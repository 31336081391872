import { Box, CircularProgress } from "@mui/material";
import { getPercentage } from "../../../helpers/getPercentage";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";

interface Props {
  current: number;
  total: number;
  text: string;
}

// "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit";

const OTArates = ({ current, total, text }: Props) => {
  return (
    <Box className="OTArates">
      <Box className="progressBar">
        <CircularProgress
          variant="determinate"
          value={getPercentage({ value: current, total })}
          color={
            getPercentage({ value: current, total }) === 100
              ? "success"
              : getPercentage({ value: current, total }) < 50
              ? "error"
              : "warning"
          }
          thickness={5}
        />
      </Box>

      <Box className="total">
        <Title variant="h1">{`${current} / ${total}`}</Title>
        <Text>{`${text} (${getPercentage({ value: current, total })}%)`}</Text>
      </Box>
    </Box>
  );
};

export default OTArates;
