import { LoaderState } from "../../types/rtk/loader/loader";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: LoaderState = {
  isLoading: false,
};

const LoaderSlice = createSlice({
  name: "loader",
  initialState: initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<LoaderState>) => {
      state.isLoading = action.payload.isLoading;
    },
  },
});
export const { setLoader } = LoaderSlice.actions;
const LoaderReducer = LoaderSlice.reducer;
export default LoaderReducer;
