import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

import PageTemplate from "../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import DataDisplay from "../../molecules/DataDisplay/DataDisplay";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import {
  useCreateReturnFileMutation,
  useGetUserByIdQuery,
} from "../../../rtk/users/users";
import UserForm from "../../organisms/UserForm/UserForm";

const ManageUsers = () => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { data: user, isLoading: isLoadingGroup } = useGetUserByIdQuery(
    id ?? "",
    {
      skip: !id,
    }
  );

  const [createReturnFile] = useCreateReturnFileMutation();

  const userDataToDisplay = [
    { title: "Id", selector: "id" },
    { title: "First name", selector: "firstname" },
    { title: "Last name", selector: "lastname" },
    { title: "Phone", selector: "phone" },
    { title: "Mail", selector: "mail" },
    { title: "Adress", selector: "address" },
    { title: "Zip code", selector: "postal_code" },
    { title: "City", selector: "city" },
    { title: "Country", selector: "country" },
  ];

  const handleReturnFile = async () => {
    setIsLoading(true);
    try {
      const response = await createReturnFile({
        id: user?.id ?? 0,
        body: {},
      }).unwrap();
      const unparsedRows = String(response).split("\n");
      const rows = unparsedRows.map((row) => String(row).split(","));

      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `ReturnFile.pdf`);
      document.body.appendChild(link); // Required for FF
      link.click();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate
      title="Manage user"
      cross
      onClickCross={editMode === true ? () => setEditMode(false) : undefined}
    >
      <Box className="ManageUsers">
        {!editMode ? (
          <StyledPaper>
            {!isLoadingGroup ? (
              <>
                <Box className="dataHeader">
                  <Title
                    variant="h3"
                    children={`${user?.firstname} ${user?.lastname}`}
                  />
                  <Text children={`user id: ${user?.id ?? ""}`} />
                </Box>

                <Box className="data">
                  <DataDisplay
                    datas={user}
                    datasInformations={userDataToDisplay}
                    orientation={"horizontal"}
                  />
                </Box>

                <Box className="buttons">
                  {!isLoading ? (
                    <>
                      <StyledButton
                        children="Generate a return file"
                        className="bigButton"
                        onClick={() => handleReturnFile()}
                      />
                      <StyledButton
                        children="Edit"
                        onClick={() => setEditMode(true)}
                      />
                    </>
                  ) : (
                    <SpinnerLoader />
                  )}
                </Box>
              </>
            ) : (
              <SpinnerLoader />
            )}
          </StyledPaper>
        ) : (
          <UserForm user={user} onClickCancel={() => setEditMode(false)} />
        )}
      </Box>
    </PageTemplate>
  );
};

export default ManageUsers;
