import {
  Home,
  CardGiftcard,
  Apartment,
  Terminal,
  // AdminPanelSettings,
  CellTower,
  Policy,
  Group,
  ShoppingCart,
  // AccountCircle,
} from "@mui/icons-material";
import { AppRoutes } from "./AppRoutes";

export const NavBarRoutes = [
  {
    path: AppRoutes.BASE_ROUTE,
    name: "Overview",
    roles: [""],
    icon: <Home />,
    homeDisplay: false,
    navBarDisplay: true,
  },
  {
    path: AppRoutes.PROMO_CODE,
    name: "Promo codes",
    roles: [""],
    icon: <CardGiftcard />,
    homeDisplay: true,
    navBarDisplay: true,
    children: [
      {
        path: AppRoutes.RULES,
        name: "Rules",
        roles: [""],
        icon: undefined,
        homeDisplay: false,
        navBarDisplay: true,
      },
      {
        path: AppRoutes.AFFILIATES,
        name: "Affiliates",
        roles: [""],
        icon: undefined,
        homeDisplay: false,
        navBarDisplay: true,
      },
    ],
  },
  {
    path: AppRoutes.RETAILERS,
    name: "Retailers",
    roles: [""],
    icon: <Apartment />,
    homeDisplay: true,
    navBarDisplay: true,
    children: [
      {
        path: AppRoutes.RETAILER_CHILD,
        name: "Retailers",
        roles: [""],
        icon: undefined,
        homeDisplay: false,
        navBarDisplay: true,
      },
      {
        path: AppRoutes.GROUPS,
        name: "Group",
        roles: [""],
        icon: undefined,
        homeDisplay: false,
        navBarDisplay: true,
      },
      {
        path: AppRoutes.STORE_LOCATOR,
        name: "Store locator",
        roles: [""],
        icon: undefined,
        homeDisplay: false,
        navBarDisplay: true,
      },
    ],
  },
  {
    path: AppRoutes.SOFTWARES,
    name: "Software",
    roles: [""],
    icon: <Terminal />,
    homeDisplay: true,
    navBarDisplay: true,
    children: [
      {
        path: AppRoutes.OTA_CAMPAINS,
        name: "OTA Campains",
        roles: [""],
        icon: undefined,
        homeDisplay: false,
        navBarDisplay: true,
      },
      {
        path: AppRoutes.FIRMWARES,
        name: "Firmwares",
        roles: [""],
        icon: undefined,
        homeDisplay: false,
        navBarDisplay: true,
      },
      {
        path: AppRoutes.HARDWARES,
        name: "Hardwares",
        roles: [""],
        icon: undefined,
        homeDisplay: false,
        navBarDisplay: true,
      },
    ],
  },
  // {
  //   path: AppRoutes.ADMIN,
  //   name: "Admin",
  //   roles: [""],
  //   icon: <AdminPanelSettings />,
  //   homeDisplay: true,
  // navBarDisplay: true,
  //   children: [
  //     {
  //       path: AppRoutes.TEAM_MEMBERS,
  //       name: "Team members",
  //       roles: [""],
  //       icon: undefined,
  //       homeDisplay: false,
  // navBarDisplay: true,
  //     },
  //     {
  //       path: AppRoutes.ROLES,
  //       name: "Roles",
  //       roles: [""],
  //       icon: undefined,
  //       homeDisplay: false,
  // navBarDisplay: true,
  //     },
  //   ],
  // },
  {
    path: AppRoutes.OPERATOR_NETWORK,
    name: "Operator network",
    roles: [""],
    icon: <CellTower />,
    homeDisplay: false,
    navBarDisplay: true,
  },
  {
    path: AppRoutes.QUALITY_CHECK,
    name: "Quality Check",
    roles: [""],
    icon: <Policy />,
    homeDisplay: false,
    navBarDisplay: true,
  },
  {
    path: AppRoutes.ORDERB2B,
    name: "Order B2B",
    roles: [""],
    icon: <ShoppingCart />,
    homeDisplay: false,
    navBarDisplay: true,
  },
  {
    path: AppRoutes.USERS,
    name: "Users",
    roles: [""],
    icon: <Group />,
    homeDisplay: false,
    navBarDisplay: true,
  },
  // {
  //   path: AppRoutes.ACCOUNT,
  //   name: "Account",
  //   roles: [""],
  //   icon: <AccountCircle />,
  //    homeDisplay:false,
  // navBarDisplay: true,
  // },
];
