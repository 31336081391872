import { FormEvent, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import InputSwitch from "../../atoms/InputSwitch/InputSwitch";
import InputNumber from "../../atoms/InputNumber/InputNumber";
import InputText from "../../atoms/InputText/InputText";
import InputAutocomplete, {
  AutoCompleteDataOptions,
  AutoCompleteDatas,
} from "../../atoms/InputAutocomplete/InputAutocomplete";
import { AvailableSites } from "../../../helpers/constants/availableSite";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import {
  CreateRule,
  Offers,
  Rule,
} from "../../../types/rtk/promo-codes/rules/rules";
import {
  useCreateRuleMutation,
  useGetSubscriptionQuery,
  useUpdateRuleMutation,
} from "../../../rtk/promo-codes/rules/rules";
import { YES_NO } from "../../../helpers/constants/yesNo";
import InputRadio from "../../atoms/InputRadio/InputRadio";
import { RULES_CARD } from "../../../helpers/constants/rulesCard";
import { AppRoutes } from "../../../routes/AppRoutes";

interface Props {
  rule?: Rule;
  onClickCancel?: () => void;
}

const RuleForm = ({ rule, onClickCancel }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [ruleCard, setRuleCard] = useState(RULES_CARD[0]);
  const [formData, setFormData] = useState<CreateRule>({
    rule_name: "",
    site: AvailableSites[0],
    is_gift_card_rule: false,
    offers: [],
  } as CreateRule);
  const { data: subscription } = useGetSubscriptionQuery({
    url: encodeURI(RULES_CARD.map((e) => e.value).join("|")),
    length: RULES_CARD.length,
    site: AvailableSites[0],
  });
  const [createRule] = useCreateRuleMutation();
  const [updateRule] = useUpdateRuleMutation();

  useEffect(() => {
    if (subscription && !rule) {
      if (formData.is_gift_card_rule !== true) {
        const arrOffers = subscription.items.map((e) => {
          return {
            id_subscription_offer: e.id,
            value: 0,
            is_percentage: true,
          };
        });

        setFormData((formData) => {
          return { ...formData, offers: arrOffers };
        });
      } else {
        const arrOffers = subscription.items
          .filter((e) => e.code === ruleCard.value)
          .map((e) => {
            return {
              id_subscription_offer: e.id,
              value: 0,
              is_percentage: true,
            };
          });

        setFormData((formData) => {
          return { ...formData, offers: arrOffers };
        });
      }
    }
  }, [subscription, formData.is_gift_card_rule, ruleCard, rule]);

  useEffect(() => {
    if (rule) {
      setFormData(rule as CreateRule);
    }
  }, [rule]);

  const updateOffers = (value: number, id: number): Offers => {
    let updatedList = formData.offers.map((item) => {
      if (item.id_subscription_offer === id) {
        return { ...item, value: value };
      }

      return item;
    });

    return updatedList;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = rule
        ? await updateRule({
            body: formData,
            id: rule?.id?.toString() ?? "",
          }).unwrap()
        : await createRule(formData).unwrap();
      setIsLoading(false);
      if (onClickCancel) {
        onClickCancel();
      } else {
        navigate(AppRoutes.MANAGE_RULE + `/${response.id}`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="RuleForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputText
                label="Name of the rule"
                value={formData.rule_name}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    rule_name: value,
                  })
                }
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutocomplete
                label="Site associated"
                value={formData.site ?? ""}
                options={AvailableSites.map((obj) => {
                  return { label: obj, value: obj };
                })}
                onChange={(datas: AutoCompleteDatas) =>
                  setFormData({
                    ...formData,
                    site: (datas as AutoCompleteDataOptions).value,
                  })
                }
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <InputRadio
                label="Is Gift Card?"
                value={formData.is_gift_card_rule}
                datas={YES_NO}
                onChange={(value: string | boolean | number) => {
                  setFormData({
                    ...formData,
                    is_gift_card_rule: value === "true" ? true : false,
                  });
                }}
              />
            </Grid>
            {!formData.is_gift_card_rule === true ? (
              <>
                <Grid item xs={4}>
                  <InputNumber
                    label="1 month"
                    value={
                      formData.offers.find((e) => e.id_subscription_offer === 1)
                        ?.value
                    }
                    onChange={(value) => {
                      const arrOffers = updateOffers(value, 1);
                      setFormData({ ...formData, offers: arrOffers });
                    }}
                    endAdornment="%"
                    maxValue={100}
                    minValue={0}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputNumber
                    label="1 year"
                    value={
                      formData.offers.find((e) => e.id_subscription_offer === 3)
                        ?.value
                    }
                    onChange={(value) => {
                      const arrOffers = updateOffers(value, 3);
                      setFormData({ ...formData, offers: arrOffers });
                    }}
                    endAdornment="%"
                    maxValue={100}
                    minValue={0}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputNumber
                    label="3 year"
                    value={
                      formData.offers.find(
                        (e) => e.id_subscription_offer === 14
                      )?.value
                    }
                    onChange={(value) => {
                      const arrOffers = updateOffers(value, 14);
                      setFormData({ ...formData, offers: arrOffers });
                    }}
                    endAdornment="%"
                    maxValue={100}
                    minValue={0}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <InputAutocomplete
                    label="Select a card"
                    value={ruleCard.value}
                    options={RULES_CARD}
                    onChange={(datas: AutoCompleteDatas) =>
                      setRuleCard(
                        RULES_CARD.find(
                          (obj) =>
                            obj.value ===
                            (datas as AutoCompleteDataOptions).value
                        ) ?? ({} as (typeof RULES_CARD)[0])
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputNumber
                    label={ruleCard.label}
                    value={formData.offers[0].value}
                    onChange={(value) => {
                      const arrOffers = updateOffers(
                        value,
                        formData.offers[0].id_subscription_offer
                      );
                      setFormData({ ...formData, offers: arrOffers });
                    }}
                    endAdornment="%"
                    maxValue={100}
                    minValue={0}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <InputSwitch
                label="Activated"
                value={formData.is_activated ?? false}
                onChange={(value: boolean) =>
                  setFormData({ ...formData, is_activated: value })
                }
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() =>
                    onClickCancel ? onClickCancel() : navigate(-1)
                  }
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default RuleForm;
