export enum AppRoutes {
  BASE_ROUTE = "/",
  PROMO_CODE = "/promo-code",
  RULES = "/promo-code/rule",
  CREATE_RULE = "/promo-code/rule/create-rule",
  MANAGE_RULE = "/promo-code/rule/manage-rule",
  AFFILIATES = "/promo-code/affiliate",
  CREATE_AFFILIATE = "/promo-code/affiliate/create-affiliate",
  MANAGE_AFFILIATE = "/promo-code/affiliate/manage-affiliate",
  PROMO_CODE_CHILD = "/promo-code/promo-code",
  CREATE_PROMO_CODE = "/promo-code/promo-code/create-promo-code",
  MANAGE_PROMO_CODE = "/promo-code/promo-code/manage-promo-code",
  RETAILERS = "/retailer",
  RETAILER_CHILD = "/retailer/retailer",
  CREATE_RETAILER = "/retailer/retailer/create-retailer",
  MANAGE_RETAILER = "/retailer/retailer/manage-retailer",
  GROUPS = "/retailer/group",
  CREATE_GROUP = "/retailer/retailer/create-group",
  MANAGE_GROUP = "/retailer/retailer/manage-group",
  STORE_LOCATOR = "/retailer/store-locator",
  SOFTWARES = "/software",
  OTA_CAMPAINS = "/software/ota-campain",
  CREATE_OTA_CAMPAIN = "/software/ota-campain/create-ota-campain",
  MANAGE_OTA_CAMPAIN = "/software/ota-campain/manage-ota-campain",
  FIRMWARES = "/software/firmwares",
  CREATE_FIRMWARE = "/software/firmware/create-firmware",
  MANAGE_FIRMWARE = "/software/firmware/manage-firmware",
  HARDWARES = "/software/hardware",
  CREATE_HARDWARE = "/software/hardware/create-hardware",
  MANAGE_HARDWARE = "/software/hardware/manage-hardware",
  // ADMIN = "/admin",
  // TEAM_MEMBERS = "/admin/team_members",
  // ROLES = "/admin/roles",
  OPERATOR_NETWORK = "/operator-network",
  MANAGE_OPERATOR_NETWORK = "/operator-network/manage-operator-network",
  QUALITY_CHECK = "/quality-check",
  QUALITY_CHECK_SIMS = "/quality-check/sims",
  QUALITY_CHECK_TRACKERS = "/quality-check/trackers",
  QUALITY_CHECK_COMMANDS = "/quality-check/commands",
  ORDERB2B = "/order-b2b",
  CREATE_ORDERB2B = "/order-b2b/create-order-b2b",
  MANAGE_ORDERB2B = "/order-b2b/manage-order-b2b",
  USERS = "/users",
  MANAGE_USERS = "/users/manage-users",
  ACCOUNT = "/account",
  LOGIN = "/login",
}
