import { useState } from "react";
import { Box, Grid } from "@mui/material";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../../atoms/StyledPaper/StyledPaper";
import StyledButton from "../../../atoms/StyledButton/StyledButton";
import Title from "../../../atoms/Title/Title";
import Text from "../../../atoms/Text/Text";
import SpinnerLoader from "../../../atoms/SpinnerLoader/SpinnerLoader";
import { useCreateStorLocatorMutation } from "../../../../rtk/retailers/storlocator/storlocator";

const StoreLocator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [createStorLocator] = useCreateStorLocatorMutation();

  const handleStoreLocator = async () => {
    setIsLoading(true);
    try {
      await createStorLocator();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate title="Store locator">
      <StyledPaper>
        <Box className="StoreLocator">
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Title variant="h3" children="Update Store Locator" />
              <Text children="Update the file from Excel to Geojson" />
            </Grid>
            <Grid item xs={3}>
              <StyledButton onClick={handleStoreLocator}>Update</StyledButton>
            </Grid>
            {isLoading && (
              <Grid item xs={12}>
                <SpinnerLoader />
              </Grid>
            )}
          </Grid>
        </Box>
      </StyledPaper>
    </PageTemplate>
  );
};

export default StoreLocator;
