import {
  OperatorNetworks,
  CreateOperatorNetwork,
  OperatorNetwork,
  CreateOperatorNetworkResponse,
  UpdateOperatorNetwork,
  UpdateOperatorNetworkResponse,
} from "../../../types/rtk/software/operator-network/operator-network";
import { apiSlice } from "../../_api/api-slice";

export const operatorNetwork = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOperatorNetworks: build.query<OperatorNetworks, string>({
      query: (url) => ({
        url: `operator-network-conf${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "OperatorNetworks" }],
    }),
    getOperatorNetworkById: build.query<OperatorNetwork, string>({
      query: (id) => ({
        url: `operator-network-conf/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "OperatorNetwork", id: `OperatorNetwork_${id}` },
      ],
    }),
    createOperatorNetwork: build.mutation<
      CreateOperatorNetworkResponse,
      CreateOperatorNetwork
    >({
      query: (body) => ({
        url: `operator-network-conf`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [
        { type: "OperatorNetworks" },
      ],
    }),
    updateOperatorNetwork: build.mutation<
      UpdateOperatorNetworkResponse,
      {
        body: UpdateOperatorNetwork;
        id: number;
      }
    >({
      query: ({ body, id }) => ({
        url: `operator-network-conf/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "OperatorNetwork", id: `OperatorNetwork_${id}` },
      ],
    }),
    deleteOperatorNetwork: build.mutation<null, string>({
      query: (id) => ({
        url: `operator-network-conf/${id}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, _id) => [{ type: "OperatorNetworks" }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetOperatorNetworksQuery,
  useGetOperatorNetworkByIdQuery,
  useCreateOperatorNetworkMutation,
  useUpdateOperatorNetworkMutation,
  useDeleteOperatorNetworkMutation,
} = operatorNetwork;
