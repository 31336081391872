import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  useEffect,
  useState,
} from "react";
import { InputAdornment, TextField } from "@mui/material";

interface Props {
  label: string;
  value: string;
  onChange: (value: string) => void;
  name?: string;
  className?: string;
  type?: HTMLInputTypeAttribute;
  endAdornment?: string | JSX.Element;
  autoComplete?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  multiline?: boolean;
}

const InputText = ({
  label,
  value,
  onChange,
  name,
  className,
  type,
  endAdornment,
  autoComplete,
  placeholder,
  disabled,
  error,
  required,
  multiline,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <TextField
      className={`InputText ${className}`}
      label={label}
      name={name}
      value={inputValue}
      type={type}
      onChange={handleInputChange}
      variant="outlined"
      InputProps={
        endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }
          : undefined
      }
      autoComplete={autoComplete}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      required={required}
      multiline={multiline}
    />
  );
};

export default InputText;
