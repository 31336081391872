import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandMore, NavigateNext } from "@mui/icons-material";

import { NavBarTypes } from "../../../types/NavBarRoutes/NavBarRoutes";

interface Props {
  item: NavBarTypes;
}

const ListItem = ({ item }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box className="ListItem">
      <ListItemButton
        onClick={() => {
          item.children ? handleClick() : navigate(item.path);
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
        {item.children === undefined ? null : open ? (
          <ExpandMore />
        ) : (
          <NavigateNext />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children?.map((child, index) => {
            return (
              <ListItemButton
                key={index}
                onClick={() => {
                  navigate(child.path);
                }}
                sx={{ pl: 4 }}
              >
                <ListItemIcon>{child.icon}</ListItemIcon>
                <ListItemText primary={child.name} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </Box>
  );
};

export default ListItem;
