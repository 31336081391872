import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import {
  CreateFirmware,
  Firmware,
} from "../../../types/rtk/software/firwmare/firwmare";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { useCreateFirmwareMutation } from "../../../rtk/software/firmware/firmware";
import InputAutocomplete, {
  AutoCompleteDataOptions,
  AutoCompleteDatas,
} from "../../atoms/InputAutocomplete/InputAutocomplete";
import { AppRoutes } from "../../../routes/AppRoutes";
import InputFile from "../../atoms/InputFile/InputFile";
import { fileToBase64 } from "../../../helpers/fileToBase64";
import InputText from "../../atoms/InputText/InputText";
import { useGetFirmwaresCompatibilityLabelQuery } from "../../../rtk/software/firmware/firmware-compatibility-label";

interface Props {
  firmware?: Firmware;
  onClickCancel?: () => void;
}

const FirmwareForm = ({ firmware, onClickCancel }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [labelsData, setLabelsData] = useState<AutoCompleteDataOptions[]>([]);
  const [formData, setFormData] = useState<CreateFirmware>({
    data: "",
    name: "",
    compatibility_label_id: 0,
    release_note: "",
  } as CreateFirmware);
  const {
    data: labels,
    isLoading: isLoadingLabels,
    isFetching,
  } = useGetFirmwaresCompatibilityLabelQuery("?page=1&size=100");

  const [createFirmwareCampain] = useCreateFirmwareMutation();

  useEffect(() => {
    if (firmware) {
      setFormData((formData) => ({ ...formData, name: firmware.name ?? "" }));
    }
  }, [firmware]);

  useEffect(() => {
    if (labels) {
      setLabelsData(
        labels.items?.map((obj) => ({
          label: obj.label ?? "",
          value: obj.id?.toString() ?? "",
        }))
      );
    }
  }, [labels]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await createFirmwareCampain(formData).unwrap();
      setIsLoading(false);
      if (onClickCancel) {
        onClickCancel();
      } else {
        navigate(AppRoutes.MANAGE_FIRMWARE + `/${response.id}`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="FirmwareForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputFile
                label="Firmware file"
                onChange={async (value: File | null) =>
                  setFormData({
                    ...formData,
                    data: value ? await fileToBase64(value) : "",
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputText
                label="Name"
                required
                placeholder="S-L2K.13.150709C"
                value={formData.name ?? ""}
                onChange={(value) => setFormData({ ...formData, name: value })}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutocomplete
                label="Compatibility"
                value={formData.compatibility_label_id.toString()}
                options={labelsData}
                onChange={(datas: AutoCompleteDatas) =>
                  setFormData({
                    ...formData,
                    compatibility_label_id: parseInt(
                      (datas as AutoCompleteDataOptions).value
                    ),
                  })
                }
                isLoading={isLoadingLabels || isFetching}
              />
            </Grid>
            <Grid item xs={12}>
              <InputText
                label="Release note"
                placeholder="What is the purpose of this firmware ?"
                required
                value={formData.release_note ?? ""}
                onChange={(value) =>
                  setFormData({ ...formData, release_note: value })
                }
                multiline
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() =>
                    onClickCancel ? onClickCancel() : navigate(-1)
                  }
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default FirmwareForm;
