import { FormEvent, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import InputText from "../../atoms/InputText/InputText";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { AppRoutes } from "../../../routes/AppRoutes";
import { useUpdateUserMutation } from "../../../rtk/users/users";
import { UpdateUser, User } from "../../../types/rtk/users/users";
import InputAutocomplete, {
  AutoCompleteDataOptions,
  AutoCompleteDatas,
} from "../../atoms/InputAutocomplete/InputAutocomplete";
import { countries } from "../../../helpers/constants/countries";

interface Props {
  user?: User;
  onClickCancel?: () => void;
}

const UserForm = ({ user, onClickCancel }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<UpdateUser>({} as UpdateUser);
  const [countriesData, setCountriesData] = useState<AutoCompleteDataOptions[]>(
    []
  );
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (user) {
      setFormData(user);
    }
  }, [user]);

  useEffect(() => {
    if (countries) {
      setCountriesData(
        Object.keys(countries).map((name) => {
          return { label: countries[name].name ?? "", value: name };
        }) ?? []
      );
    }
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await updateUser({
        body: formData,
        id: user?.id?.toString() ?? "",
      }).unwrap();
      setIsLoading(false);
      if (onClickCancel) {
        onClickCancel();
      } else {
        navigate(AppRoutes.MANAGE_AFFILIATE + `/${response.id}`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="UserForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <InputText
                label="First name"
                required
                value={formData.firstname ?? ""}
                onChange={(value: string) => {
                  setFormData({ ...formData, firstname: value });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="Last name"
                required
                value={formData.lastname ?? ""}
                onChange={(value: string) => {
                  setFormData({ ...formData, lastname: value });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="Mail"
                required
                value={formData.mail ?? ""}
                onChange={(value: string) => {
                  setFormData({ ...formData, mail: value });
                }}
                type="email"
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="Phone"
                value={formData.phone ?? ""}
                onChange={(value: string) => {
                  setFormData({ ...formData, phone: value.replace(/\D/g, "") });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="Adress"
                value={formData.address ?? ""}
                onChange={(value: string) => {
                  setFormData({ ...formData, address: value });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="City"
                value={formData.city ?? ""}
                onChange={(value: string) => {
                  setFormData({ ...formData, city: value });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="Zip code"
                value={formData.postal_code ?? ""}
                onChange={(value: string) => {
                  setFormData({
                    ...formData,
                    postal_code: value.replace(/\D/g, ""),
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputAutocomplete
                label="Country"
                value={formData.country ?? ""}
                onChange={(datas: AutoCompleteDatas) =>
                  setFormData({
                    ...formData,
                    country: (datas as AutoCompleteDataOptions).value,
                  })
                }
                options={countriesData}
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() =>
                    onClickCancel ? onClickCancel() : navigate(-1)
                  }
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default UserForm;
