import {
  Users,
  UpdateUser,
  User,
  CreateUserResponse,
} from "../../types/rtk/users/users";
import { apiSlice } from "../_api/api-slice";

export const retailers = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<Users, string>({
      query: (url) => ({
        url: `user${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Users" }],
    }),
    getUserById: build.query<User, string>({
      query: (id) => ({
        url: `user/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "User", id: `user_${id}` },
      ],
    }),
    createReturnFile: build.mutation<
      CreateUserResponse,
      { id: string | number; body: {} }
    >({
      query: ({ id, body }) => ({
        url: `user/${id}/return-file`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Users" }],
    }),
    updateUser: build.mutation<User, { body: UpdateUser; id: string }>({
      query: ({ body, id }) => ({
        url: `user/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "User", id: `user_${id}` },
        { type: "Users" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateReturnFileMutation,
  useUpdateUserMutation,
} = retailers;
