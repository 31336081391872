import { useState } from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import PageTemplate from "../../templates/PageTemplate/PageTemplate";
import GenericTable from "../../molecules/GenericTable/GenericTable";
import { AppRoutes } from "../../../routes/AppRoutes";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { hasQueryFilters } from "../../../helpers/hasQueryFilter";
import ConditionalWrapper from "../../atoms/ConditionalWrapper/ConditionalWrapper";
import { useGetOrderB2BsQuery } from "../../../rtk/orderB2B/orderB2B";
import { formatDate } from "../../../helpers/formatDate";

interface Props {
  template?: boolean;
}

const OrderB2B = ({ template = true }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const [query, setQuery] = useState("");
  const colDef = types?.components.schemas.OrderB2BSummaryRead.properties ?? {};
  const {
    data: retailers,
    isLoading,
    isFetching,
  } = useGetOrderB2BsQuery(query, {
    skip: query === "",
  });

  const colToOverride: GridColDef[] = [
    {
      field: "order_number",
      headerName: "Order number",
      renderCell: (params) => (
        <Box
          onClick={() =>
            navigate(`${AppRoutes.MANAGE_ORDERB2B}/${params.id.toString()}`)
          }
          className="clickableBox"
        >
          {params.value}
        </Box>
      ),
    },
    { field: "id" },
    {
      field: "retailer.name",
      headerName: "Retailer",
      renderCell: (params) => {
        return (
          <Box
            onClick={() =>
              navigate(
                `${
                  AppRoutes.MANAGE_RETAILER
                }/${params.row.retailer.id.toString()}`
              )
            }
            className="clickableBox"
          >
            {`${params.row.retailer.name} (${params.row.retailer.id})`}
          </Box>
        );
      },
    },

    {
      field: "bo_users.username",
      headerName: "Created by",
      renderCell: (params) => params.row.bo_users.username,
    },
    // {
    //   field: "trackers",
    //   headerName: "Count tracker",
    //   valueGetter: (
    //     trackers: components["schemas"]["OrderB2BTrackerObjRoot"][]
    //   ) => trackers.length,
    // sortable: false,
    // filterable: false,
    // },
    {
      field: "created_at",
      valueGetter: (created_at: string) => formatDate(created_at ?? ""),
    },
  ];

  const clearFilter = () => {
    setQuery("");
  };

  return (
    <ConditionalWrapper
      condition={template}
      wrapper={(children) => (
        <PageTemplate
          title="Order B2B"
          button="New Order B2B"
          link={AppRoutes.CREATE_ORDERB2B}
          clearFilterButton={
            hasQueryFilters(query) ? "Remove filters" : undefined
          }
          onClickClearFilterButton={clearFilter}
        >
          {children}
        </PageTemplate>
      )}
    >
      <Box className="OrderB2B">
        {types && (
          <GenericTable
            rawData={retailers}
            isLoading={isLoading}
            isFetching={isFetching}
            colDef={colDef}
            colToOverride={colToOverride}
            setQuery={setQuery}
            query={query}
          />
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default OrderB2B;
