import { Box, Typography } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface Props {
  variant: "h1" | "h2" | "h3" | "h4" | "h5";
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const Title = ({ children, variant = "h1", style, className = "" }: Props) => {
  return (
    <Box className={`Title ${className}`} style={style}>
      <Typography variant={variant}>{children}</Typography>
    </Box>
  );
};

export default Title;
