import { ChangeEvent, useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";

interface Props {
  label: string;
  onChange: (value: number) => void;
  value?: number;
  defaultValue?: number;
  className?: string;
  endAdornment?: string;
  required?: boolean;
  maxValue?: number;
  minValue?: number;
}

const InputNumber = ({
  label,
  value,
  onChange,
  className,
  endAdornment,
  required,
  maxValue,
  minValue,
}: Props) => {
  const [inputValue, setInputValue] = useState<number>(value ? value : 0);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.valueAsNumber;

    if (maxValue !== undefined && newValue > maxValue) {
      newValue = maxValue;
    } else if (minValue !== undefined && newValue < minValue) {
      newValue = minValue;
    }
    // Update value if exist else set zero
    setInputValue(newValue ? newValue : 0);
    onChange(newValue ? newValue : 0);
  };

  useEffect(() => {
    if (value) setInputValue(value);
  }, [value]);

  return (
    <TextField
      className={`InputNumber ${className}`}
      type="number"
      label={label}
      value={inputValue.toString()}
      onChange={handleChange}
      variant="outlined"
      inputProps={{ inputMode: "numeric" }}
      InputProps={
        endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }
          : undefined
      }
      required={required}
    />
  );
};

export default InputNumber;
