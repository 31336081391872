import { ChangeEvent, useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

export interface RadioData {
  value: number | string | boolean | undefined;
  label: string;
}

interface Props {
  label: string;
  datas: RadioData[];
  value: string | boolean | number | undefined;
  onChange?: (value: string | boolean | number) => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
}

const InputRadio = ({
  label,
  datas = [{ label: "", value: "" }],
  value,
  onChange,
  className,
  required,
  disabled,
}: Props) => {
  const [val, setVal] = useState<string | boolean | number>("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVal((event.target as HTMLInputElement).value);
    if (onChange) onChange((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (value !== undefined && datas.length > 0) {
      setVal(value);
    }
  }, [value, datas]);

  return (
    <FormControl className={`InputRadio ${className}`}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup row value={val} onChange={handleChange}>
        {datas.length > 0 &&
          datas.map((data, index) => (
            <FormControlLabel
              key={index}
              value={data.value}
              control={<Radio />}
              label={data.label}
              className={`${data.value === val ? "checked" : ""}`}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

export default InputRadio;
