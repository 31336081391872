import AffiliateForm from "../../../organisms/AffiliateForm/AffiliateForm";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";

const CreateAffiliate = () => {
  return (
    <PageTemplate title="New Affiliate" cross>
      <AffiliateForm />
    </PageTemplate>
  );
};

export default CreateAffiliate;
