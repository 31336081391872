import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import InputAutocomplete, {
  AutoCompleteDataOptions,
  AutoCompleteDatas,
} from "../../atoms/InputAutocomplete/InputAutocomplete";
import { AppRoutes } from "../../../routes/AppRoutes";
import InputText from "../../atoms/InputText/InputText";
import { CreateOrderB2B } from "../../../types/rtk/orderB2B/orderB2B";
import { useCreateOrderB2BMutation } from "../../../rtk/orderB2B/orderB2B";
// import InputNumber from "../../atoms/InputNumber/InputNumber";
import { useGetRetailersQuery } from "../../../rtk/retailers/retailers/retailers";
import Text from "../../atoms/Text/Text";
import { getUrlParameters } from "../../../helpers/getUrlParameters";

const OrderB2BForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [retailersData, setRetailersData] = useState<AutoCompleteDataOptions[]>(
    []
  );
  const [formData, setFormData] = useState<CreateOrderB2B>(
    {} as CreateOrderB2B
  );
  const {
    data: retailers,
    isLoading: isLoadingRetailersList,
    isFetching: isFetchingRetailersList,
  } = useGetRetailersQuery(
    getUrlParameters({
      filters: [{ operator: "contains", field: "name|id", query }],
      size: 10,
    }),
    { skip: !query }
  );
  const [createOrderB2B] = useCreateOrderB2BMutation();

  useEffect(() => {
    if (retailers) {
      setRetailersData(
        retailers.items.map((group) => {
          return {
            label: `${group.name ?? ""} (${group.id?.toString() ?? ""})`,
            value: group.id?.toString() ?? "",
          };
        }) ?? []
      );
    }
  }, [retailers]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await createOrderB2B(formData).unwrap();
      setIsLoading(false);
      navigate(AppRoutes.MANAGE_ORDERB2B + `/${response.id}`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="OrderB2BForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputText
                label="Order number"
                required
                // placeholder="S-L2K.13.150709C"
                value={formData.order_number ?? ""}
                onChange={(value) =>
                  setFormData({ ...formData, order_number: value })
                }
              />
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={4}>
                <Box className="title">
                  <Text
                    children={`Type the retailer NAME or ID you want to select.`}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} className="input">
                <InputAutocomplete
                  label="Retailer"
                  value={query}
                  options={retailersData}
                  onChange={(value: AutoCompleteDatas) => {
                    setFormData({
                      ...formData,
                      retailer_id: parseInt(
                        (value as AutoCompleteDataOptions).value
                      ),
                    });
                  }}
                  onInputChange={(value) => {
                    setQuery(value);
                  }}
                  isLoading={isLoadingRetailersList || isFetchingRetailersList}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputAutocomplete
                label={"Packaging Cat (IMEI list)"}
                value={
                  formData.imei_list_cat?.map((obj) => obj.toString()) ?? ""
                }
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    imei_list_cat: (value as string[]).map((obj) =>
                      parseInt(obj)
                    ),
                  });
                }}
                options={[]}
                multiple
                freeSolo
                number
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutocomplete
                label={"Packaging Dog (IMEI list)"}
                value={
                  formData.imei_list_dog?.map((obj) => obj.toString()) ?? ""
                }
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    imei_list_dog: (value as string[]).map((obj) =>
                      parseInt(obj)
                    ),
                  });
                }}
                options={[]}
                multiple
                freeSolo
                number
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutocomplete
                label={"Packaging Kid (IMEI list)"}
                value={
                  formData.imei_list_kid?.map((obj) => obj.toString()) ?? ""
                }
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    imei_list_kid: (value as string[]).map((obj) =>
                      parseInt(obj)
                    ),
                  });
                }}
                options={[]}
                multiple
                freeSolo
                number
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutocomplete
                label={"Packaging Silver (IMEI list)"}
                value={
                  formData.imei_list_silver?.map((obj) => obj.toString()) ?? ""
                }
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    imei_list_silver: (value as string[]).map((obj) =>
                      parseInt(obj)
                    ),
                  });
                }}
                options={[]}
                multiple
                freeSolo
                number
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() => navigate(-1)}
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default OrderB2BForm;
