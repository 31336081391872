import { Box, Drawer, List } from "@mui/material";

import logo from "../../../assets/images/LogoBlanc.png";
import ListItem from "../../molecules/ListItem/ListItem";
import { NavBarRoutes } from "../../../routes/NavBarRoutes";

interface Props {
  isNavBarOpen: boolean;
}

const NavBar = ({ isNavBarOpen }: Props) => {
  return (
    <Drawer variant="persistent" anchor="left" open={isNavBarOpen}>
      <Box className="NavBar">
        <Box className="logo">
          <img src={logo} alt={"Weenect"} />
        </Box>
        <Box className="NavList">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            // subheader={
            //   <ListSubheader component="div" id="nested-list-subheader">
            //     {"GENERAL"}
            //   </ListSubheader>
            // }
          >
            {NavBarRoutes.map((item, index) => {
              return item.navBarDisplay && <ListItem key={index} item={item} />;
            })}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NavBar;
