import { RouteObject } from "react-router-dom";

import { AppRoutes } from "../AppRoutes";
import Rules from "../../components/pages/PromoCode/Rules/Rules";
import CreateRule from "../../components/pages/PromoCode/Rules/CreateRule";
import ManageRule from "../../components/pages/PromoCode/Rules/ManageRule";
import Affiliates from "../../components/pages/PromoCode/Affiliates/Affiliates";
import CreateAffiliate from "../../components/pages/PromoCode/Affiliates/CreateAffiliate";
import ManageAffiliate from "../../components/pages/PromoCode/Affiliates/ManageAffiliate";
import CreatePromoCode from "../../components/pages/PromoCode/PromoCodes/CreatePromoCodes";

export const PromoCodesRoutes: RouteObject = {
  path: AppRoutes.PROMO_CODE,
  children: [
    {
      path: AppRoutes.RULES,
      element: <Rules />,
    },
    {
      path: AppRoutes.CREATE_RULE,
      element: <CreateRule />,
    },
    {
      path: AppRoutes.MANAGE_RULE + "/:id",
      element: <ManageRule />,
    },
    {
      path: AppRoutes.AFFILIATES,
      element: <Affiliates />,
    },
    {
      path: AppRoutes.CREATE_AFFILIATE,
      element: <CreateAffiliate />,
    },
    {
      path: AppRoutes.MANAGE_AFFILIATE + "/:id",
      element: <ManageAffiliate />,
    },
    // {
    //   path: AppRoutes.PROMO_CODE_CHILD,
    //   element: <Affiliates />,
    // },
    {
      path: AppRoutes.CREATE_PROMO_CODE + "/:affiliate_id",
      element: <CreatePromoCode />,
    },
    // {
    //   path: AppRoutes.MANAGE_PROMO_CODE + "/:id",
    //   element: <ManageAffiliate />,
    // },
  ],
};
