import { FormEvent, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import { AppRoutes } from "../../../routes/AppRoutes";
import {
  useCreateGroupMutation,
  useGetGroupsTypesQuery,
  useUpdateGroupMutation,
} from "../../../rtk/retailers/group/group";
import { getUrlParameters } from "../../../helpers/getUrlParameters";
import InputText from "../../atoms/InputText/InputText";
import InputSwitch from "../../atoms/InputSwitch/InputSwitch";
import InputFile from "../../atoms/InputFile/InputFile";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { CreateGroup, Group } from "../../../types/rtk/retailers/group/group";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { fileToBase64 } from "../../../helpers/fileToBase64";
import InputAutocomplete, {
  AutoCompleteDataOptions,
  AutoCompleteDatas,
} from "../../atoms/InputAutocomplete/InputAutocomplete";

interface Props {
  group?: Group;
  onClickCancel?: () => void;
}

const GroupForm = ({ group, onClickCancel }: Props) => {
  const navigate = useNavigate();
  const [groupTypeData, setGroupeTypeData] = useState<
    AutoCompleteDataOptions[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<CreateGroup>({} as CreateGroup);
  const {
    data: groupsType,
    isLoading: isLoadingGroupsType,
    isFetching: isFetchingGroupsType,
  } = useGetGroupsTypesQuery(
    getUrlParameters({ sort: { order: "asc", field: "name" } })
  );
  const [createGroup] = useCreateGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();

  useEffect(() => {
    if (groupsType) {
      setGroupeTypeData(
        groupsType?.items.map((item) => {
          return { label: item.name ?? "", value: item.id?.toString() ?? "" };
        }) ?? []
      );
    }
  }, [groupsType]);

  useEffect(() => {
    if (group) {
      setFormData({
        code: group.code ?? "",
        display_header: group.display_header ?? false,
        display_splashscreen: group.display_splashscreen ?? false,
        logo_header: group.logo_header ? group.logo_header : undefined,
        logo_splashscreen: group.logo_splashscreen
          ? group.logo_splashscreen
          : undefined,
        type_id: group.type?.id ?? 0,
        name: group.name ?? "",
      });
    }
  }, [group]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = group
        ? await updateGroup({
            body: formData,
            id: group.id?.toString() ?? "",
          }).unwrap()
        : await createGroup(formData).unwrap();
      setIsLoading(false);
      if (onClickCancel) {
        onClickCancel();
      } else {
        navigate(AppRoutes.MANAGE_GROUP + `/${response.id}`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="GroupForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <InputText
                label="Name"
                required
                value={formData.name ?? ""}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    name: value,
                    code: value.toLocaleUpperCase(),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputAutocomplete
                label="Type"
                value={formData.type_id?.toString() ?? "0"}
                options={groupTypeData}
                onChange={(datas: AutoCompleteDatas) =>
                  setFormData({
                    ...formData,
                    type_id: parseInt((datas as AutoCompleteDataOptions).value),
                  })
                }
                isLoading={isLoadingGroupsType || isFetchingGroupsType}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFile
                label="Logo-Header"
                onChange={async (value: File | null) =>
                  setFormData({
                    ...formData,
                    logo_header: value ? await fileToBase64(value) : undefined,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputFile
                label="Splashscreen"
                onChange={async (value: File | null) =>
                  setFormData({
                    ...formData,
                    logo_splashscreen: value
                      ? await fileToBase64(value)
                      : undefined,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputSwitch
                label="Display header (displayed in the userspace)"
                value={formData.display_header ?? false}
                onChange={(value: boolean) =>
                  setFormData({ ...formData, display_header: value })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputSwitch
                label="Display splashscreen (displayed in the apps)"
                value={formData.display_splashscreen ?? false}
                onChange={(value: boolean) =>
                  setFormData({ ...formData, display_splashscreen: value })
                }
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() =>
                    onClickCancel ? onClickCancel() : navigate(-1)
                  }
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default GroupForm;
