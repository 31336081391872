import { Login } from "../../types/rtk/auth/login";
import { apiSlice } from "../_api/api-slice";

const login = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getToken: build.mutation<Login, FormData>({
      query: (formData) => ({
        url: `token`,
        method: "post",
        body: formData,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetTokenMutation } = login;
