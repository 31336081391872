import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import { RootState } from "../../../../types/rtk/api-config/types";
import { useAppSelector } from "../../../../rtk/_config/hooks";
import { useGetGroupsQuery } from "../../../../rtk/retailers/group/group";
import { AppRoutes } from "../../../../routes/AppRoutes";
import GenericTable from "../../../molecules/GenericTable/GenericTable";
import { formatDate } from "../../../../helpers/formatDate";
import { hasQueryFilters } from "../../../../helpers/hasQueryFilter";
import ConditionalWrapper from "../../../atoms/ConditionalWrapper/ConditionalWrapper";

interface Props {
  template?: boolean;
}

const Groups = ({ template = true }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const [query, setQuery] = useState("");
  const colDef =
    types?.components.schemas.RetailerGroupListSummaryRead.properties ?? {};
  const {
    data: groups,
    isLoading,
    isFetching,
  } = useGetGroupsQuery(query, {
    skip: query === "",
  });

  const colToOverride: GridColDef[] = [
    {
      field: "name",
      renderCell: (params) => (
        <Box
          onClick={() =>
            navigate(`${AppRoutes.MANAGE_GROUP}/${params.id.toString()}`)
          }
          className="clickableBox"
        >
          {params.value}
        </Box>
      ),
    },
    { field: "id" },
    {
      field: "type.name",
      headerName: "type",
      renderCell: (params) => params.row.type.name,
    },
    {
      field: "created_at",
      valueGetter: (created_at: string) => formatDate(created_at ?? ""),
    },
  ];

  const clearFilter = () => {
    setQuery("");
  };

  return (
    <ConditionalWrapper
      condition={template}
      wrapper={(children) => (
        <PageTemplate
          title="Groups"
          button="New group"
          link={AppRoutes.CREATE_GROUP}
          clearFilterButton={
            hasQueryFilters(query) ? "Remove filters" : undefined
          }
          onClickClearFilterButton={clearFilter}
        >
          {children}
        </PageTemplate>
      )}
    >
      <Box className="Groups">
        {types && (
          <GenericTable
            rawData={groups}
            isLoading={isLoading}
            isFetching={isFetching}
            colDef={colDef}
            colToOverride={colToOverride}
            setQuery={setQuery}
            query={query}
          />
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default Groups;
