import {
  FirmwareCompatibilityLabel,
  FirmwareCompatibilityLabels,
  CreateFirmwareCompatibilityLabel,
  CreateFirmwareCompatibilityLabelResponse,
  UpdateFirmwareCompatibilityLabelResponse,
  UpdateFirmwareCompatibilityLabel,
} from "../../../types/rtk/software/firwmare/firmware-compatibility-label";
import { apiSlice } from "../../_api/api-slice";

export const firmwares = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFirmwaresCompatibilityLabel: build.query<
      FirmwareCompatibilityLabels,
      string
    >({
      query: (url) => ({
        url: `firmware-compatibility-label${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [
        { type: "FirmwaresCompatibilityLabel" },
      ],
    }),
    getFirmwaresCompatibilityLabelById: build.query<
      FirmwareCompatibilityLabel,
      string
    >({
      query: (id) => ({
        url: `firmware-compatibility-label${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "Firmware", id: `Firmware_${id}` },
      ],
    }),
    createFirmwaresCompatibilityLabel: build.mutation<
      CreateFirmwareCompatibilityLabelResponse,
      CreateFirmwareCompatibilityLabel
    >({
      query: (body) => ({
        url: `firmware-compatibility-label`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Firmwares" }],
    }),
    updateFirmwaresCompatibilityLabel: build.mutation<
      UpdateFirmwareCompatibilityLabelResponse,
      { body: UpdateFirmwareCompatibilityLabel; id: string }
    >({
      query: ({ body, id }) => ({
        url: `firmware-compatibility-label/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Firmwares" }],
    }),
    deleteFirmwaresCompatibilityLabel: build.mutation<null, string>({
      query: (id) => ({
        url: `firmware-compatibility-label/${id}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, _id) => [{ type: "Firmwares" }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetFirmwaresCompatibilityLabelQuery,
  useGetFirmwaresCompatibilityLabelByIdQuery,
  useCreateFirmwaresCompatibilityLabelMutation,
  useUpdateFirmwaresCompatibilityLabelMutation,
  useDeleteFirmwaresCompatibilityLabelMutation,
} = firmwares;
