import { Menu, MenuItem } from "@mui/material";

import { useAppDispatch } from "../../../rtk/_config/hooks";
import { setAuth } from "../../../rtk/auth/auth";
import { setTypes } from "../../../rtk/allTypes/allTypes";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/AppRoutes";

interface Props {
  open: boolean;
  handleClose: () => void;
  anchorEl: null | HTMLElement;
}

const HeaderMenu = ({ open, handleClose, anchorEl }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    dispatch(setTypes({ types: undefined }));
    dispatch(setAuth({ token: undefined }));
  };

  return (
    <Menu
      className={`HeaderMenu`}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={() => navigate(AppRoutes.ACCOUNT)}>
        My account
      </MenuItem>
      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
    </Menu>
  );
};

export default HeaderMenu;
