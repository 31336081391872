import { FormEvent, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import InputText from "../../atoms/InputText/InputText";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import {
  Affiliate,
  CreateAffiliate,
} from "../../../types/rtk/promo-codes/affiliate/affiliate";
import { AppRoutes } from "../../../routes/AppRoutes";
import {
  useCreateAffiliateMutation,
  useUpdateAffiliateMutation,
} from "../../../rtk/promo-codes/affiliate/affiliate";

interface Props {
  affiliate?: Affiliate;
  onClickCancel?: () => void;
}

const AffiliateForm = ({ affiliate, onClickCancel }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<CreateAffiliate>(
    {} as CreateAffiliate
  );

  const [createAffiliate] = useCreateAffiliateMutation();
  const [updateAffiliate] = useUpdateAffiliateMutation();

  useEffect(() => {
    if (affiliate) {
      setFormData({
        first_name: affiliate.first_name ?? "",
        last_name: affiliate.last_name ?? "",
        mail: affiliate.mail ?? "",
      });
    }
  }, [affiliate]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = affiliate
        ? await updateAffiliate({
            body: formData,
            id: affiliate?.id?.toString() ?? "",
          }).unwrap()
        : await createAffiliate(formData).unwrap();
      setIsLoading(false);
      if (onClickCancel) {
        onClickCancel();
      } else {
        navigate(AppRoutes.MANAGE_AFFILIATE + `/${response.id}`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="AffiliateForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <InputText
                label="First name"
                required
                value={formData.first_name ?? ""}
                onChange={(value: string) => {
                  setFormData({ ...formData, first_name: value });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="Last name"
                required
                value={formData.last_name ?? ""}
                onChange={(value: string) => {
                  setFormData({ ...formData, last_name: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputText
                label="Mail"
                required
                value={formData.mail ?? ""}
                onChange={(value: string) => {
                  setFormData({ ...formData, mail: value });
                }}
                type="email"
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() =>
                    onClickCancel ? onClickCancel() : navigate(-1)
                  }
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default AffiliateForm;
