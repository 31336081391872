import { RouteObject } from "react-router-dom";

import { AppRoutes } from "../AppRoutes";
import OperatorNetwork from "../../components/pages/OperatorNetwork/OperatorNetwork";
import ManageOperatorNetwork from "../../components/pages/OperatorNetwork/ManageOperatorNetwork";

export const OperatorNetworkRoutes: RouteObject = {
  path: AppRoutes.OPERATOR_NETWORK,
  children: [
    { index: true, element: <OperatorNetwork /> },
    {
      path: AppRoutes.MANAGE_OPERATOR_NETWORK + "/:id",
      element: <ManageOperatorNetwork />,
    },
  ],
};
