import RuleForm from "../../../organisms/RuleForm/RuleForm";
import PageTemplate from "../../../templates/PageTemplate/PageTemplate";

const CreateRule = () => {
  return (
    <PageTemplate title="New Rule" cross>
      <RuleForm />
    </PageTemplate>
  );
};

export default CreateRule;
