import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import {
  CreateOTACampain,
  OTACampain,
} from "../../../types/rtk/software/ota-campain/ota-campain";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import {
  useCreateOTACampainMutation,
  useUpdateOTACampainMutation,
} from "../../../rtk/software/ota-campains/ota-campains";
import InputAutocomplete, {
  AutoCompleteDataOptions,
  AutoCompleteDatas,
} from "../../atoms/InputAutocomplete/InputAutocomplete";
import { AppRoutes } from "../../../routes/AppRoutes";
import { useGetFirmwaresCompatibilityLabelQuery } from "../../../rtk/software/firmware/firmware-compatibility-label";

interface Props {
  ota?: OTACampain;
  onClickCancel?: () => void;
}

const OTAForm = ({ ota, onClickCancel }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [labelsData, setLabelsData] = useState<AutoCompleteDataOptions[]>([]);
  const [firmwareData, setFirmwareData] = useState<AutoCompleteDataOptions[]>(
    []
  );
  const [formData, setFormData] = useState<CreateOTACampain>({
    name: "",
    firmware_to_id: 0,
    firmwares_from: [],
  } as CreateOTACampain);
  const {
    data: labels,
    isLoading: isLoadingLabels,
    isFetching: isFetchingLabels,
  } = useGetFirmwaresCompatibilityLabelQuery("?page=1&size=100");

  const [createOTACampain] = useCreateOTACampainMutation();
  const [updateOTACampain] = useUpdateOTACampainMutation();

  useEffect(() => {
    if (ota) {
      setFormData((formData) => ({ ...formData, name: ota.name ?? "" }));
    }
  }, [ota]);

  useEffect(() => {
    if (labels) {
      setLabelsData(
        labels.items?.map((obj) => ({
          label: obj.label ?? "",
          value: obj.id?.toString() ?? "",
        }))
      );
    }
  }, [labels]);

  useEffect(() => {
    if (labels && formData.name !== "") {
      const firmwares =
        labels.items?.filter((obj) => obj.label === formData.name).length > 0
          ? labels.items?.filter((obj) => obj.label === formData.name)[0]
              .firmwares
          : undefined;

      setFirmwareData(
        firmwares?.map((obj) => ({
          label: obj.name ?? "",
          value: obj.id?.toString() ?? "",
        })) ?? []
      );
    }
  }, [labels, formData.name]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = ota
        ? await updateOTACampain({
            body: formData,
            id: ota.id?.toString() ?? "",
          }).unwrap()
        : await createOTACampain(formData).unwrap();
      setIsLoading(false);
      if (onClickCancel) {
        onClickCancel();
      } else {
        navigate(AppRoutes.MANAGE_OTA_CAMPAIN + `/${response.id}`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="OTAForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputAutocomplete
                label="Label"
                value={formData.name}
                options={labelsData}
                onChange={(datas: AutoCompleteDatas) => {
                  setFormData({
                    ...formData,
                    name: (datas as AutoCompleteDataOptions).label,
                  });
                }}
                isLoading={isLoadingLabels || isFetchingLabels}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutocomplete
                label="Firmware to"
                value={formData.firmware_to_id.toString() ?? ""}
                options={firmwareData}
                onChange={(datas: AutoCompleteDatas) =>
                  setFormData({
                    ...formData,
                    firmware_to_id: parseInt(
                      (datas as AutoCompleteDataOptions).value
                    ),
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutocomplete
                label="Firmware from"
                value={formData.firmwares_from.toString()}
                options={
                  formData.firmware_to_id !== 0
                    ? firmwareData.filter(
                        (obj) =>
                          obj.value !== formData.firmware_to_id.toString()
                      )
                    : firmwareData
                }
                onChange={(datas: AutoCompleteDatas) => {
                  console.log({
                    datas,
                    value: formData.firmwares_from.toString(),
                  });
                  setFormData({
                    ...formData,
                    firmwares_from: (datas as AutoCompleteDataOptions[]).map(
                      (obj) => parseInt(obj.value)
                    ),
                  });
                }}
                multiple
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() =>
                    onClickCancel ? onClickCancel() : navigate(-1)
                  }
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default OTAForm;
