import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import {
  OperatorNetwork,
  UpdateOperatorNetwork,
} from "../../../types/rtk/software/operator-network/operator-network";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import InputSwitch from "../../atoms/InputSwitch/InputSwitch";
import InputText from "../../atoms/InputText/InputText";
import { useUpdateOperatorNetworkMutation } from "../../../rtk/software/operator-network/operator-network";

interface Props {
  operatorNetwork: OperatorNetwork;
}

const OperatorNetworkDataDisplayAndEdit = ({ operatorNetwork }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState<UpdateOperatorNetwork>({
    priority: "",
    has_cat_m: false,
    has_cat_m_edrx: false,
    has_nb_iot: false,
    has_nb_iot_edrx: false,
  });

  const [updateOperatorNetworK] = useUpdateOperatorNetworkMutation();

  useEffect(() => {
    setFormData({
      priority: operatorNetwork.priority,
      has_cat_m: operatorNetwork.has_cat_m,
      has_cat_m_edrx: operatorNetwork.has_cat_m_edrx,
      has_nb_iot: operatorNetwork.has_nb_iot,
      has_nb_iot_edrx: operatorNetwork.has_nb_iot_edrx,
    });
  }, [operatorNetwork]);

  const dataDisplay = (element: any) => {
    switch (typeof element) {
      case "boolean":
        return element ? <CheckIcon /> : <CloseIcon />;
      default:
        return element ? element : "null";
    }
  };

  const dataEdit = (value: any, key: string) => {
    switch (typeof value) {
      case "boolean":
        return (
          <InputSwitch
            label=""
            value={value}
            onChange={(value) => {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  [key]: value,
                };
              });
            }}
          />
        );
      case "string":
        return (
          <InputText
            label=""
            value={value}
            onChange={(value) => {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  [key]: value,
                };
              });
            }}
          />
        );
      default:
        return (
          <InputText
            label=""
            value={value}
            onChange={(value) => {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  [key]: value,
                };
              });
            }}
          />
        );
    }
  };

  const handleUpdateOperatorNetworK = async (body: UpdateOperatorNetwork) => {
    setIsLoading(true);
    try {
      await updateOperatorNetworK({
        body,
        id: operatorNetwork.mccmnc ?? 0,
      }).unwrap();
      setIsLoading(false);
      setEditMode(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setEditMode(false);
    }
  };

  return (
    <Box className="OperatorNetworkDataDisplayAndEdit">
      <Grid container item spacing={3} className="operatorNetworkGrid">
        <Grid container item xs={12} className="operatorNetworkData">
          <Grid item xs={6} className="rowNameData">
            {`Priority`}
          </Grid>
          <Grid item xs={6} className="data">
            {!editMode
              ? dataDisplay(formData.priority)
              : dataEdit(formData.priority, "priority")}
          </Grid>
        </Grid>

        <Grid container item xs={12} className="operatorNetworkData">
          <Grid item xs={6} className="rowNameData">
            {`Has cat m`}
          </Grid>
          <Grid item xs={6} className="data">
            {!editMode
              ? dataDisplay(formData.has_cat_m)
              : dataEdit(formData.has_cat_m, "has_cat_m")}
          </Grid>
        </Grid>

        <Grid container item xs={12} className="operatorNetworkData">
          <Grid item xs={6} className="rowNameData">
            {`Has cat m edrx`}
          </Grid>
          <Grid item xs={6} className="data">
            {!editMode
              ? dataDisplay(formData.has_cat_m_edrx)
              : dataEdit(formData.has_cat_m_edrx, "has_cat_m_edrx")}
          </Grid>
        </Grid>

        <Grid container item xs={12} className="operatorNetworkData">
          <Grid item xs={6} className="rowNameData">
            {`Has nb iot`}
          </Grid>
          <Grid item xs={6} className="data">
            {!editMode
              ? dataDisplay(formData.has_nb_iot)
              : dataEdit(formData.has_nb_iot, "has_nb_iot")}
          </Grid>
        </Grid>

        <Grid container item xs={12} className="operatorNetworkData">
          <Grid item xs={6} className="rowNameData">
            {`Has nb iot edrx`}
          </Grid>
          <Grid item xs={6} className="data">
            {!editMode
              ? dataDisplay(formData.has_nb_iot_edrx)
              : dataEdit(formData.has_nb_iot_edrx, "has_nb_iot_edrx")}
          </Grid>
        </Grid>

        <Grid container item spacing={3} className="operatorNetworkButtons">
          <Grid item xs={6} />
          <Grid item xs={6} className="button">
            {!isLoading ? (
              <StyledButton
                children={!editMode ? "EDIT" : "SAVE"}
                onClick={() =>
                  !editMode
                    ? setEditMode(true)
                    : handleUpdateOperatorNetworK(formData)
                }
              />
            ) : (
              <SpinnerLoader />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OperatorNetworkDataDisplayAndEdit;
