import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateView } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

interface Props {
  label: string;
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  className?: string;
  views?: DateView[];
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const InputDate = ({
  label,
  onChange,
  className,
  value,
  views = ["day", "month", "year"],
  minDate,
  maxDate,
}: Props) => {
  const [val, setVal] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (value !== null) {
      setVal(dayjs(value));
    } else {
      setVal(null);
    }
  }, [value]);

  const handleChange = (value: Dayjs | null) => {
    setVal(value);
    onChange(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        className={`InputDate ${className}`}
        label={label}
        value={val}
        onChange={handleChange}
        openTo="day"
        views={views}
        format="DD / MM / YYYY"
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default InputDate;
