import { useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../../atoms/StyledPaper/StyledPaper";
import {
  useDeleteRuleMutation,
  useGetRuleByIdQuery,
} from "../../../../rtk/promo-codes/rules/rules";
import Title from "../../../atoms/Title/Title";
import Text from "../../../atoms/Text/Text";
import DataDisplay, {
  DataToDisplay,
} from "../../../molecules/DataDisplay/DataDisplay";
import StyledButton from "../../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../../atoms/SpinnerLoader/SpinnerLoader";
import { AppRoutes } from "../../../../routes/AppRoutes";
import RuleForm from "../../../organisms/RuleForm/RuleForm";

const ManageRule = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { data: rule, isLoading: isLoadingRule } = useGetRuleByIdQuery(
    id ?? "",
    {
      skip: !id,
    }
  );
  const [deleteRule] = useDeleteRuleMutation();

  const ruleDataToDisplay: DataToDisplay[] = [
    { title: "Name", selector: "rule_name" },
    { title: "Activated", selector: "is_activated" },
    { title: "Gift card", selector: "is_gift_card_rule" },
    { title: "Site", selector: "site" },
  ];

  if (rule) {
    if (rule.offers?.length === 1) {
      ruleDataToDisplay.push({
        title: "1 month",
        selector: "offers[0].value",
        callBack: (value: string) => `${value} %`,
      });
    } else {
      ruleDataToDisplay.push(
        {
          title: "1 month",
          selector: "offers[0].value",
          callBack: (value: string) => `${value} %`,
        },
        {
          title: "1 year",
          selector: "offers[1].value",
          callBack: (value: string) => `${value} %`,
        },
        {
          title: "3 year",
          selector: "offers[2].value",
          callBack: (value: string) => `${value} %`,
        }
      );
    }
  }

  const handleDeleteRule = async () => {
    setIsLoading(true);
    try {
      await deleteRule(rule?.id?.toString() ?? "");
      navigate(AppRoutes.RULES);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate
      title="Manage rule"
      cross
      onClickCross={editMode === true ? () => setEditMode(false) : undefined}
    >
      <Box className="ManageRule">
        {!editMode ? (
          <StyledPaper>
            {!isLoadingRule ? (
              <>
                <Box className="dataHeader">
                  <Title variant="h3" children={rule?.rule_name} />
                  <Text children={`rule id: ${rule?.id ?? ""}`} />
                </Box>

                <Box className="data">
                  <DataDisplay
                    datas={rule}
                    datasInformations={ruleDataToDisplay}
                    orientation={"horizontal"}
                  />
                </Box>

                <Box className="buttons">
                  {!isLoading ? (
                    <>
                      <StyledButton
                        children="Delete"
                        className="cancel"
                        onClick={handleDeleteRule}
                      />
                      <StyledButton
                        children="Edit"
                        onClick={() => setEditMode(true)}
                      />
                    </>
                  ) : (
                    <SpinnerLoader />
                  )}
                </Box>
              </>
            ) : (
              <SpinnerLoader />
            )}
          </StyledPaper>
        ) : (
          <RuleForm rule={rule} onClickCancel={() => setEditMode(false)} />
        )}
      </Box>
    </PageTemplate>
  );
};

export default ManageRule;
