import {
  Affiliate,
  CreateAffiliate,
  UpdateAffiliate,
  Affiliates,
  CreateAffiliateResponse,
  UpdateAffiliateResponse,
} from "../../../types/rtk/promo-codes/affiliate/affiliate";

import { apiSlice } from "../../_api/api-slice";

export const affiliate = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAffiliates: build.query<Affiliates, string>({
      query: (url) => ({
        url: `affiliate${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Affiliates" }],
    }),
    getAffiliateById: build.query<Affiliate, string>({
      query: (id) => ({
        url: `affiliate/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "Affiliate", id: `Affiliate_${id}` },
      ],
    }),
    createAffiliate: build.mutation<CreateAffiliateResponse, CreateAffiliate>({
      query: (body) => ({
        url: `affiliate`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Affiliates" }],
    }),
    updateAffiliate: build.mutation<
      UpdateAffiliateResponse,
      { body: UpdateAffiliate; id: string }
    >({
      query: ({ body, id }) => ({
        url: `affiliate/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Affiliate", id: `Affiliate_${id}` },
        { type: "Affiliates" },
      ],
    }),
    deleteAffiliate: build.mutation<null, string>({
      query: (id) => ({
        url: `affiliate/${id}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Affiliates" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAffiliatesQuery,
  useGetAffiliateByIdQuery,
  useCreateAffiliateMutation,
  useUpdateAffiliateMutation,
  useDeleteAffiliateMutation,
} = affiliate;
