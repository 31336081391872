export const hasQueryFilters = (query: string): boolean => {
  if (
    query.includes("sort_order") ||
    query.includes("sort_field") ||
    query.includes("search") ||
    query.includes("search_fields") ||
    query.includes("f_")
  )
    return true;
  return false;
};
