import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { apiSlice } from "../_api/api-slice";
import { AllTypes, AllTypesState } from "../../types/rtk/allTypes/generalTypes";

const initialState: AllTypesState = {
  types: undefined,
};

const allTypesSlice = createSlice({
  name: "types",
  initialState,
  reducers: {
    setTypes: (state, action: PayloadAction<AllTypesState>) => {
      state.types = action.payload.types;
    },
  },
});

export const { setTypes } = allTypesSlice.actions;
const AllTypesReducer = allTypesSlice.reducer;

export default AllTypesReducer;

export const types = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTypes: build.query<AllTypes, void>({
      query: (url) => ({
        url: `openapi.json`,
        method: "get",
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetTypesQuery } = types;
