import { RouteObject } from "react-router-dom";

import { AppRoutes } from "../AppRoutes";
import OTACampains from "../../components/pages/Software/OTACampains/OTACampains";
import CreateOTACampain from "../../components/pages/Software/OTACampains/CreateOTACampain";
import ManageOTACampain from "../../components/pages/Software/OTACampains/ManageOTACampain";
import Firmware from "../../components/pages/Software/Firmware/Firmware";
import ManageFirmware from "../../components/pages/Software/Firmware/ManageFirmware";
import CreateFirmware from "../../components/pages/Software/Firmware/CreateFirmware";
import Hardware from "../../components/pages/Software/Hardware/Hardware";
import CreateHardware from "../../components/pages/Software/Hardware/CreateHardware";
import ManageHardware from "../../components/pages/Software/Hardware/ManageHardware";

export const SoftwaresRoutes: RouteObject = {
  path: AppRoutes.SOFTWARES,
  children: [
    {
      path: AppRoutes.OTA_CAMPAINS,
      element: <OTACampains />,
    },
    { path: AppRoutes.CREATE_OTA_CAMPAIN, element: <CreateOTACampain /> },
    {
      path: AppRoutes.MANAGE_OTA_CAMPAIN + "/:id",
      element: <ManageOTACampain />,
    },
    {
      path: AppRoutes.FIRMWARES,
      element: <Firmware />,
    },
    { path: AppRoutes.CREATE_FIRMWARE, element: <CreateFirmware /> },
    {
      path: AppRoutes.MANAGE_FIRMWARE + "/:id",
      element: <ManageFirmware />,
    },
    {
      path: AppRoutes.HARDWARES,
      element: <Hardware />,
    },
    { path: AppRoutes.CREATE_HARDWARE, element: <CreateHardware /> },
    {
      path: AppRoutes.MANAGE_HARDWARE + "/:id",
      element: <ManageHardware />,
    },
  ],
};
