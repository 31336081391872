export const findKeyInUrlParams = (
  params: URLSearchParams,
  keyTofind: string
): string => {
  let key = "";
  params.forEach((_value, name) => {
    if (name.includes(keyTofind)) {
      key = name;
    }
  });
  return key;
};
