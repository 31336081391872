import {
  OrderB2Bs,
  CreateOrderB2B,
  OrderB2B,
  CreateOrderB2BResponse,
} from "../../types/rtk/orderB2B/orderB2B";
import { apiSlice } from "../_api/api-slice";

export const orderB2Bs = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrderB2Bs: build.query<OrderB2Bs, string>({
      query: (url) => ({
        url: `order-b2b${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "OrderB2Bs" }],
    }),
    getOrderB2BById: build.query<OrderB2B, string>({
      query: (id) => ({
        url: `order-b2b/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "OrderB2B", id: `order-b2b_${id}` },
      ],
    }),
    createOrderB2B: build.mutation<CreateOrderB2BResponse, CreateOrderB2B>({
      query: (body) => ({
        url: `order-b2b`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "OrderB2Bs" }],
    }),
    deleteOrderB2B: build.mutation<null, string>({
      query: (id) => ({
        url: `order-b2b/${id}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, _id) => [{ type: "OrderB2Bs" }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetOrderB2BsQuery,
  useGetOrderB2BByIdQuery,
  useCreateOrderB2BMutation,
  useDeleteOrderB2BMutation,
} = orderB2Bs;
