import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../../atoms/StyledPaper/StyledPaper";
import Title from "../../../atoms/Title/Title";
import Text from "../../../atoms/Text/Text";
import DataDisplay, {
  DataToDisplay,
} from "../../../molecules/DataDisplay/DataDisplay";
import SpinnerLoader from "../../../atoms/SpinnerLoader/SpinnerLoader";
import { useGetFirmwareByIdQuery } from "../../../../rtk/software/firmware/firmware";
import { formatDate } from "../../../../helpers/formatDate";
import { useGetFeaturesQuery } from "../../../../rtk/software/firmware/feature";
import FeatureDataDisplayAndEdit from "../../../organisms/FeatureDataDisplayAndEdit/FeatureDataDisplayAndEdit";
import StyledButton from "../../../atoms/StyledButton/StyledButton";

const ManageFirmware = () => {
  let { id } = useParams();
  const { data: firmware, isLoading: isLoadingFirmware } =
    useGetFirmwareByIdQuery(id ?? "", {
      skip: !id,
    });

  const { data: feature } = useGetFeaturesQuery(
    `?f_firmware_id=${firmware?.id}`,
    {
      skip: !firmware,
    }
  );

  const firmwareDataToDisplay: DataToDisplay[] = [
    {
      title: "Id",
      selector: "id",
    },
    {
      title: "Name",
      selector: "name",
    },
    {
      title: "Created at",
      selector: "created_at",
      callBack: (data) => formatDate(data),
    },
    {
      title: "Updated at",
      selector: "updated_at",
      callBack: (data) => formatDate(data),
    },
    {
      title: "Link",
      selector: "link",
    },
    {
      title: "Command",
      selector: "command",
    },
    {
      title: "Release note",
      selector: "release_note",
    },
  ];

  const navigateToFeatureDescription = () =>
    window.open(
      "https://weenect.atlassian.net/wiki/spaces/IT/pages/900857861/D+finition+des+Features+d+un+Firmware",
      "_blank",
      "noreferrer"
    );

  return (
    <PageTemplate title="Manage Firmware" cross>
      <Box className="ManageFirmware">
        {firmware && !isLoadingFirmware ? (
          <>
            <StyledPaper>
              <Box className="dataHeader">
                <Title variant="h3" children={`Firmware: ${firmware?.name}`} />
                <Text
                  children={`Compatibility Label:  ${
                    firmware?.compatibility_label?.label ?? ""
                  }`}
                />
              </Box>

              <Box className="data">
                <DataDisplay
                  datas={firmware}
                  datasInformations={firmwareDataToDisplay}
                  orientation={"horizontal"}
                />
              </Box>
            </StyledPaper>

            {feature && feature.items.length > 0 && (
              <StyledPaper>
                <Box className="dataHeader">
                  <Title variant="h3" children={`Features`} />
                </Box>

                <Box className="data">
                  <FeatureDataDisplayAndEdit
                    data={feature.items}
                    firmwareId={firmware.id ?? 0}
                  />
                </Box>
              </StyledPaper>
            )}

            <StyledPaper>
              <Box className="dataHeader">
                <Title variant="h3" children={`Feature Description`} />
              </Box>

              <Box className="data">
                <StyledButton
                  children="Go to description"
                  onClick={navigateToFeatureDescription}
                />
              </Box>
            </StyledPaper>
          </>
        ) : (
          <SpinnerLoader />
        )}
      </Box>
    </PageTemplate>
  );
};

export default ManageFirmware;
