import { FormEvent, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import InputAutocomplete, {
  AutoCompleteDataOptions,
  AutoCompleteDatas,
} from "../../atoms/InputAutocomplete/InputAutocomplete";
import InputText from "../../atoms/InputText/InputText";
import InputNumber from "../../atoms/InputNumber/InputNumber";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import {
  useCreateRetailerMutation,
  useUpdateRetailerMutation,
} from "../../../rtk/retailers/retailers/retailers";
import {
  useGetGroupsQuery,
  useGetGroupsTypesQuery,
} from "../../../rtk/retailers/group/group";
import { getUrlParameters } from "../../../helpers/getUrlParameters";
import { countries } from "../../../helpers/constants/countries";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import {
  CreateRetailer,
  Retailer,
} from "../../../types/rtk/retailers/retailers/retailers";
import { AppRoutes } from "../../../routes/AppRoutes";
import { components } from "../../../types/rtk/allTypes/allTypes";

interface Props {
  retailer?: Retailer;
  onClickCancel?: () => void;
}

const RetailerForm = ({ retailer, onClickCancel }: Props) => {
  const navigate = useNavigate();
  const [groupTypeData, setGroupeTypeData] = useState<
    AutoCompleteDataOptions[]
  >([]);
  const [groupTypeId, setGroupeTypeId] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [groupsData, setGroupsData] = useState<AutoCompleteDataOptions[]>([]);
  const [countriesData, setCountriesData] = useState<AutoCompleteDataOptions[]>(
    []
  );
  const [formData, setFormData] = useState<CreateRetailer>({
    months_free: 0,
  } as CreateRetailer);
  const {
    data: groupsType,
    isLoading: isLoadingGroupTypes,
    isFetching: isFetchingGroupTypes,
  } = useGetGroupsTypesQuery(
    getUrlParameters({ sort: { order: "asc", field: "name" } })
  );
  const {
    data: groups,
    isLoading: isLoadingGroups,
    isFetching: isFetchingGroups,
  } = useGetGroupsQuery(
    getUrlParameters({
      filters: [
        {
          operator: "equals",
          query: groupTypeId?.toString(),
          field: "type_id",
        },
        { operator: "contains", query: undefined, field: "name" },
      ],
    }),
    { skip: !groupTypeId }
  );
  const [createRetailer] = useCreateRetailerMutation();
  const [updateRetailer] = useUpdateRetailerMutation();

  useEffect(() => {
    if (groupsType) {
      setGroupeTypeData(
        groupsType?.items.map((item) => {
          return { label: item.name ?? "", value: item.id?.toString() ?? "" };
        }) ?? []
      );
    }
  }, [groupsType]);

  useEffect(() => {
    if (countries) {
      setCountriesData(
        Object.keys(countries).map((name) => {
          return { label: countries[name].name ?? "", value: name };
        }) ?? []
      );
    }
  }, []);

  useEffect(() => {
    if (groups) {
      setGroupsData(
        groups.items.map((group) => {
          return { label: group.name ?? "", value: group.id?.toString() ?? "" };
        }) ?? []
      );
    }
  }, [groups]);

  useEffect(() => {
    if (retailer) {
      setFormData({
        address: retailer.address ?? "",
        city: retailer.city ?? "",
        country:
          (retailer.country as components["schemas"]["EnumCountryAlpha2"]) ??
          undefined,
        group_id: retailer.group_id ?? 0,
        name: retailer.name ?? "",
        postal_code: retailer.postal_code ?? "",
        months_free: retailer.months_free ?? 0,
      });
      setGroupeTypeId(retailer.group?.type?.id);
    }
  }, [retailer]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = retailer
        ? await updateRetailer({
            body: formData,
            id: retailer.id?.toString() ?? "",
          }).unwrap()
        : await createRetailer(formData).unwrap();
      setIsLoading(false);
      if (onClickCancel) {
        onClickCancel();
      } else {
        navigate(AppRoutes.MANAGE_RETAILER + `/${response.id}`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="RetailerForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <InputAutocomplete
                label="Type"
                value={groupTypeId?.toString() ?? ""}
                options={groupTypeData}
                onChange={(datas: AutoCompleteDatas) => {
                  setGroupeTypeId(
                    parseInt((datas as AutoCompleteDataOptions).value)
                  );
                }}
                isLoading={isLoadingGroupTypes || isFetchingGroupTypes}
              />
            </Grid>
            {groupTypeId && (
              <Grid item xs={6}>
                <InputAutocomplete
                  label="Search by group"
                  value={formData.group_id?.toString() ?? ""}
                  options={groupsData}
                  onChange={(datas: AutoCompleteDatas) => {
                    setFormData({
                      ...formData,
                      group_id: parseInt(
                        (datas as AutoCompleteDataOptions).value
                      ),
                    });
                  }}
                  isLoading={isLoadingGroups || isFetchingGroups}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <InputText
                label="Name"
                required
                value={formData.name ?? ""}
                onChange={(value) => setFormData({ ...formData, name: value })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="Adresse"
                value={formData.address ?? ""}
                onChange={(value) =>
                  setFormData({ ...formData, address: value })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="City"
                value={formData.city ?? ""}
                onChange={(value) => setFormData({ ...formData, city: value })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                label="Zip code"
                value={formData.postal_code ?? ""}
                onChange={(value) =>
                  setFormData({ ...formData, postal_code: value })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputAutocomplete
                label="Country"
                value={formData.country ?? ""}
                onChange={(datas: AutoCompleteDatas) =>
                  setFormData({
                    ...formData,
                    country: (datas as AutoCompleteDataOptions)
                      .value as components["schemas"]["EnumCountryAlpha2"],
                  })
                }
                options={countriesData}
              />
            </Grid>
            <Grid item xs={6}>
              <InputNumber
                label="Month free"
                value={formData.months_free}
                onChange={(value) =>
                  setFormData({ ...formData, months_free: value })
                }
                required
                maxValue={100}
                minValue={0}
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() =>
                    onClickCancel ? onClickCancel() : navigate(-1)
                  }
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default RetailerForm;
