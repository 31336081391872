import {
  Rule,
  CreateRule,
  UpdateRule,
  Rules,
  CreateRuleResponse,
  UpdateRuleResponse,
  Subscription,
} from "../../../types/rtk/promo-codes/rules/rules";

import { apiSlice } from "../../_api/api-slice";

export const rules = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRules: build.query<Rules, string>({
      query: (url) => ({
        url: `promo-code-rule${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Promo_code_rules" }],
    }),
    getRuleById: build.query<Rule, string>({
      query: (id) => ({
        url: `promo-code-rule/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "Promo_code_rule", id: `Promo_code_rule_${id}` },
      ],
    }),
    createRule: build.mutation<CreateRuleResponse, CreateRule>({
      query: (body) => ({
        url: `promo-code-rule`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [
        { type: "Promo_code_rules" },
      ],
    }),
    updateRule: build.mutation<
      UpdateRuleResponse,
      { body: UpdateRule; id: string }
    >({
      query: ({ body, id }) => ({
        url: `promo-code-rule/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Promo_code_rule", id: `Promo_code_rule_${id}` },
        { type: "Promo_code_rules" },
      ],
    }),
    deleteRule: build.mutation<null, string>({
      query: (id) => ({
        url: `promo-code-rule/${id}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, _arg) => [
        { type: "Promo_code_rules" },
      ],
    }),
    getSubscription: build.query<
      Subscription,
      { url: string; length: number; site: string }
    >({
      query: ({ url, length, site }) => ({
        url: `subscription-offer?page=1&size=${length}&sort_order=asc&sort_field=id&f_site=${site}&f_code=${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Subscription" }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetRulesQuery,
  useGetRuleByIdQuery,
  useCreateRuleMutation,
  useUpdateRuleMutation,
  useDeleteRuleMutation,
  useGetSubscriptionQuery,
} = rules;
