import { Box, Grid } from "@mui/material";

import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import { OTACampain } from "../../../types/rtk/software/ota-campain/ota-campain";
import { formatDate } from "../../../helpers/formatDate";

interface Props {
  ota?: OTACampain;
}

const FirmwareFromDisplay = ({ ota }: Props) => {
  return (
    <Grid className="FirmwareFromDisplay" container spacing={3}>
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Box className="item">
            <Title variant="h3" children={"ID"} />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className="item">
            <Title variant="h3">{`Name`}</Title>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className="item">
            <Title variant="h3">{`Created at`}</Title>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className="item">
            <Title variant="h3">{`Updated at`}</Title>
          </Box>
        </Grid>
      </Grid>
      {ota?.firmwares_from?.map((obj, i) => (
        <Grid key={i} item container xs={12}>
          <Grid item xs={3}>
            <Box className="item">
              <Text color="tercery" children={obj.firmware?.id} />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className="item">
              <Text color="tercery">{`${obj.firmware?.name}`}</Text>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className="item">
              <Text color="tercery">{`${formatDate(
                obj.firmware.created_at ?? ""
              )}`}</Text>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className="item">
              <Text color="tercery">{`${formatDate(
                obj.firmware.updated_at ?? ""
              )}`}</Text>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default FirmwareFromDisplay;
