import { GridColDef } from "@mui/x-data-grid";

interface Props {
  origine: GridColDef[];
  newData: GridColDef[];
  selector: keyof GridColDef;
  defaultProps?: GridColDef;
}

export const mergeAndUpdateArrayOfObject = ({
  origine,
  newData,
  selector,
  defaultProps,
}: Props): GridColDef[] => {
  var ordering: any = {} as GridColDef;

  newData.forEach((obj, i) => {
    const foundIndex = origine.findIndex(
      (ori: GridColDef) => ori[selector] === obj[selector]
    );
    if (foundIndex >= 0) {
      origine[foundIndex] = { ...origine[foundIndex], ...obj };
    } else origine.push({ ...defaultProps, ...obj });

    ordering[newData[i].field] = i;
  });

  origine.sort((a, b) => {
    return (
      ordering[a.field] - ordering[b.field] || a.field.localeCompare(b.field)
    );
  });

  return origine;
};
