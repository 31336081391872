import { FormEvent, useEffect, useMemo, useState } from "react";
import { ReactSVG } from "react-svg";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Header from "../../templates/Header/Header";
import { RootState } from "../../../types/rtk/api-config/types";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import InputText from "../../atoms/InputText/InputText";
import logo from "../../../assets/images/logo.svg";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import { AppRoutes } from "../../../routes/AppRoutes";
import { useGetTokenMutation } from "../../../rtk/auth/login";
import { setAuth } from "../../../rtk/auth/auth";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

const Login = () => {
  const navigate = useNavigate();
  const theme = useAppSelector((state: RootState) => state.theme.mode);
  const token = useAppSelector((state: RootState) => state.auth.token);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [getToken] = useGetTokenMutation();
  const dispatch = useAppDispatch();
  let location = useLocation();
  useMemo(() => {
    document.body.className = `theme--${theme}`;
  }, [theme]);

  useEffect(() => {
    if (token !== undefined) {
      navigate(location.state.path);
    }
  }, [token, navigate, location]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    try {
      const response = await getToken(formData).unwrap();
      dispatch(setAuth({ token: response.access_token }));
      setIsLoading(false);
      navigate(location.state.path);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="Login">
      <Header />
      <Box className="authentificationContent">
        <Box className="loginHeader">
          <ReactSVG src={logo} />
          <Title variant="h1" children="Log In" />
          <Text children="Sign in on the internal platform" />
          {!isLoading ? (
            <Box
              component={"form"}
              onSubmit={handleLogin}
              autoComplete="on"
              action={AppRoutes.BASE_ROUTE}
              noValidate
              className="form"
            >
              <Box className="inputs">
                <InputText
                  label="Email Address"
                  value={email}
                  onChange={(value: string) => setEmail(value)}
                  type="email"
                  name="email"
                  autoComplete="username"
                  placeholder="Ex: ******@weenect.com"
                />
                <InputText
                  label="Password"
                  value={password}
                  onChange={(value: string) => setPassword(value)}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  autoComplete="current-password"
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <StyledButton type={"submit"}>Log In</StyledButton>
              </Box>
            </Box>
          ) : (
            <SpinnerLoader />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
