import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridRowsProp,
  GridColDef,
  GridSortModel,
  GridFilterModel,
  getGridStringOperators,
  GridSlots,
  GridColumnVisibilityModel,
  GridSortDirection,
  GridPaginationModel,
  GridRowEditStopReasons,
  GridEventListener,
  GridRowModesModel,
  GridRowModes,
  GridActionsCellItem,
  GridRowId,
  GridRowModel,
  GridValidRowModel,
  GridRowIdGetter,
} from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import { snakeCaseToTitleCase } from "../../../helpers/snakeCaseToTitleCase";
import { Page, RootState } from "../../../types/rtk/api-config/types";
import { actionsProperties } from "../../../types/rtk/allTypes/schemas/schemas";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { mergeAndUpdateArrayOfObject } from "../../../helpers/mergeAndUpdateArrayOfObject";
import { findKeyInUrlParams } from "../../../helpers/findKeyInUrlParams";
import { getUrlParameters } from "../../../helpers/getUrlParameters";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { setLoader } from "../../../rtk/loader/loader";
import { checkIfElementIsSortable } from "../../../helpers/checkIfElementIsSortable";

type colTypes =
  | "actions"
  | "string"
  | "number"
  | "boolean"
  | "date"
  | "dateTime"
  | "custom"
  | "singleSelect";

interface Data extends Page {
  items: any[];
}

interface Props {
  rawData?: Data;
  isLoading: boolean;
  isFetching?: boolean;
  colDef: actionsProperties;
  colToOverride: GridColDef[];
  setQuery?: Dispatch<SetStateAction<string>>;
  query?: string;
  editable?: boolean;
  handleUpdateRow?: (newRow: GridRowModel) => void;
  getRowId?: GridRowIdGetter<GridValidRowModel>;
}

const getTypesFromCol = (type: string): colTypes => {
  switch (type) {
    case "integer":
      return "number";
    case "date-time":
      return "string";
    default:
      return type as colTypes;
  }
};

const GenericTable = ({
  rawData,
  isLoading,
  isFetching,
  colDef,
  colToOverride,
  setQuery,
  query,
  editable,
  handleUpdateRow,
  getRowId,
}: Props) => {
  const dispatch = useAppDispatch();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const loader = useAppSelector((state: RootState) => state.loader.isLoading);
  /** Sort and filter
   * states
   */
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const size = searchParams.get("size");
  const sort_order = searchParams.get("sort_order");
  const sort_field = searchParams.get("sort_field");
  const search = searchParams.get("search");
  const search_fields = searchParams.get("search_fields");
  const f_field = findKeyInUrlParams(searchParams, "f_");
  const f_field_query = searchParams.get(f_field);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [queryOptionsSort, setQueryOptionsSort] = useState<GridSortModel>();
  const [queryOptionsFilter, setQueryOptionsFilter] =
    useState<GridFilterModel>();
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();
  const [columnToQuery, setColumnToQuery] = useState<string[]>([]);
  const [quickFilter, setQuickFilter] = useState("");
  const [muiTableKey, setMuiTableKey] = useState(0);
  /** Edit
   * states
   */
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const rowCountRef = useRef(
    rawData
      ? rawData.total
        ? rawData.total
        : rawData?.items
        ? rawData?.items.length
        : 0
      : 0
  );
  const stringOperators = getGridStringOperators().filter((op) =>
    ["equals", "contains"].includes(op.value)
  );

  const defaultProps: GridColDef = {
    field: "",
    headerClassName: "header",
    headerAlign: "center",
    align: "center",
    filterOperators: stringOperators,
    hideable: true,
    flex: 1,
    minWidth: 200,
  };

  // const rows: GridRowsProp = rawData ? rawData?.items : [];

  let columns: GridColDef[] = Object.keys(colDef).map((obj) => {
    return {
      ...defaultProps,
      field: obj,
      headerName: colDef[obj].title
        ? colDef[obj].title
        : snakeCaseToTitleCase(obj),
      type: getTypesFromCol(
        colDef[obj].format ? colDef[obj].format ?? "" : colDef[obj].type ?? ""
      ),
      description: colDef[obj].description,
      sortable:
        colDef[obj].sortable !== undefined ? colDef[obj].sortable : true,
      filterable:
        colDef[obj].sortable !== undefined ? colDef[obj].sortable : true,
    };
  });

  const sortColumns = useMemo(
    () =>
      (col: GridColDef[]): GridColDef[] => {
        //Sorting before reduce to keep column ordering on the initial state
        return col.sort((col1, col2) => {
          const index1 = colToOverride.map((e) => e.field).indexOf(col1.field);
          const index2 = colToOverride.map((e) => e.field).indexOf(col2.field);
          return (
            (index1 > -1 ? index1 : Infinity) -
            (index2 > -1 ? index2 : Infinity)
          );
        });
      },
    [colToOverride]
  );

  const reduceColumns = useMemo(
    () =>
      (col: GridColDef[]): GridColumnVisibilityModel => {
        const columnToShow = col.reduce(
          (a: GridColDef, v: GridColDef) => ({
            ...a,
            [v.field]: Boolean(
              colToOverride.find((el) => el.field === v.field)
            ),
            actions: editable ? true : false,
          }),
          {} as GridColDef
        );

        return columnToShow as unknown as GridColumnVisibilityModel;
      },
    [colToOverride, editable]
  );

  if (colToOverride) {
    columns = sortColumns(
      mergeAndUpdateArrayOfObject({
        origine: columns,
        newData: colToOverride,
        selector: "field",
        defaultProps: defaultProps,
      })
    );
  }

  if (editable) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    });
  }

  useEffect(() => {
    if (setQuery) {
      setQuery(
        getUrlParameters({
          page: paginationModel.page + 1,
          size: paginationModel.pageSize,
          sortGrid: queryOptionsSort,
          filterGrid: queryOptionsFilter,
          columnVisibilityModel: columnToQuery,
          quickFilter: quickFilter,
          showInUrl: true,
        })
      );
    }
  }, [
    columnToQuery,
    paginationModel,
    queryOptionsFilter,
    queryOptionsSort,
    quickFilter,
    query,
    setQuery,
  ]);

  useEffect(() => {
    if (query === "") {
      setQueryOptionsFilter(undefined);
      setQueryOptionsSort(undefined);
      setQuickFilter("");
      setMuiTableKey(muiTableKey + 1);
    }
  }, [query, muiTableKey]);

  useEffect(
    () => {
      if (page) {
        setPaginationModel({ ...paginationModel, page: parseInt(page) });
      }
      if (size) {
        setPaginationModel({ ...paginationModel, pageSize: parseInt(size) });
      }
      if (sort_order && sort_field) {
        setQueryOptionsSort([
          { field: sort_field, sort: sort_order as GridSortDirection },
        ]);
      }
      if (search_fields && search) {
        setQueryOptionsFilter({
          ...queryOptionsFilter,
          items: [
            { field: search_fields, value: search, operator: "contains" },
          ],
        });
      }
      if (f_field) {
        setQueryOptionsFilter({
          ...queryOptionsFilter,
          items: [{ field: f_field, value: f_field_query, operator: "equals" }],
        });
      }
      setColumnVisibilityModel(reduceColumns(columns));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (rawData) {
      setRows(rawData ? rawData?.items : []);
    }
  }, [rawData]);

  useEffect(() => {
    if (columnVisibilityModel) {
      const queryColumnsArray: string[] = [];

      Object.keys(columnVisibilityModel).forEach((element) => {
        if (columnVisibilityModel[element] === true) {
          if (element.includes(".")) {
            if (checkIfElementIsSortable({ element, colDef, types })) {
              queryColumnsArray.push(element);
            }
          } else if (colDef[element] && colDef[element].sortable !== false) {
            queryColumnsArray.push(element);
          }
        }
      });

      setColumnToQuery(queryColumnsArray);
    }
  }, [
    colDef,
    columnVisibilityModel,
    types?.components.schemas.RetailerGroupObj.properties,
    types?.components.schemas.RetailerGroupTypeSummaryRead.properties,
    types?.components.schemas.FirmwareCompatibilityLabelObjRoot.properties,
    types,
  ]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(setLoader({ isLoading: isFetching ?? false })); //change
    }
  }, [isFetching, loader, dispatch, isLoading]);

  const rowCount = useMemo(() => {
    if (rawData?.total !== undefined) {
      rowCountRef.current = rawData.total;
    }
    return rowCountRef.current;
  }, [rawData?.total]);

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setQueryOptionsSort([...sortModel]);
    },
    [setQueryOptionsSort]
  );

  const onFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setQueryOptionsFilter({ ...filterModel });
    },
    [setQueryOptionsFilter]
  );

  const onPaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      setPaginationModel({ ...model });
    },
    [setPaginationModel]
  );

  const onColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) =>
      setColumnVisibilityModel({ ...model }),
    []
  );

  const onRowModesModelChange = useCallback(
    (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    },
    [setRowModesModel]
  );

  const onRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (
    newRow: GridRowModel
  ): GridValidRowModel | Promise<GridValidRowModel> => {
    const updatedRow = { ...newRow, isNew: false };
    if (handleUpdateRow) {
      handleUpdateRow(newRow);
    }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const data = { columns, rows, rowCount, paginationModel };

  return (
    <Box className="GenericTable">
      <DataGrid
        {...data}
        loading={isLoading}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        editMode={editable ? "row" : undefined}
        rowModesModel={rowModesModel}
        processRowUpdate={processRowUpdate}
        key={muiTableKey}
        paginationMode="server"
        sortingMode={query ? "server" : undefined}
        filterMode={query ? "server" : undefined}
        onFilterModelChange={query ? onFilterModelChange : undefined}
        onSortModelChange={query ? onSortModelChange : undefined}
        onPaginationModelChange={onPaginationModelChange}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        onRowModesModelChange={onRowModesModelChange}
        onRowEditStop={onRowEditStop}
        disableColumnFilter={query ? false : true}
        autoHeight
        getRowId={getRowId}
        slots={{
          loadingOverlay: SpinnerLoader as GridSlots["loadingOverlay"],
          toolbar: !editable && query !== undefined ? GridToolbar : undefined,
        }}
        slotProps={{
          toolbar:
            query !== undefined
              ? {
                  showQuickFilter: true,
                  quickFilterProps: {
                    onChange: (event) => {
                      setQuickFilter(event.target.value);
                    },
                    value: quickFilter,
                  },
                }
              : undefined,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: reduceColumns(columns),
          },
        }}
      />
    </Box>
  );
};

export default GenericTable;
