import { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import StyledChip from "../StyledChip/StyledChip";

export interface AutoCompleteDataOptions {
  value: string;
  label: string;
}

export type AutoCompleteDatas =
  | string
  | AutoCompleteDataOptions
  | (string | AutoCompleteDataOptions)[]
  | null;

interface Props {
  label: string;
  value: string | string[];
  onChange: (datas: AutoCompleteDatas) => void;
  onInputChange?: (value: string) => void;
  options: AutoCompleteDataOptions[];
  className?: string;
  placeHolder?: string;
  disabled?: boolean;
  multiple?: boolean;
  separator?: string[];
  freeSolo?: boolean;
  number?: boolean;
  isLoading?: boolean;
}

const InputAutocomplete = ({
  label,
  value,
  onChange,
  onInputChange,
  options,
  className,
  disabled,
  multiple,
  separator = [" ", ",", ";"],
  freeSolo,
  number,
  isLoading,
}: Props) => {
  const [val, setVal] = useState<AutoCompleteDatas>(multiple ? [] : null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (value && options && !val) {
      setVal(
        multiple
          ? freeSolo
            ? value
            : options.filter(
                (el) =>
                  typeof value === "string" &&
                  (value.split(",").includes(el.value) ||
                    value.split(",").includes(el.label))
              )
          : options.find((el) => el.value === value || el.label === value) ??
              null
      );
    }
  }, [value, options, multiple, freeSolo, val]);

  const handleChange = (_e: SyntheticEvent, value: AutoCompleteDatas) => {
    setVal(value);
    if (value !== null) {
      onChange(value);
    }
  };

  const handleInputValueChange = (_e: SyntheticEvent, value: string) => {
    if (multiple) {
      if (Array.isArray(val)) {
        let tmpArray: string[] = [];
        for (const elm of separator) {
          if (value.includes(elm)) {
            tmpArray = value.split(elm);

            if (tmpArray[tmpArray.length - 1] === "") {
              tmpArray.pop();
            }

            if (number) {
              tmpArray = tmpArray.map((obj) => obj.replace(/\D/g, ""));
            }

            if (tmpArray[0] !== "") {
              setVal([...val, ...tmpArray]);
            }

            setInputValue("");
            return;
          }
        }
      } else {
        setVal([value]);
        setInputValue("");
      }
    }

    if (number) {
      setInputValue(value.replace(/\D/g, ""));
    } else {
      setInputValue(value);
    }

    if (onInputChange) {
      onInputChange(value);
    }
  };

  return (
    <Box className={`InputAutocomplete ${className}`}>
      <Autocomplete
        disablePortal
        value={val}
        getOptionLabel={(option) =>
          typeof option !== "string" ? option.label : option || ""
        }
        getOptionKey={(obj) => (typeof obj !== "string" ? obj.value : obj)}
        onChange={handleChange}
        options={options}
        inputValue={inputValue}
        renderInput={(params) => <TextField {...params} label={label} />}
        onInputChange={handleInputValueChange}
        disabled={disabled}
        multiple={multiple}
        freeSolo={freeSolo}
        autoSelect={true}
        isOptionEqualToValue={(option, value) =>
          typeof option !== "string" && typeof value !== "string"
            ? option.value === value.value
            : option === value
        }
        renderTags={
          multiple
            ? (tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <StyledChip
                      key={key}
                      label={typeof option !== "string" ? option.label : option}
                      {...tagProps}
                    />
                  );
                })
            : undefined
        }
        loading={isLoading}
      />
    </Box>
  );
};

export default InputAutocomplete;
