import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "./styles/main.scss";
import App from "./App";
import { AppRoutes } from "./routes/AppRoutes";
import { RetailersRoutes } from "./routes/Retailers/Retailers";
import ErrorPage from "./components/pages/ErrorPage/ErrorPage";
import store from "./rtk/_config/store";
import Overview from "./components/pages/Overview/Overview";
import Login from "./components/pages/Login/Login";
import Account from "./components/pages/Account/Account";
import { PromoCodesRoutes } from "./routes/PromoCodes/PromoCodes";
import { SoftwaresRoutes } from "./routes/Softwares/Softwares";
import { OperatorNetworkRoutes } from "./routes/OperatorNetwork/OperatorNetwork";
import { QualityCheckRoutes } from "./routes/QualityCheck/QualityCheck";
import { OrderB2BRoutes } from "./routes/OrderB2B/OrderB2B";
import { UsersRoutes } from "./routes/Users/Users";

const router = createBrowserRouter([
  {
    path: AppRoutes.LOGIN,
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: AppRoutes.BASE_ROUTE,
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Overview />,
      },
      PromoCodesRoutes,
      RetailersRoutes,
      SoftwaresRoutes,
      OperatorNetworkRoutes,
      QualityCheckRoutes,
      OrderB2BRoutes,
      UsersRoutes,
      {
        path: AppRoutes.ACCOUNT,
        element: <Account />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
