import {
  Retailers,
  CreateRetailer,
  UpdateRetailer,
  Retailer,
  MergeRetailer,
  CreateRetailerResponse,
  UpdateRetailerResponse,
} from "../../../types/rtk/retailers/retailers/retailers";
import { apiSlice } from "../../_api/api-slice";

export const retailers = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRetailers: build.query<Retailers, string>({
      query: (url) => ({
        url: `retailer${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Retailers" }],
    }),
    getRetailerById: build.query<Retailer, string>({
      query: (id) => ({
        url: `retailer/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "Retailer", id: `retailer_${id}` },
      ],
    }),
    createRetailer: build.mutation<CreateRetailerResponse, CreateRetailer>({
      query: (body) => ({
        url: `retailer`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Retailers" }],
    }),
    updateRetailer: build.mutation<
      UpdateRetailerResponse,
      { body: UpdateRetailer; id: string }
    >({
      query: ({ body, id }) => ({
        url: `retailer/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Retailer", id: `retailer_${id}` },
        { type: "Retailers" },
      ],
    }),
    mergeRetailer: build.mutation<{}, MergeRetailer>({
      query: (body) => ({
        url: `retailer/merge`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Retailers" }],
    }),
    deleteRetailer: build.mutation<null, string>({
      query: (id) => ({
        url: `retailer/${id}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, _id) => [{ type: "Retailers" }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetRetailersQuery,
  useGetRetailerByIdQuery,
  useCreateRetailerMutation,
  useUpdateRetailerMutation,
  useMergeRetailerMutation,
  useDeleteRetailerMutation,
} = retailers;
