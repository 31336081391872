import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../../types/rtk/auth/login";

const initialState: AuthState = {
  token: undefined,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthState>) => {
      state.token = action.payload.token;
    },
  },
});

export const { setAuth } = AuthSlice.actions;
const AuthReducer = AuthSlice.reducer;

export default AuthReducer;
