import { useState } from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import PageTemplate from "../../templates/PageTemplate/PageTemplate";
import GenericTable from "../../molecules/GenericTable/GenericTable";
import { AppRoutes } from "../../../routes/AppRoutes";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { hasQueryFilters } from "../../../helpers/hasQueryFilter";
import ConditionalWrapper from "../../atoms/ConditionalWrapper/ConditionalWrapper";
import { useGetUsersQuery } from "../../../rtk/users/users";

interface Props {
  template?: boolean;
}

const Users = ({ template = true }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const [query, setQuery] = useState("");
  const colDef = types?.components.schemas.UserSummaryRead.properties ?? {};
  const {
    data: retailers,
    isLoading,
    isFetching,
  } = useGetUsersQuery(query, {
    skip: query === "",
  });

  const colToOverride: GridColDef[] = [
    {
      field: "id",
      renderCell: (params) => (
        <Box
          onClick={() =>
            navigate(`${AppRoutes.MANAGE_USERS}/${params.id.toString()}`)
          }
          className="clickableBox"
        >
          {params.value}
        </Box>
      ),
    },
    { field: "firstname" },
    { field: "lastname" },
    { field: "phone" },
    { field: "country" },
  ];

  const clearFilter = () => {
    setQuery("");
  };

  return (
    <ConditionalWrapper
      condition={template}
      wrapper={(children) => (
        <PageTemplate
          title="Users"
          clearFilterButton={
            hasQueryFilters(query) ? "Remove filters" : undefined
          }
          onClickClearFilterButton={clearFilter}
        >
          {children}
        </PageTemplate>
      )}
    >
      <Box className="Users">
        {types && (
          <GenericTable
            rawData={retailers}
            isLoading={isLoading}
            isFetching={isFetching}
            colDef={colDef}
            colToOverride={colToOverride}
            setQuery={setQuery}
            query={query}
          />
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default Users;
