/**
 * See /.env
 */
export interface IConfig {
  REACT_APP_APIV4_BASE_URL: string;
}

export const config: IConfig = {
  REACT_APP_APIV4_BASE_URL: process.env.REACT_APP_APIV4_BASE_URL || "",
};
