import { AllTypes } from "../types/rtk/allTypes/generalTypes";
import { actionsProperties } from "../types/rtk/allTypes/schemas/schemas";

interface Arg {
  element: string;
  colDef: actionsProperties;
  types?: AllTypes;
  schema?: string;
}

export const checkIfElementIsSortable = ({
  element,
  colDef,
  types,
  schema,
}: Arg): boolean => {
  if (element.includes(".")) {
    //Get schema from first call or recursive
    const objectDef =
      schema && types?.components.schemas[schema].properties
        ? types?.components.schemas[schema].properties
        : colDef;
    const elementsSplitted = element.split(".");
    const firstElement = elementsSplitted[0];
    const currentElement = objectDef[firstElement];

    if (currentElement && currentElement.$ref) {
      //get the new schema
      const getSchema =
        currentElement.$ref.split("/")[
          currentElement.$ref.split("/").length - 1
        ];

      if (elementsSplitted.length > 1) {
        //check if the recursive must occure
        if (elementsSplitted.length > 2) {
          elementsSplitted.shift();
          return checkIfElementIsSortable({
            element: elementsSplitted.join("."),
            colDef: objectDef,
            types,
            schema: getSchema,
          });
        } else {
          //check the element
          if (
            types?.components.schemas[getSchema].properties &&
            types?.components.schemas[getSchema].properties[
              elementsSplitted[1]
            ] &&
            types?.components.schemas[getSchema].properties[elementsSplitted[1]]
              .sortable !== false
          ) {
            return true;
          }
        }
      }
    }
  }

  return false;
};
