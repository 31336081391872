import RetailerForm from "../../../organisms/RetailerForm/RetailerForm";
import PageTemplate from "../../../templates/PageTemplate/PageTemplate";

const CreateRetailers = () => {
  return (
    <PageTemplate title="New retailer" cross>
      <RetailerForm />
    </PageTemplate>
  );
};

export default CreateRetailers;
