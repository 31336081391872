import {
  OTACampains,
  CreateOTACampain,
  UpdateOTACampain,
  OTACampain,
  CreateOTACampainResponse,
  UpdateOTACampainResponse,
} from "../../../types/rtk/software/ota-campain/ota-campain";
import { apiSlice } from "../../_api/api-slice";

export const otacampains = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOTACampains: build.query<OTACampains, string>({
      query: (url) => ({
        url: `ota/campain${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "OTACampains" }],
    }),
    getOTACampainById: build.query<OTACampain, string>({
      query: (id) => ({
        url: `ota/campain/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "OTACampain", id: `OTACampain_${id}` },
      ],
    }),
    createOTACampain: build.mutation<
      CreateOTACampainResponse,
      CreateOTACampain
    >({
      query: (body) => ({
        url: `ota/campain`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "OTACampains" }],
    }),
    updateOTACampain: build.mutation<
      UpdateOTACampainResponse,
      { body: UpdateOTACampain; id: string }
    >({
      query: ({ body, id }) => ({
        url: `ota/campain/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "OTACampain", id: `OTACampain_${id}` },
        { type: "OTACampains" },
      ],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetOTACampainsQuery,
  useGetOTACampainByIdQuery,
  useCreateOTACampainMutation,
  useUpdateOTACampainMutation,
} = otacampains;
