import { useState } from "react";
import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import GenericTable from "../../../molecules/GenericTable/GenericTable";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useAppSelector } from "../../../../rtk/_config/hooks";
import { RootState } from "../../../../types/rtk/api-config/types";
import { useGetRulesQuery } from "../../../../rtk/promo-codes/rules/rules";
import StyledChip from "../../../atoms/StyledChip/StyledChip";
import { hasQueryFilters } from "../../../../helpers/hasQueryFilter";
import ConditionalWrapper from "../../../atoms/ConditionalWrapper/ConditionalWrapper";

interface Props {
  template?: boolean;
}

const Rules = ({ template = true }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const [query, setQuery] = useState("");
  const colDef =
    types?.components.schemas.PromoCodeRuleListSummaryRead.properties ?? {};
  const {
    data: rules,
    isLoading,
    isFetching,
  } = useGetRulesQuery(query, {
    skip: query === "",
  });

  const colToOverride: GridColDef[] = [
    {
      field: "rule_name",
      renderCell: (params: GridRenderCellParams) => (
        <Box
          onClick={() =>
            navigate(`${AppRoutes.MANAGE_RULE}/${params.id.toString()}`)
          }
          className="clickableBox"
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "offers",
      valueGetter: (offers: any[]) => {
        const arr: string[] = [];

        offers?.map((obj) =>
          arr.push(" " + obj.value?.toString() + "% " ?? " 0% ")
        );
        return arr.join("|");
      },
    },
    {
      field: "is_activated",
      renderCell: (params: GridRenderCellParams) => (
        <StyledChip
          label={
            params.value === true
              ? "active".toUpperCase()
              : "deactivated".toUpperCase()
          }
          color={params.value === true ? "success" : "error"}
        />
      ),
    },
    {
      field: "is_gift_card_rule",
      renderCell: (params: GridRenderCellParams) => (
        <StyledChip
          label={
            params.value === true ? "yes".toUpperCase() : "no".toUpperCase()
          }
          color={params.value === true ? "success" : "error"}
        />
      ),
    },
  ];

  const clearFilter = () => {
    setQuery("");
  };

  return (
    <ConditionalWrapper
      condition={template}
      wrapper={(children) => (
        <PageTemplate
          title="Rules"
          button="New rules"
          link={AppRoutes.CREATE_RULE}
          clearFilterButton={
            hasQueryFilters(query) ? "Remove filters" : undefined
          }
          onClickClearFilterButton={clearFilter}
        >
          {children}
        </PageTemplate>
      )}
    >
      <Box className="Rules">
        {types && (
          <GenericTable
            rawData={rules}
            isLoading={isLoading}
            isFetching={isFetching}
            colDef={colDef}
            colToOverride={colToOverride}
            setQuery={setQuery}
            query={query}
          />
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default Rules;
