import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useDeleteRetailerMutation,
  useGetRetailerByIdQuery,
  useGetRetailersQuery,
  useMergeRetailerMutation,
} from "../../../../rtk/retailers/retailers/retailers";
import StyledPaper from "../../../atoms/StyledPaper/StyledPaper";
import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import RetailerForm from "../../../organisms/RetailerForm/RetailerForm";
import { Box, Grid } from "@mui/material";
import StyledButton from "../../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../../atoms/SpinnerLoader/SpinnerLoader";
import Title from "../../../atoms/Title/Title";
import Text from "../../../atoms/Text/Text";
import { AppRoutes } from "../../../../routes/AppRoutes";
import InputAutocomplete, {
  AutoCompleteDataOptions,
  AutoCompleteDatas,
} from "../../../atoms/InputAutocomplete/InputAutocomplete";
import { getUrlParameters } from "../../../../helpers/getUrlParameters";
import { Retailer } from "../../../../types/rtk/retailers/retailers/retailers";
import DataDisplay from "../../../molecules/DataDisplay/DataDisplay";

const ManageRetailer = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [retailersData, setRetailersData] = useState<AutoCompleteDataOptions[]>(
    []
  );
  const [retailerToMerge, setRetailerToMerge] = useState<Retailer>();
  const [mergeMode, setMergeMode] = useState(false);
  const { data: retailer, isLoading: isLoadingRetailer } =
    useGetRetailerByIdQuery(id ?? "", { skip: !id });
  const {
    data: retailers,
    isLoading: isLoadingRetailersList,
    isFetching: isFetchingRetailersList,
  } = useGetRetailersQuery(
    getUrlParameters({
      filters: [{ operator: "contains", field: "name|id", query }],
      size: 10,
    }),
    { skip: !query }
  );
  const [deleteRetailer] = useDeleteRetailerMutation();
  const [mergeRetailer] = useMergeRetailerMutation();

  const retailerDataToDisplay = [
    { title: "Type", selector: "group.type.name" },
    { title: "Group", selector: "group.name" },
    { title: "Month free", selector: "months_free" },
    { title: "Adress", selector: "address" },
    { title: "City", selector: "city" },
    { title: "Zip code", selector: "postal_code" },
    { title: "Country", selector: "country" },
  ];

  useEffect(() => {
    if (retailers) {
      setRetailersData(
        retailers.items.map((group) => {
          return {
            label: `${group.name ?? ""} (${group.id?.toString() ?? ""})`,
            value: group.id?.toString() ?? "",
          };
        }) ?? []
      );
    }
  }, [retailers]);

  useEffect(() => {
    if (mergeMode === false) {
      setRetailerToMerge(undefined);
    }
  }, [mergeMode]);

  const handleDeleteRetailer = async () => {
    setIsLoading(true);
    try {
      await deleteRetailer(retailer?.id?.toString() ?? "");
      navigate(AppRoutes.RETAILER_CHILD);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleMergeRetailer = async () => {
    setIsLoading(true);
    try {
      await mergeRetailer({
        id_from: retailerToMerge?.id ?? 0,
        id_to: retailer?.id ?? 0,
      });
      // navigate(`${AppRoutes.MANAGE_RETAILER}/${retailer?.id}`);
      navigate(AppRoutes.RETAILER_CHILD);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate
      title="Manage retailer"
      cross
      onClickCross={editMode === true ? () => setEditMode(false) : undefined}
    >
      <Box className="ManageRetailer">
        {!editMode ? (
          <StyledPaper>
            {!isLoadingRetailer ? (
              <>
                <Box className="dataHeader">
                  <Title variant="h3" children={retailer?.name} />
                  <Text children={`retailer id: ${retailer?.id ?? ""}`} />
                </Box>

                {mergeMode && (
                  <Box className="dataMergeHeader">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box className="title">
                          <Title variant="h3" children={"Merge retailer"} />
                          <Text
                            children={`Type the retailer NAME or ID you want to merge into the selected one.`}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} className="input">
                        <InputAutocomplete
                          label="Retailer to merge"
                          value={retailerToMerge?.name ?? ""}
                          options={retailersData}
                          onChange={(datas: AutoCompleteDatas) => {
                            setRetailerToMerge(
                              retailers?.items.find(
                                (obj) =>
                                  obj.id ===
                                  parseInt(
                                    (datas as AutoCompleteDataOptions).value
                                  )
                              )
                            );
                          }}
                          onInputChange={(value) => {
                            setQuery(value);
                          }}
                          isLoading={
                            isLoadingRetailersList || isFetchingRetailersList
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Box className="data">
                  <DataDisplay
                    datas={retailer}
                    datasInformations={retailerDataToDisplay}
                    orientation={retailerToMerge ? "vertical" : "horizontal"}
                  />
                  {mergeMode && retailerToMerge && (
                    <DataDisplay
                      datas={retailerToMerge}
                      datasInformations={retailerDataToDisplay}
                      orientation="vertical"
                      arrow
                    />
                  )}
                </Box>

                <Box className="buttons">
                  {!isLoading ? (
                    mergeMode ? (
                      <>
                        <StyledButton
                          children="Cancel"
                          className="cancel"
                          onClick={() => setMergeMode(false)}
                        />
                        <StyledButton
                          children="Merge"
                          onClick={handleMergeRetailer}
                          disabled={!retailerToMerge}
                        />
                      </>
                    ) : (
                      <>
                        <StyledButton
                          children="Merge"
                          onClick={() => setMergeMode(true)}
                        />
                        <StyledButton
                          children="Delete"
                          className="cancel"
                          onClick={handleDeleteRetailer}
                        />
                        <StyledButton
                          children="Edit"
                          onClick={() => setEditMode(true)}
                        />
                      </>
                    )
                  ) : (
                    <SpinnerLoader />
                  )}
                </Box>
              </>
            ) : (
              <SpinnerLoader />
            )}
          </StyledPaper>
        ) : (
          <RetailerForm
            retailer={retailer}
            onClickCancel={() => setEditMode(false)}
          />
        )}
      </Box>
    </PageTemplate>
  );
};

export default ManageRetailer;
