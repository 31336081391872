import {
  CreateFeature,
  CreateFeatureResponse,
  Features,
  UpdateFeature,
  UpdateFeatureResponse,
} from "../../../types/rtk/software/firwmare/features";
import { apiSlice } from "../../_api/api-slice";

export const feature = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFeatures: build.query<Features, string>({
      query: (url) => ({
        url: `feature${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Features" }],
    }),
    createFeature: build.mutation<CreateFeatureResponse, CreateFeature>({
      query: (body) => ({
        url: `feature`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Features" }],
    }),
    updateFeature: build.mutation<
      UpdateFeatureResponse,
      { body: UpdateFeature; id: number }
    >({
      query: ({ body, id }) => ({
        url: `feature/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Features" }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetFeaturesQuery,
  useCreateFeatureMutation,
  useUpdateFeatureMutation,
} = feature;
