import { useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../../atoms/StyledPaper/StyledPaper";
import Title from "../../../atoms/Title/Title";
import DataDisplay from "../../../molecules/DataDisplay/DataDisplay";
import StyledButton from "../../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../../atoms/SpinnerLoader/SpinnerLoader";
import { AppRoutes } from "../../../../routes/AppRoutes";
import {
  useGetAffiliateByIdQuery,
  useDeleteAffiliateMutation,
} from "../../../../rtk/promo-codes/affiliate/affiliate";
import AffiliateForm from "../../../organisms/AffiliateForm/AffiliateForm";
import PromoCodeDisplay from "../../../molecules/PromoCodeDisplay/PromoCodeDisplay";

const ManageAffiliate = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { data: affiliate, isLoading: isLoadingAffiliate } =
    useGetAffiliateByIdQuery(id ?? "", {
      skip: !id,
    });
  const [deleteAffiliate] = useDeleteAffiliateMutation();

  const navigateToCreatePromoCode = (isGiftCard: boolean) => {
    navigate(AppRoutes.CREATE_PROMO_CODE + `/${affiliate?.id}`, {
      state: {
        isGiftCard: isGiftCard,
      },
    });
  };

  const handleDeleteAffiliate = async () => {
    setIsLoading(true);
    try {
      await deleteAffiliate(affiliate?.id?.toString() ?? "");
      navigate(AppRoutes.AFFILIATES);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const affiliateDataToDisplay = [
    { title: "First name", selector: "first_name" },
    { title: "Last name", selector: "last_name" },
    { title: "Email", selector: "mail" },
    { title: "Promo codes", selector: "promo_code_activations" },
    { title: "Site", selector: "site" },
    { title: "Activated", selector: "is_activated" },
  ];

  return (
    <PageTemplate
      title="Manage affiliate"
      cross
      onClickCross={editMode === true ? () => setEditMode(false) : undefined}
    >
      <Box className="ManageAffiliate">
        {!editMode ? (
          <>
            {!isLoadingAffiliate ? (
              <>
                <StyledPaper>
                  <Box className="dataHeader">
                    <Title
                      variant="h3"
                      children="Basic Information"
                      // children={`${affiliate?.first_name} ${affiliate?.last_name}`}
                    />
                  </Box>

                  <Box className="data">
                    <DataDisplay
                      datas={affiliate}
                      datasInformations={affiliateDataToDisplay}
                      orientation={"horizontal"}
                    />
                  </Box>

                  <Box className="buttons">
                    {!isLoading ? (
                      <>
                        <StyledButton
                          children="Delete"
                          className="cancel"
                          onClick={handleDeleteAffiliate}
                        />
                        <StyledButton
                          children="Edit"
                          onClick={() => setEditMode(true)}
                        />
                      </>
                    ) : (
                      <SpinnerLoader />
                    )}
                  </Box>
                </StyledPaper>
                <StyledPaper>
                  <Box className="dataHeader">
                    <Title variant="h3" children={`Promo codes`} />
                    <StyledButton
                      children="+ Add"
                      onClick={() => navigateToCreatePromoCode(false)}
                    />
                  </Box>

                  <Box className="data">
                    <PromoCodeDisplay
                      promoCode={affiliate?.promo_codes}
                      affiliate_id={affiliate?.id ?? 0}
                    />
                  </Box>
                </StyledPaper>

                <StyledPaper>
                  <Box className="dataHeader">
                    <Title variant="h3" children={`Gift cards`} />
                    <StyledButton
                      children="+ Add"
                      onClick={() => navigateToCreatePromoCode(true)}
                    />
                  </Box>

                  <Box className="data">
                    <PromoCodeDisplay
                      giftCard={affiliate?.gift_cards}
                      affiliate_id={affiliate?.id ?? 0}
                    />
                  </Box>
                </StyledPaper>
              </>
            ) : (
              <SpinnerLoader />
            )}
          </>
        ) : (
          <AffiliateForm
            affiliate={affiliate}
            onClickCancel={() => setEditMode(false)}
          />
        )}
      </Box>
    </PageTemplate>
  );
};

export default ManageAffiliate;
