import { useEffect, useState } from "react";
import { Box, InputAdornment } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface Props {
  label: string;
  value?: File | null;
  onChange: (value: File | null) => void;
  name?: string;
  className?: string;
  endAdornment?: string | JSX.Element;
  autoComplete?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
}

const InputFile = ({
  label,
  value,
  onChange,
  name,
  className,
  endAdornment,
  placeholder = "Insert a file",
  disabled,
  error,
  required,
}: Props) => {
  const [inputValue, setInputValue] = useState<File | null>(null);

  const handleChange = (newValue: File | null) => {
    onChange(newValue);
    setInputValue(newValue);
  };

  useEffect(() => {
    if (value) setInputValue(value);
  }, [value]);

  return (
    <Box className={`InputFile ${className}`}>
      <MuiFileInput
        label={label}
        name={name}
        value={inputValue}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        error={error}
        placeholder={placeholder}
        InputProps={
          endAdornment
            ? {
                endAdornment: (
                  <InputAdornment position="end">{endAdornment}</InputAdornment>
                ),
              }
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachFileIcon />
                  </InputAdornment>
                ),
              }
        }
      />
    </Box>
  );
};

export default InputFile;
