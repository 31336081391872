import GroupForm from "../../../organisms/GroupForm/GroupForm";
import PageTemplate from "../../../templates/PageTemplate/PageTemplate";

const CreateGroup = () => {
  return (
    <PageTemplate title="New group" cross>
      <GroupForm />
    </PageTemplate>
  );
};

export default CreateGroup;
