import { ErrorState } from "../../types/rtk/error/error";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ErrorState = {
  code: undefined,
  message: undefined,
};

const ErrorSlice = createSlice({
  name: "error",
  initialState: initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorState>) => {
      state.code = action.payload.code;
      state.message = action.payload.message;
    },
  },
});

export const { setError } = ErrorSlice.actions;
const ErrorReducer = ErrorSlice.reducer;
export default ErrorReducer;
