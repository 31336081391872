import { CSSProperties, ReactNode, useState } from "react";
import { Box, Button } from "@mui/material";

import ConfirmationModal from "../../molecules/ConfirmationModal/ConfirmationModal";

interface Props {
  text?: string;
  children?: ReactNode;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  type?: "button" | "reset" | "submit" | undefined;
  className?: string;
  variant?: "text" | "contained" | "outlined";
  confirmation?: boolean;
  titleConfirmation?: string;
  textConfirmation?: string;
  textButtonConfirmation?: string;
}

const StyledButton = ({
  text,
  children,
  style,
  onClick,
  disabled,
  type,
  className,
  variant = "contained",
  confirmation,
  titleConfirmation,
  textConfirmation,
  textButtonConfirmation,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box className={`StyledButton ${className}`} sx={style}>
      <Button
        variant={variant}
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          children === "Delete" || confirmation
            ? setIsOpen(true)
            : onClick && onClick(event)
        }
        disabled={disabled}
        type={type}
      >
        {children || text}
      </Button>
      <ConfirmationModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        action={onClick}
        titleConfirmation={titleConfirmation}
        textConfirmation={textConfirmation}
        textButtonConfirmation={textButtonConfirmation}
      />
    </Box>
  );
};

export default StyledButton;
