import { ChangeEvent, useEffect, useState } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";

interface Props {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

const InputSwitch = ({
  label,
  value,
  onChange,
  name,
  className,
  disabled,
  required,
}: Props) => {
  const [inputValue, setInputValue] = useState<boolean>(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
    setInputValue(e.target.checked);
  };

  useEffect(() => {
    if (value) setInputValue(value);
  }, [value]);

  return (
    <Box className={`InputSwitch ${className}`}>
      <FormControlLabel
        value={inputValue}
        name={name}
        control={
          <Switch
            color="primary"
            onChange={handleInputChange}
            disabled={disabled}
            required={required}
            checked={inputValue}
          />
        }
        label={label}
        labelPlacement="start"
      />
    </Box>
  );
};

export default InputSwitch;
