import { GridSortModel, GridFilterModel } from "@mui/x-data-grid";

interface Arg {
  page?: number;
  size?: number;
  sort?: { order: "asc" | "desc"; field: string };
  filters?: {
    query?: string;
    field?: string;
    operator?: "equals" | "contains";
  }[];
  sortGrid?: GridSortModel;
  filterGrid?: GridFilterModel;
  columnVisibilityModel?: string[];
  quickFilter?: string;
  showInUrl?: boolean;
}

interface paramObjet {
  [s: string]: string | number | boolean;
}

export const buildParams = (params: paramObjet): string => {
  let ret: string[] = [];
  Object.entries(params).forEach(([key, value]) => {
    ret.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
  });
  return ret.join("&");
};

export const getUrlParameters = ({
  page,
  size,
  sort,
  filters,
  sortGrid,
  filterGrid,
  columnVisibilityModel,
  quickFilter,
  showInUrl = false,
}: Arg) => {
  let params: paramObjet = {};

  if (page) {
    params = { ...params, page };
  }

  if (size) {
    params = { ...params, size };
  }

  //GRID
  if (sortGrid && sortGrid.length > 0 && sortGrid[0].sort) {
    params = {
      ...params,
      sort_order: sortGrid[0].sort,
      sort_field: sortGrid[0].field,
    };
  }

  if (sort) {
    params = {
      ...params,
      sort_order: sort?.order,
      sort_field: sort?.field,
    };
  }

  //GRID
  if (filterGrid) {
    if (filterGrid.items.length > 0 && filterGrid.items[0].value) {
      switch (filterGrid.items[0].operator) {
        case "equals":
          params = {
            ...params,
            [`${
              filterGrid.items[0].field.includes("f_") ? "" : "f_"
            }${filterGrid.items[0].field.replace(/\./g, "__")}`]:
              filterGrid.items[0].value,
          };
          break;
        case "contains":
          params = {
            ...params,
            [`search`]: filterGrid.items[0].value,
            [`search_fields`]: filterGrid.items[0].field,
          };
          break;
        default:
          break;
      }
    }
  }

  if (quickFilter && columnVisibilityModel) {
    params = {
      ...params,
      [`search`]: quickFilter,
      [`search_fields`]: columnVisibilityModel.join("|"),
    };
  }

  if (filters && filters.length > 0) {
    filters.forEach((filter) => {
      switch (filter.operator) {
        case "equals":
          if (filter.query) {
            params = {
              ...params,
              [`f_${filter.field}`]: filter.query,
            };
          }
          break;
        case "contains":
          if (filter.query && filter.field) {
            params = {
              ...params,
              [`search`]: filter.query,
              [`search_fields`]: filter.field,
            };
          }
          break;
        default:
          break;
      }
    });
  }

  if (showInUrl) {
    window.history.replaceState(null, "", `?${buildParams(params)}`);
  }

  return `?${buildParams(params)}`;
};
