import { Paper } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  noBorder?: boolean;
  className?: string;
  ref?: React.Ref<unknown> | undefined;
}

const StyledPaper = ({ children, noBorder, className, ref }: Props) => {
  return (
    <Paper
      className={`StyledPaper ${className}`}
      style={{ border: noBorder ? "none" : "", overflow: "hidden" }}
    >
      {children}
    </Paper>
  );
};

export default StyledPaper;
