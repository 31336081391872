import { RouteObject } from "react-router-dom";

import { AppRoutes } from "../AppRoutes";
import OrderB2B from "../../components/pages/OrderB2B/OrderB2B";
import CreateOrderB2B from "../../components/pages/OrderB2B/CreateOrderB2B";
import ManageOrderB2B from "../../components/pages/OrderB2B/ManageOrderB2B";

export const OrderB2BRoutes: RouteObject = {
  path: AppRoutes.ORDERB2B,
  children: [
    { index: true, element: <OrderB2B /> },
    {
      path: AppRoutes.CREATE_ORDERB2B,
      element: <CreateOrderB2B />,
    },
    {
      path: AppRoutes.MANAGE_ORDERB2B + "/:id",
      element: <ManageOrderB2B />,
    },
  ],
};
