import { FormEvent, useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import InputText from "../../atoms/InputText/InputText";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import InputDate from "../../atoms/InputDate/InputDate";
import InputNumber from "../../atoms/InputNumber/InputNumber";
import { useGetRulesQuery } from "../../../rtk/promo-codes/rules/rules";
import {
  useCreatePromoCodeBatchMutation,
  useCreatePromoCodeMutation,
} from "../../../rtk/promo-codes/promo-code/promo-code";
import { CreatePromoCode } from "../../../types/rtk/promo-codes/promo-code/promo-code";
import { AppRoutes } from "../../../routes/AppRoutes";
import InputAutocomplete, {
  AutoCompleteDataOptions,
  AutoCompleteDatas,
} from "../../atoms/InputAutocomplete/InputAutocomplete";

interface Props {
  onClickCancel?: () => void;
}

const PromoCodeForm = ({ onClickCancel }: Props) => {
  let { affiliate_id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isGiftCard, setIsGiftCard] = useState(false);
  const [formData, setFormData] = useState<Partial<CreatePromoCode>>({
    commission_amount: 0,
  } as Partial<CreatePromoCode>);
  const {
    data: rules,
    isLoading: isLoadingRules,
    isFetching: isFetchingRules,
  } = useGetRulesQuery("?page=1&size=1000");
  const [createPromoCode] = useCreatePromoCodeMutation();
  const [createPromoCodeBatch] = useCreatePromoCodeBatchMutation();

  const getRulesSortedByIsGiftCard =
    useCallback((): AutoCompleteDataOptions[] => {
      return (
        rules?.items
          .filter((obj) => {
            return obj.is_gift_card_rule === isGiftCard;
          })
          .map((obj) => {
            return {
              label: obj.rule_name ?? "",
              value: obj.id?.toString() ?? "",
            };
          }) ?? ([] as AutoCompleteDataOptions[])
      );
    }, [isGiftCard, rules?.items]);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.isGiftCard !== undefined &&
      affiliate_id &&
      rules
    ) {
      setIsGiftCard(location.state.isGiftCard);
      setFormData((formData) => ({
        ...formData,
        affiliate_id: parseInt(affiliate_id ?? "0"),
        is_gift_card_code: isGiftCard,
      }));
    }
  }, [
    location,
    navigate,
    getRulesSortedByIsGiftCard,
    rules,
    affiliate_id,
    isGiftCard,
  ]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = isGiftCard
        ? await createPromoCodeBatch({
            ...formData,
            is_activated: true,
          }).unwrap()
        : await createPromoCode({ ...formData, is_activated: true }).unwrap();
      setIsLoading(false);
      console.log(response);
      if (onClickCancel) {
        onClickCancel();
      } else {
        navigate(AppRoutes.MANAGE_AFFILIATE + `/${formData.affiliate_id}`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="RuleForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputAutocomplete
                label="Rule associated"
                options={getRulesSortedByIsGiftCard()}
                value={formData.promo_code_rule_id?.toString() ?? ""}
                onChange={(datas: AutoCompleteDatas) => {
                  setFormData({
                    ...formData,
                    promo_code_rule_id: parseInt(
                      (datas as AutoCompleteDataOptions).value
                    ),
                  });
                }}
                isLoading={isLoadingRules || isFetchingRules}
              />
            </Grid>
            {!isGiftCard && (
              <Grid item xs={12}>
                <InputText
                  label="Code Promo"
                  required
                  value={formData.code ?? ""}
                  onChange={(value) => {
                    setFormData({ ...formData, code: value });
                  }}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <InputDate
                label="Valid from"
                value={formData.valid_from ? dayjs(formData.valid_from) : null}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    valid_from: value?.toISOString(),
                  });
                }}
                minDate={dayjs()}
              />
            </Grid>
            <Grid item xs={6}>
              <InputDate
                label="Expires on"
                value={formData.expires_on ? dayjs(formData.expires_on) : null}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    expires_on: value?.toISOString(),
                  });
                }}
                minDate={dayjs(formData.valid_from)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputNumber
                label={isGiftCard ? "Batch size" : "Commision"}
                required
                value={
                  isGiftCard ? formData.batch_size : formData.commission_amount
                }
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    [isGiftCard ? "batch_size" : "commission_amount"]: value,
                  });
                }}
                endAdornment={isGiftCard ? undefined : "€"}
                maxValue={isGiftCard ? 1000 : 10}
                minValue={isGiftCard ? 1 : 0}
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() =>
                    onClickCancel ? onClickCancel() : navigate(-1)
                  }
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default PromoCodeForm;
