import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { components } from "../../../types/rtk/allTypes/allTypes";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import InputSwitch from "../../atoms/InputSwitch/InputSwitch";
import InputNumber from "../../atoms/InputNumber/InputNumber";
import InputText from "../../atoms/InputText/InputText";
import { snakeCaseToTitleCase } from "../../../helpers/snakeCaseToTitleCase";
import {
  useCreateFeatureMutation,
  useUpdateFeatureMutation,
} from "../../../rtk/software/firmware/feature";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

type Feature = components["schemas"]["FeatureListSummaryRead"];

type FormData = {
  kore: Feature;
  once: Feature;
  kore_connectivitypro: Feature;
};

interface Props {
  data: Feature[];
  firmwareId: number;
}

const FeatureDataDisplayAndEdit = ({ data, firmwareId }: Props) => {
  const [isLoading, setIsLoading] = useState({
    kore: false,
    once: false,
    kore_connectivitypro: false,
  });
  const [editMode, setEditMode] = useState({
    kore: false,
    once: false,
    kore_connectivitypro: false,
  });
  const [formData, setFormData] = useState<FormData>({
    kore: {} as Feature,
    once: {} as Feature,
    kore_connectivitypro: {} as Feature,
  });
  const row = Object.keys(data[0]);
  const [createFeature] = useCreateFeatureMutation();
  const [updateFeature] = useUpdateFeatureMutation();

  useEffect(() => {
    setFormData({
      kore: data[0] && data[0],
      once: data[1] && data[1],
      kore_connectivitypro: data[2] && data[2],
    });
  }, [data]);

  const dataDisplay = (element: any) => {
    switch (typeof element) {
      case "boolean":
        return element ? <CheckIcon /> : <CloseIcon />;
      default:
        return element ? element : "null";
    }
  };

  const dataEdit = (value: any, key: string, selector: string) => {
    switch (typeof value) {
      case "boolean":
        return (
          <InputSwitch
            label=""
            value={value}
            onChange={(value) => {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  [selector]: {
                    ...prevState[selector as keyof FormData],
                    [key]: value,
                  },
                };
              });
            }}
          />
        );
      case "number":
        return (
          <InputNumber
            label=""
            value={value}
            onChange={(value) => {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  [selector]: {
                    ...prevState[selector as keyof FormData],
                    [key]: value,
                  },
                };
              });
            }}
          />
        );
      case "string":
        return (
          <InputText
            label=""
            value={value}
            onChange={(value) => {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  [selector]: {
                    ...prevState[selector as keyof FormData],
                    [key]: value,
                  },
                };
              });
            }}
          />
        );
      default:
        return null;
    }
  };

  const handleCreateFeatureKore_ConnectivityPro = async () => {
    setIsLoading({ ...isLoading, kore_connectivitypro: true });
    try {
      await createFeature({
        activity_tracking: false,
        call_directly: false,
        firmware_id: firmwareId,
        has_ble: false,
        has_buttons: false,
        has_flash: false,
        has_flash_stop: false,
        has_wifi: false,
        keep_alive_interval: 0,
        limited_buttons: false,
        mode_gsensor: false,
        mode_selection: false,
        ringing: false,
        ringing_stop: false,
        sim_brand_id: 3,
        sos_button: false,
        super_tracking: false,
        super_tracking_interval: 0,
        super_tracking_stop: false,
        super_tracking_ttl_cmd_hms: false,
        vibrate: false,
        vibrate_stop: false,
      }).unwrap();
      setIsLoading({ ...isLoading, kore_connectivitypro: false });
    } catch (error) {
      console.log(error);
      setIsLoading({ ...isLoading, kore_connectivitypro: false });
    }
  };

  const handleUpdateFeature = async (body: Feature, selector: string) => {
    setIsLoading({ ...isLoading, [selector]: true });
    try {
      await updateFeature({ body, id: body.id ?? 0 }).unwrap();
      setIsLoading({ ...isLoading, [selector]: false });
      setEditMode({ ...editMode, [selector]: false });
    } catch (error) {
      console.log(error);
      setIsLoading({ ...isLoading, [selector]: false });
      setEditMode({ ...editMode, [selector]: false });
    }
  };

  return (
    <Box className="FeatureDataDisplayAndEdit">
      <Grid container item spacing={3} className="featureGrid">
        <Grid container item spacing={3} className="featureDataHeader">
          <Grid item xs={3} className="data">
            {`Feature :`}
          </Grid>
          <Grid item xs={3} className="data">
            {`KORE`}
          </Grid>
          <Grid item xs={3} className="data">
            {`1NCE`}
          </Grid>
          <Grid item xs={3} className="data">
            {`KORE-CONNECTIVITYPRO`}
          </Grid>
        </Grid>

        {row.sort().map((obj, i) => {
          if (
            obj === "sim_brand_id" ||
            obj === "sim_brand_name" ||
            obj === "firmware_id" ||
            obj === "id"
          )
            return null;
          return (
            <Grid container item spacing={3} className="featureData" key={i}>
              <Grid item xs={3} className="rowNameData">
                {snakeCaseToTitleCase(obj)}
              </Grid>
              {formData.kore && (
                <Grid item xs={3} className="data">
                  {!editMode.kore
                    ? dataDisplay(formData.kore[obj as keyof Feature])
                    : dataEdit(
                        formData.kore[obj as keyof Feature],
                        obj,
                        "kore"
                      )}
                </Grid>
              )}
              {formData.once && (
                <Grid item xs={3} className="data">
                  {!editMode.once
                    ? dataDisplay(formData.once[obj as keyof Feature])
                    : dataEdit(
                        formData.once[obj as keyof Feature],
                        obj,
                        "once"
                      )}
                </Grid>
              )}
              {formData.kore_connectivitypro && (
                <Grid item xs={3} className="data">
                  {!editMode.kore_connectivitypro
                    ? dataDisplay(
                        formData.kore_connectivitypro[obj as keyof Feature]
                      )
                    : dataEdit(
                        formData.kore_connectivitypro[obj as keyof Feature],
                        obj,
                        "kore_connectivitypro"
                      )}
                </Grid>
              )}
            </Grid>
          );
        })}

        <Grid container item spacing={3} className="featureButtons">
          <Grid item xs={3} />
          <Grid item xs={3} className="button">
            {!isLoading.kore ? (
              <StyledButton
                children={!editMode.kore ? "EDIT KORE" : "SAVE"}
                onClick={() =>
                  !editMode.kore
                    ? setEditMode({ ...editMode, kore: true })
                    : handleUpdateFeature(formData.kore, "kore")
                }
              />
            ) : (
              <SpinnerLoader />
            )}
          </Grid>
          <Grid item xs={3} className="button">
            {!isLoading.once ? (
              <StyledButton
                children={!editMode.once ? "EDIT 1NCE" : "SAVE"}
                onClick={() =>
                  !editMode.once
                    ? setEditMode({ ...editMode, once: true })
                    : handleUpdateFeature(formData.once, "once")
                }
              />
            ) : (
              <SpinnerLoader />
            )}
          </Grid>
          <Grid item xs={3} className="button">
            {!isLoading.kore_connectivitypro ? (
              <StyledButton
                children={
                  !editMode.kore_connectivitypro
                    ? formData.kore_connectivitypro
                      ? "EDIT KORE-CONNECTIVITYPRO"
                      : "CREATE KORE-CONNECTIVITYPRO"
                    : "SAVE"
                }
                onClick={() =>
                  !editMode.kore_connectivitypro
                    ? formData.kore_connectivitypro
                      ? setEditMode({
                          ...editMode,
                          kore_connectivitypro: true,
                        })
                      : handleCreateFeatureKore_ConnectivityPro()
                    : handleUpdateFeature(
                        formData.kore_connectivitypro,
                        "kore_connectivitypro"
                      )
                }
              />
            ) : (
              <SpinnerLoader />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeatureDataDisplayAndEdit;
