import { useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../../atoms/StyledPaper/StyledPaper";
import Title from "../../../atoms/Title/Title";
import Text from "../../../atoms/Text/Text";
import DataDisplay, {
  DataToDisplay,
} from "../../../molecules/DataDisplay/DataDisplay";
import SpinnerLoader from "../../../atoms/SpinnerLoader/SpinnerLoader";
import {
  useDeleteHardwareMutation,
  useGetHardwareByIdQuery,
} from "../../../../rtk/software/hardware/hardware";
import { formatDate } from "../../../../helpers/formatDate";
// import TrackerConfigTable from "../../../organisms/TrackerConfigTable/TrackerConfigTable";
import Firmware from "../Firmware/Firmware";
import StyledButton from "../../../atoms/StyledButton/StyledButton";
import { AppRoutes } from "../../../../routes/AppRoutes";
import StyledChip from "../../../atoms/StyledChip/StyledChip";

const ManageHardware = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { data: hardware, isLoading: isLoadingHardware } =
    useGetHardwareByIdQuery(id ?? "", {
      skip: !id,
    });
  const [deleteHardware] = useDeleteHardwareMutation();

  const hardwareDataToDisplay: DataToDisplay[] = [
    {
      title: "Id",
      selector: "id",
    },
    {
      title: "Name",
      selector: "name",
    },
    {
      title: "Created at",
      selector: "created_at",
      callBack: (data) => formatDate(data),
    },
    {
      title: "Updated at",
      selector: "updated_at",
      callBack: (data) => formatDate(data),
    },
    {
      title: "Release note",
      selector: "release_note",
    },
  ];

  const handleDeleteHardware = async () => {
    setIsLoading(true);
    try {
      await deleteHardware(hardware?.id?.toString() ?? "");
      navigate(AppRoutes.HARDWARES);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <PageTemplate title="Manage Hardware" cross>
      <Box className="ManageHardware">
        {hardware && !isLoadingHardware ? (
          <>
            <StyledPaper>
              <Box className="dataHeader">
                <Title variant="h3" children={`Hardware: ${hardware?.name}`} />
                <Box className="chipAlignement">
                  <Text children={`Compatibility Label: `} />
                  {hardware?.firmware_compatibility_labels?.map((label) => (
                    <StyledChip label={`${label.label}`} randomColor />
                  ))}
                </Box>
              </Box>

              <Box className="data">
                <DataDisplay
                  datas={hardware}
                  datasInformations={hardwareDataToDisplay}
                  orientation={"horizontal"}
                />
              </Box>

              <Box className="buttons">
                {!isLoading ? (
                  <>
                    <StyledButton
                      children="Delete"
                      className="cancel"
                      onClick={handleDeleteHardware}
                    />
                  </>
                ) : (
                  <SpinnerLoader />
                )}
              </Box>
            </StyledPaper>

            {/* {hardware.tracker_configs &&
              hardware.tracker_configs?.length > 0 && (
                <StyledPaper>
                  <Box className="dataHeader">
                    <Title variant="h3" children={`Tracker config`} />
                  </Box>

                  <Box className="data">
                    <TrackerConfigTable
                      trackersConfig={hardware.tracker_configs}
                    />
                  </Box>
                </StyledPaper>
              )} */}

            {hardware.firmware_compatibility_labels &&
              hardware.firmware_compatibility_labels.length > 0 && (
                <StyledPaper>
                  <Box className="dataHeader">
                    <Title variant="h3" children={`Firmwares List`} />
                  </Box>

                  <Box className="data">
                    <Firmware
                      template={false}
                      staticQuery={`&f_hardware_id=${hardware.id}`}
                    />
                  </Box>
                </StyledPaper>
              )}
          </>
        ) : (
          <SpinnerLoader />
        )}
      </Box>
    </PageTemplate>
  );
};

export default ManageHardware;
