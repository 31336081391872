import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import NavBar from "./components/templates/NavBar/NavBar";
import Header from "./components/templates/Header/Header";
import { RootState } from "./types/rtk/api-config/types";
import { useAppDispatch, useAppSelector } from "./rtk/_config/hooks";
import { setTypes, useGetTypesQuery } from "./rtk/allTypes/allTypes";
import { AppRoutes } from "./routes/AppRoutes";
import { setUser, useGetMyUserQuery } from "./rtk/user/user";
import ErrorModal from "./components/molecules/ErrorModal/ErrorModal";
import { setError } from "./rtk/error/error";

const App = () => {
  const theme = useAppSelector((state: RootState) => state.theme.mode);
  const token = useAppSelector((state: RootState) => state.auth.token);
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const error = useAppSelector((state: RootState) => state.error);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { data } = useGetTypesQuery(undefined, { skip: types !== undefined });
  const { data: myUser } = useGetMyUserQuery();
  const [isNavBarOpen, setIsNavBarOpen] = useState(true);

  useMemo(() => {
    document.body.className = `theme--${theme}`;
  }, [theme]);

  useEffect(() => {
    if (data !== undefined) {
      dispatch(setTypes({ types: data }));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (myUser !== undefined) {
      dispatch(setUser(myUser));
    }
  }, [myUser, dispatch]);

  useEffect(() => {
    if (token === undefined) {
      navigate(AppRoutes.LOGIN, {
        state: {
          path: `${location.pathname}${location.search}`,
        },
      });
    }
  }, [token, navigate, location]);

  const handleCloseErrorModal = () => {
    dispatch(setError({ code: undefined, message: undefined }));
  };

  return (
    <Box className="App">
      <NavBar isNavBarOpen={isNavBarOpen} />
      <Box className={`appContent ${isNavBarOpen ? "navBarOpen" : ""}`}>
        <Header isNavBarOpen={isNavBarOpen} setIsNavBarOpen={setIsNavBarOpen} />
        <Outlet />
      </Box>
      <ErrorModal
        isOpen={error.code !== undefined && error.message !== undefined}
        handleClose={handleCloseErrorModal}
        code={error.code}
        message={error.message}
      />
    </Box>
  );
};

export default App;
