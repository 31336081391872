import { Box, Modal } from "@mui/material";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import StyledButton from "../../atoms/StyledButton/StyledButton";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  code?: string;
  message?: string;
}

const ErrorModal = ({ isOpen, handleClose, code, message }: Props) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <>
        <StyledPaper className="ConfirmationModal">
          <Title variant="h1">ERROR</Title>
          <Title variant="h3">{message && message}</Title>
          <Text className="error">{`Code: ${code && code}`}</Text>
          <Box className="buttonsBox">
            <StyledButton className="cancel" onClick={handleClose}>
              Close
            </StyledButton>
          </Box>
        </StyledPaper>
      </>
    </Modal>
  );
};

export default ErrorModal;
