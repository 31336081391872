import { useState } from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import { useGetRetailersQuery } from "../../../../rtk/retailers/retailers/retailers";
import GenericTable from "../../../molecules/GenericTable/GenericTable";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useAppSelector } from "../../../../rtk/_config/hooks";
import { RootState } from "../../../../types/rtk/api-config/types";
import { hasQueryFilters } from "../../../../helpers/hasQueryFilter";
import ConditionalWrapper from "../../../atoms/ConditionalWrapper/ConditionalWrapper";

interface Props {
  template?: boolean;
}

const Retailers = ({ template = true }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const [query, setQuery] = useState("");
  const colDef =
    types?.components.schemas.RetailerListSummaryRead.properties ?? {};
  const {
    data: retailers,
    isLoading,
    isFetching,
  } = useGetRetailersQuery(query, {
    skip: query === "",
  });

  const colToOverride: GridColDef[] = [
    {
      field: "name",
      renderCell: (params) => (
        <Box
          onClick={() =>
            navigate(`${AppRoutes.MANAGE_RETAILER}/${params.id.toString()}`)
          }
          className="clickableBox"
        >
          {params.value}
        </Box>
      ),
    },
    { field: "id", sortable: false, filterable: false },
    {
      field: "group.name",
      headerName: "Group",
      renderCell: (params) => {
        return params.row.group !== null ? (
          <Box
            onClick={() =>
              navigate(
                `${AppRoutes.MANAGE_GROUP}/${params.row.group.id.toString()}`
              )
            }
            className="clickableBox"
          >
            {params.row.group.name}
          </Box>
        ) : null;
      },
    },
    {
      field: "group.type.name",
      headerName: "Type",
      renderCell: (params) => {
        return params.row.group !== null ? params.row.group.type.name : null;
      },
    },
    { field: "count_tracker_stocks" },
    { field: "count_trackers" },
  ];

  const clearFilter = () => {
    setQuery("");
  };

  return (
    <ConditionalWrapper
      condition={template}
      wrapper={(children) => (
        <PageTemplate
          title="Retailers"
          button="New retailer"
          link={AppRoutes.CREATE_RETAILER}
          clearFilterButton={
            hasQueryFilters(query) ? "Remove filters" : undefined
          }
          onClickClearFilterButton={clearFilter}
        >
          {children}
        </PageTemplate>
      )}
    >
      <Box className="Retailers">
        {types && (
          <GenericTable
            rawData={retailers}
            isLoading={isLoading}
            isFetching={isFetching}
            colDef={colDef}
            colToOverride={colToOverride}
            setQuery={setQuery}
            query={query}
          />
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default Retailers;
