import { Box, Grid } from "@mui/material";

import Text from "../../atoms/Text/Text";
import { getValue } from "../../../helpers/getValueWithBracketNotation";
import { ArrowBack } from "@mui/icons-material";

export interface DataToDisplay {
  title: string;
  selector: string;
  callBack?: (data: any) => any;
}

interface Props {
  datasInformations: DataToDisplay[];
  datas: any;
  orientation?: "horizontal" | "vertical";
  arrow?: boolean;
}

const DataDisplay = ({
  datasInformations,
  datas,
  orientation = "horizontal",
  arrow,
}: Props) => {
  return (
    <Grid className="DataDisplay" container spacing={4}>
      {datasInformations.map((obj, i) => (
        <Grid key={i} item xs={orientation === "horizontal" ? 6 : 12}>
          <Box className="item">
            {arrow && <ArrowBack />}
            <Box>
              <Text children={obj.title} color="secondary" />
              {datas &&
                `${
                  obj.callBack
                    ? obj.callBack(getValue(obj.selector, datas))
                    : getValue(obj.selector, datas) ?? ""
                }`}
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default DataDisplay;
