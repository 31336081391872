import {
  createApi,
  fetchBaseQuery,
  BaseQueryApi,
} from "@reduxjs/toolkit/query/react";

import { config as appConfig } from "../../config";
import { RootState } from "../../types/rtk/api-config/types";
import { setAuth } from "../auth/auth";
import { setError } from "../error/error";
import { setLoader } from "../loader/loader";

const query = fetchBaseQuery({
  baseUrl: appConfig.REACT_APP_APIV4_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQuery = async (args: any, api: BaseQueryApi, extraOptions: {}) => {
  let result = await query(args, api, extraOptions);
  if (
    result.error &&
    result.error.status === 401 &&
    (result.error.data as any).detail.error_code === "INVALID_CREDENTIALS"
  ) {
    api.dispatch(setAuth({ token: undefined }));
    // const refreshResult = await query(
    //   {
    //     url: `auth/login/mail`,
    //     method: "POST",
    //     body: {
    //       mail_token: (api.getState() as RootState).auth.mail_token,
    //     },
    //   },
    //   api,
    //   extraOptions
    // );
    // if (refreshResult.data) {
    //   // @ts-ignore
    //   const token: string = refreshResult.data.access_token;
    //   // store the new token
    //   api.dispatch(
    //     setAuth({
    //       token: token,
    //       mail_token: (api.getState() as RootState).auth.mail_token,
    //     })
    //   );
    //   // retry the initial query
    //   result = await query(args, api, extraOptions);
    // } else {
    //   api.dispatch(
    //     setAuth({
    //       token: undefined,
    //       mail_token: (api.getState() as RootState).auth.mail_token,
    //     })
    //   );
    //   console.log("error");
    // window.location.href = "/error";
    // }
  } else if (
    result.error &&
    (result.error.data as any).detail.error_code &&
    (result.error.data as any).detail.error_message
  ) {
    api.dispatch(
      setError({
        code: (result.error.data as any).detail.error_code,
        message: (result.error.data as any).detail.error_message,
      })
    );
    // console.log("error");
    // window.location.href = "/error";
  }
  api.dispatch(setLoader({ isLoading: false }));
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [
    "Retailers",
    "Retailer",
    "Groups",
    "Group",
    "Groups_type",
    "Group_type",
    "Promo_code_rules",
    "Promo_code_rule",
    "Subscription",
    "Affiliate",
    "Affiliates",
    "Promo_codes",
    "Promo_code",
    "Promo_code_campaign",
    "OTACampains",
    "OTACampain",
    "Labels",
    "Firmwares",
    "Firmware",
    "FirmwaresCompatibilityLabels",
    "FirmwaresCompatibilityLabel",
    "Features",
    "Hardwares",
    "Hardware",
    "Trackers",
    "Tracker",
    "TrackerConfigs",
    "TrackerConfig",
    "OperatorNetworks",
    "OperatorNetwork",
    "OrderB2Bs",
    "OrderB2B",
    "Users",
    "User",
  ],
});
