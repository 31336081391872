import { MyUser } from "../../types/rtk/user/user";
import { apiSlice } from "../_api/api-slice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: MyUser = {
  username: "",
  role_label: "",
  has_provisional_password: false,
  actions_labels: [],
};

const User = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<MyUser>) => {
      state.username = action.payload.username;
      state.role_label = action.payload.role_label;
      state.has_provisional_password = action.payload.has_provisional_password;
      state.actions_labels = action.payload.actions_labels;
    },
  },
});

export const { setUser } = User.actions;
const userReducer = User.reducer;

export const tracker = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMyUser: build.query<MyUser, void>({
      query: () => ({
        url: `myuser`,
        method: "get",
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetMyUserQuery } = tracker;

export default userReducer;
