import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { setTheme } from "../../../rtk/theme/theme";
import Moon from "../../../assets/images/switchDarkModeMoon.svg";
import Sun from "../../../assets/images/switchDarkModeSun.svg";
import { Switch } from "@mui/material";

const SwitchDarkMode = () => {
  const theme = useAppSelector((state: RootState) => state.theme.mode);
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(theme === "light" ? false : true);

  useEffect(() => {
    if (theme) {
      dispatch(setTheme({ mode: checked === true ? "dark" : "light" }));
    }
  }, [checked, dispatch, theme]);

  const handleSwitchInput = () => {
    setChecked(!checked);
  };

  return (
    <Switch
      className="SwitchDarkMode"
      checked={checked}
      onClick={handleSwitchInput}
      icon={<ReactSVG className="icon" src={Sun} />}
      checkedIcon={<ReactSVG className="icon" src={Moon} />}
    />
  );
};

export default SwitchDarkMode;
