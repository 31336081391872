import { useState } from "react";
import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import GenericTable from "../../../molecules/GenericTable/GenericTable";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useAppSelector } from "../../../../rtk/_config/hooks";
import { RootState } from "../../../../types/rtk/api-config/types";
import { formatDate } from "../../../../helpers/formatDate";
import StyledChip from "../../../atoms/StyledChip/StyledChip";
import { useGetFirmwaresQuery } from "../../../../rtk/software/firmware/firmware";
import { hasQueryFilters } from "../../../../helpers/hasQueryFilter";
import ConditionalWrapper from "../../../atoms/ConditionalWrapper/ConditionalWrapper";

interface Props {
  template?: boolean;
  staticQuery?: string;
}

const Firmware = ({ template = true, staticQuery }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const [query, setQuery] = useState("");
  const colDef =
    types?.components.schemas.FirmwareListSummaryRead.properties ?? {};
  const {
    data: firmware,
    isLoading,
    isFetching,
  } = useGetFirmwaresQuery(query + (staticQuery ? staticQuery : ""), {
    skip: query === "",
  });

  const colToOverride: GridColDef[] = [
    {
      field: "name",
      headerName: "NAME",
      renderCell: (params) => (
        <Box
          onClick={() =>
            navigate(`${AppRoutes.MANAGE_FIRMWARE}/${params.id.toString()}`)
          }
          className="clickableBox"
        >
          {params.value}
        </Box>
      ),
    },
    { field: "id", headerName: "ID" },
    {
      field: "compatibility_label.label",
      headerName: "COMPATIBILITY",
      renderCell: (params: GridRenderCellParams) => (
        <StyledChip label={params.row.compatibility_label.label} randomColor />
      ),
    },
    {
      field: "feature_configured",
      headerName: "FEATURE CONFIGURED",
    },
    { field: "count_trackers", headerName: "COUNT TRACKER" },
    {
      field: "created_at",
      headerName: "CREATED AT",
      valueGetter: (created_at: string) => formatDate(created_at ?? ""),
    },
  ];

  const clearFilter = () => {
    setQuery("");
  };

  return (
    <ConditionalWrapper
      condition={template}
      wrapper={(children) => (
        <PageTemplate
          title="Firmware"
          button="New firmware"
          link={AppRoutes.CREATE_FIRMWARE}
          clearFilterButton={
            hasQueryFilters(query) ? "Remove filters" : undefined
          }
          onClickClearFilterButton={clearFilter}
        >
          {children}
        </PageTemplate>
      )}
    >
      <Box className={`Firmware ${!template && "fullWidth"}`}>
        {types && (
          <GenericTable
            rawData={firmware}
            isLoading={isLoading}
            isFetching={isFetching}
            colDef={colDef}
            colToOverride={colToOverride}
            setQuery={setQuery}
            query={query}
          />
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default Firmware;
