import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import GenericTable from "../../../molecules/GenericTable/GenericTable";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useAppSelector } from "../../../../rtk/_config/hooks";
import { RootState } from "../../../../types/rtk/api-config/types";
import { useGetHardwaresQuery } from "../../../../rtk/software/hardware/hardware";
import { formatDate } from "../../../../helpers/formatDate";
import StyledChip from "../../../atoms/StyledChip/StyledChip";
import { hasQueryFilters } from "../../../../helpers/hasQueryFilter";
import ConditionalWrapper from "../../../atoms/ConditionalWrapper/ConditionalWrapper";

interface Props {
  template?: boolean;
}

const Hardware = ({ template = true }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const [query, setQuery] = useState("");
  const colDef = useMemo(
    () => types?.components.schemas.HardwareListSummaryRead.properties ?? {},
    [types?.components.schemas.HardwareListSummaryRead.properties]
  );
  const {
    data: hardware,
    isLoading,
    isFetching,
  } = useGetHardwaresQuery(query, {
    skip: query === "",
  });

  const colToOverride: GridColDef[] = [
    {
      field: "name",
      renderCell: (params) => (
        <Box
          onClick={() =>
            navigate(`${AppRoutes.MANAGE_HARDWARE}/${params.id.toString()}`)
          }
          className="clickableBox"
        >
          {params.value}
        </Box>
      ),
    },
    { field: "id" },
    {
      field: "labels",
      headerName: "Label",
      renderCell: (params: GridRenderCellParams) => {
        return (params.row.labels as string[]).length > 0 ? (
          <StyledChip label={params.row.labels[0]} randomColor />
        ) : null;
      },
    },
    {
      field: "count_tracker_configs",
      headerName: "Configuration",
      renderCell: (params: GridRenderCellParams) => {
        return shouldDisplayConfigIcon(params.row.labels) ? (
          params.row.count_tracker_configs > 1 ? (
            <CheckIcon color={"success"} />
          ) : (
            <CloseIcon color={"warning"} />
          )
        ) : null;
      },
    },
    { field: "count_trackers" },
    {
      field: "created_at",
      valueGetter: (created_at: string) => formatDate(created_at ?? ""),
    },
    {
      field: "updated_at",
      valueGetter: (created_at: string) => formatDate(created_at ?? ""),
    },
  ];

  const clearFilter = () => {
    setQuery("");
  };

  const shouldDisplayConfigIcon = (labels: string[]): boolean => {
    if (!labels || labels.length === 0) {
      return false;
    }

    return (
      labels.find(
        (lab: string) => lab.includes("008N") || lab.includes("006N")
      ) !== undefined
    );
  };

  return (
    <ConditionalWrapper
      condition={template}
      wrapper={(children) => (
        <PageTemplate
          title="Hardware"
          button="New hardware"
          link={AppRoutes.CREATE_HARDWARE}
          clearFilterButton={
            hasQueryFilters(query) ? "Remove filters" : undefined
          }
          onClickClearFilterButton={clearFilter}
        >
          {children}
        </PageTemplate>
      )}
    >
      <Box className="Hardware">
        {types && (
          <GenericTable
            rawData={hardware}
            isLoading={isLoading}
            isFetching={isFetching}
            colDef={colDef}
            colToOverride={colToOverride}
            setQuery={setQuery}
            query={query}
          />
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default Hardware;
