import {
  Groups,
  CreateGroup,
  UpdateGroup,
  GroupsTypes,
  CreateGroupType,
  UpdateGroupType,
  Group,
  CreateGroupResponse,
  UpdateGroupResponse,
  CreateGroupTypeResponse,
  UpdateGroupTypeResponse,
} from "./../../../types/rtk/retailers/group/group";
import { apiSlice } from "../../_api/api-slice";

export const groups = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getGroups: build.query<Groups, string>({
      query: (url) => ({
        url: `retailer-group${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Groups" }],
    }),
    getGroupById: build.query<Group, string>({
      query: (id) => ({
        url: `retailer-group/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "Group", id: `group_${id}` },
      ],
    }),
    createGroup: build.mutation<CreateGroupResponse, CreateGroup>({
      query: (body) => ({
        url: `retailer-group`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Groups" }],
    }),
    updateGroup: build.mutation<
      UpdateGroupResponse,
      { body: UpdateGroup; id: string }
    >({
      query: ({ body, id }) => ({
        url: `retailer-group/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Group", id: `group_${id}` },
        { type: "Groups" },
      ],
    }),
    deleteGroup: build.mutation<null, string>({
      query: (id) => ({
        url: `retailer-group/${id}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Groups" }],
    }),
    getGroupsTypes: build.query<GroupsTypes, string>({
      query: (url) => ({
        url: `retailer-group-type${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Groups_type" }],
    }),
    createGroupType: build.mutation<CreateGroupTypeResponse, CreateGroupType>({
      query: (body) => ({
        url: `retailer-group-type`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Groups_type" }],
    }),
    updateGroupType: build.mutation<
      UpdateGroupTypeResponse,
      { body: UpdateGroupType; id: string }
    >({
      query: ({ body, id }) => ({
        url: `retailer-group-type/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Group_type", id: `group_type_${id}` },
        { type: "Groups_type" },
      ],
    }),
    deleteGroupType: build.mutation<null, string>({
      query: (id) => ({
        url: `retailer-group-type/${id}`,
        method: "delete",
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetGroupsQuery,
  useGetGroupByIdQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useGetGroupsTypesQuery,
  useCreateGroupTypeMutation,
  useUpdateGroupTypeMutation,
  useDeleteGroupTypeMutation,
} = groups;
