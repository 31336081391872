import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../../atoms/StyledPaper/StyledPaper";
import Title from "../../../atoms/Title/Title";
import Text from "../../../atoms/Text/Text";
import DataDisplay, {
  DataToDisplay,
} from "../../../molecules/DataDisplay/DataDisplay";
import StyledButton from "../../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../../atoms/SpinnerLoader/SpinnerLoader";
import {
  useGetOTACampainByIdQuery,
  useUpdateOTACampainMutation,
} from "../../../../rtk/software/ota-campains/ota-campains";
import { formatDate } from "../../../../helpers/formatDate";
import OTArates from "../../../molecules/OTArates/OTArates";
import FirmwareFromDisplay from "../../../molecules/FirmwareFromDisplay/FirmwareFromDisplay";
import InputNumber from "../../../atoms/InputNumber/InputNumber";
import { UpdateOTACampain } from "../../../../types/rtk/software/ota-campain/ota-campain";
import StyledChip from "../../../atoms/StyledChip/StyledChip";

const ManageOTACampain = () => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<UpdateOTACampain>({});
  const { data: ota, isLoading: isLoadingOta } = useGetOTACampainByIdQuery(
    id ?? "",
    {
      skip: !id,
    }
  );
  const [updateOta] = useUpdateOTACampainMutation();

  useEffect(() => {
    if (ota) {
      setFormData({
        is_closed: ota.is_closed,
        target_percent: ota.target_percent,
      });
    }
  }, [ota]);

  const otaDataToDisplay: DataToDisplay[] = [
    {
      title: "Created at",
      selector: "created_at",
      callBack: (data) => formatDate(data),
    },
    {
      title: "Updated at",
      selector: "updated_at",
      callBack: (data) => formatDate(data),
    },
    {
      title: "Firmware to",
      selector: "firmware_to_name",
    },
    {
      title: "OTA campaign id",
      selector: "id",
    },
  ];

  const handleUpdateOta = async (body: UpdateOTACampain) => {
    setIsLoading(true);
    try {
      await updateOta({ body, id: ota?.id.toString() ?? "" });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate title="Manage OTA" cross>
      <Box className="ManageOTACampain">
        {!isLoadingOta ? (
          <>
            <StyledPaper>
              <>
                <Box className="dataHeader">
                  <Box className="chipAlignement">
                    <Title variant="h3" children={`OTA: ${ota?.name}`} />
                    <StyledChip
                      label={`${ota?.firmware_to_label}`}
                      randomColor
                    />
                  </Box>
                  <Text
                    children={`Firmware to: ${ota?.firmware_to_name ?? ""}`}
                  />
                </Box>

                <Box className="data">
                  <DataDisplay
                    datas={ota}
                    datasInformations={otaDataToDisplay}
                    orientation={"horizontal"}
                  />
                </Box>
              </>
            </StyledPaper>

            <StyledPaper>
              <>
                <Box className="dataHeader">
                  <Title variant="h3" children={`Rates`} />
                </Box>

                <Box className="data">
                  <OTArates
                    total={ota?.total ?? 0}
                    current={ota?.tracker_selected ?? 0}
                    text="Deploy rate"
                  />
                  <OTArates
                    total={ota?.total ?? 0}
                    current={ota?.trackers_already_updated ?? 0}
                    text="Migration rate"
                  />
                </Box>
              </>
            </StyledPaper>

            {!ota?.is_closed && (
              <StyledPaper>
                <>
                  <Box className="dataHeader">
                    <Title variant="h3" children={`Update deployment rate`} />
                  </Box>

                  <Box className="data">
                    {ota?.target_percent !== 100 ? (
                      <>
                        <InputNumber
                          label="Deploy rate"
                          value={formData.target_percent}
                          onChange={(value) => {
                            setFormData({
                              ...formData,
                              target_percent: value,
                            });
                          }}
                          maxValue={100}
                          minValue={ota?.target_percent}
                        />
                        {!isLoading && (
                          <StyledButton
                            children="UPDATE"
                            onClick={() => handleUpdateOta(formData)}
                          />
                        )}
                      </>
                    ) : (
                      <Text>
                        The OTA deploy rate is 100% and therefore its percentage
                        can no longer be modified
                      </Text>
                    )}
                  </Box>
                </>
              </StyledPaper>
            )}

            <StyledPaper>
              <>
                <Box className="dataHeader">
                  <Title variant="h3" children={`Firmware from`} />
                </Box>

                <Box className="data">
                  <FirmwareFromDisplay ota={ota} />
                </Box>
              </>
            </StyledPaper>

            {!ota?.is_closed && (
              <StyledPaper>
                <>
                  <Box className="dataHeader">
                    <Title variant="h3" children={`Data management`} />
                  </Box>

                  {!isLoading && (
                    <Box className="data">
                      <StyledButton
                        children="Stop OTA"
                        onClick={() => {
                          handleUpdateOta({ ...formData, is_closed: true });
                        }}
                        confirmation
                        titleConfirmation="Pause ota-campain"
                        textConfirmation="are you sure ? this is irreversible, you will not be able to restart it again"
                        textButtonConfirmation="Stop !"
                      />
                    </Box>
                  )}
                </>
              </StyledPaper>
            )}
          </>
        ) : (
          <SpinnerLoader />
        )}
      </Box>
    </PageTemplate>
  );
};

export default ManageOTACampain;
