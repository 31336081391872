import { useState } from "react";
import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import GenericTable from "../../../molecules/GenericTable/GenericTable";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useAppSelector } from "../../../../rtk/_config/hooks";
import { RootState } from "../../../../types/rtk/api-config/types";
import { useGetAffiliatesQuery } from "../../../../rtk/promo-codes/affiliate/affiliate";
import { formatDate } from "../../../../helpers/formatDate";
import { hasQueryFilters } from "../../../../helpers/hasQueryFilter";
import ConditionalWrapper from "../../../atoms/ConditionalWrapper/ConditionalWrapper";

interface Props {
  template?: boolean;
}

const Affiliates = ({ template = true }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const [query, setQuery] = useState("");
  const colDef =
    types?.components.schemas.AffiliateListSummaryRead.properties ?? {};
  const {
    data: rules,
    isLoading,
    isFetching,
  } = useGetAffiliatesQuery(query, {
    skip: query === "",
  });

  const colToOverride: GridColDef[] = [
    {
      field: "first_name",
      headerName: "Name",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box
            onClick={() =>
              navigate(`${AppRoutes.MANAGE_AFFILIATE}/${params.id.toString()}`)
            }
            className="clickableBox"
          >
            {`${params.row.first_name} ${params.row.last_name}`}
          </Box>
        );
      },
    },
    {
      field: "mail",
      headerName: "Email",
    },
    {
      field: "created_at",
      valueGetter: (created_at: string) => formatDate(created_at ?? ""),
    },
  ];

  const clearFilter = () => {
    setQuery("");
  };

  return (
    <ConditionalWrapper
      condition={template}
      wrapper={(children) => (
        <PageTemplate
          title="Affiliates"
          button="New affiliate"
          link={AppRoutes.CREATE_AFFILIATE}
          clearFilterButton={
            hasQueryFilters(query) ? "Remove filters" : undefined
          }
          onClickClearFilterButton={clearFilter}
        >
          {children}
        </PageTemplate>
      )}
    >
      <Box className="Affiliates">
        {types && (
          <GenericTable
            rawData={rules}
            isLoading={isLoading}
            isFetching={isFetching}
            colDef={colDef}
            colToOverride={colToOverride}
            setQuery={setQuery}
            query={query}
          />
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default Affiliates;
