import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import PromoCodeForm from "../../../organisms/PromoCodeForm/PromoCodeForm";
import PageTemplate from "../../../templates/PageTemplate/PageTemplate";

const CreatePromoCode = () => {
  const [isGiftCard, setIsGiftCard] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (location && location.state && location.state.isGiftCard !== undefined) {
      setIsGiftCard(location.state.isGiftCard);
    }
  }, [location]);

  return (
    <PageTemplate
      title={`New ${isGiftCard ? "Gift card" : "Promo code"}`}
      cross
    >
      <PromoCodeForm />
    </PageTemplate>
  );
};

export default CreatePromoCode;
