import OrderB2BForm from "../../organisms/OrderB2BForm/OrderB2BForm";
import PageTemplate from "../../templates/PageTemplate/PageTemplate";

const CreateOrderB2B = () => {
  return (
    <PageTemplate title="New order B2B" cross>
      <OrderB2BForm />
    </PageTemplate>
  );
};

export default CreateOrderB2B;
