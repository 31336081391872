import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import {
  CreateHardware,
  Hardware,
} from "../../../types/rtk/software//hardware/hardware";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { useCreateHardwareMutation } from "../../../rtk/software/hardware/hardware";
import InputAutocomplete, {
  AutoCompleteDatas,
  AutoCompleteDataOptions,
} from "../../atoms/InputAutocomplete/InputAutocomplete";
import { AppRoutes } from "../../../routes/AppRoutes";
import InputText from "../../atoms/InputText/InputText";
import { useGetFirmwaresCompatibilityLabelQuery } from "../../../rtk/software/firmware/firmware-compatibility-label";

interface Props {
  hardware?: Hardware;
  onClickCancel?: () => void;
}

const HardwareForm = ({ hardware, onClickCancel }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [labelsData, setLabelsData] = useState<AutoCompleteDataOptions[]>([]);
  const [formData, setFormData] = useState<CreateHardware>({
    name: "",
    firmware_compatibility_labels: [],
    release_note: "",
  } as CreateHardware);
  const {
    data: labels,
    isLoading: isLoadingLabels,
    isFetching: isFetchingLabels,
  } = useGetFirmwaresCompatibilityLabelQuery("?page=1&size=100");

  const [createHardware] = useCreateHardwareMutation();

  useEffect(() => {
    if (hardware) {
      setFormData((formData) => ({ ...formData, name: hardware.name ?? "" }));
    }
  }, [hardware]);

  useEffect(() => {
    if (labels) {
      setLabelsData(
        labels.items?.map((obj) => ({
          label: obj.label ?? "",
          value: obj.id?.toString() ?? "",
        }))
      );
    }
  }, [labels]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await createHardware(formData).unwrap();
      setIsLoading(false);
      if (onClickCancel) {
        onClickCancel();
      } else {
        navigate(AppRoutes.MANAGE_HARDWARE + `/${response.id}`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="HardwareForm">
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputText
                label="Name"
                required
                placeholder="S-L2K.13.150709C"
                value={formData.name ?? ""}
                onChange={(value) => setFormData({ ...formData, name: value })}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutocomplete
                label="Compatibility"
                value={formData.firmware_compatibility_labels?.toString() ?? ""}
                options={labelsData}
                onChange={(datas: AutoCompleteDatas) =>
                  setFormData({
                    ...formData,
                    firmware_compatibility_labels: (
                      datas as AutoCompleteDataOptions[]
                    ).map((obj) => parseInt(obj.value)),
                  })
                }
                multiple
                isLoading={isLoadingLabels || isFetchingLabels}
              />
            </Grid>
            <Grid item xs={12}>
              <InputText
                label="Release note"
                placeholder="What is the purpose of this hardware ?"
                required
                value={formData.release_note ?? ""}
                onChange={(value) =>
                  setFormData({ ...formData, release_note: value })
                }
                multiline
              />
            </Grid>
          </Grid>
          <Box className="buttons">
            {!isLoading ? (
              <>
                <StyledButton
                  children="Cancel"
                  className="cancel"
                  onClick={() =>
                    onClickCancel ? onClickCancel() : navigate(-1)
                  }
                />
                <StyledButton children="Save" type="submit" />
              </>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default HardwareForm;
