import { apiSlice } from "../../_api/api-slice";

export const storlocator = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createStorLocator: build.mutation<void, void>({
      query: () => ({
        url: "store-locator",
        method: "post",
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useCreateStorLocatorMutation } = storlocator;
