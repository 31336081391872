import { useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import PageTemplate from "../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import Title from "../../atoms/Title/Title";
import DataDisplay, {
  DataToDisplay,
} from "../../molecules/DataDisplay/DataDisplay";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { formatDate } from "../../../helpers/formatDate";
// import TrackerConfigTable from "../../organisms/TrackerConfigTable/TrackerConfigTable";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { AppRoutes } from "../../../routes/AppRoutes";
import {
  useDeleteOperatorNetworkMutation,
  useGetOperatorNetworkByIdQuery,
} from "../../../rtk/software/operator-network/operator-network";
import OperatorNetworkDataDisplayAndEdit from "../../organisms/OperatorNetworkDataDisplayAndEdit/OperatorNetworkDataDisplayAndEdit";

const ManageOperatorNetwork = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { data: operatorNetwork, isLoading: isLoadingOperatorNetwork } =
    useGetOperatorNetworkByIdQuery(id ?? "", {
      skip: !id,
    });
  const [deleteOperatorNetwork] = useDeleteOperatorNetworkMutation();

  const operatorNetworkDataToDisplay: DataToDisplay[] = [
    {
      title: "Mccmnc",
      selector: "mccmnc",
    },
    {
      title: "Created at",
      selector: "created_at",
      callBack: (data) => formatDate(data),
    },
    {
      title: "Updated at",
      selector: "updated_at",
      callBack: (data) => formatDate(data),
    },
    {
      title: "Operator",
      selector: "operator",
    },
    {
      title: "Brand",
      selector: "brand",
    },
    {
      title: "Country",
      selector: "country",
    },
  ];

  const handleDeleteOperatorNetwork = async () => {
    setIsLoading(true);
    try {
      await deleteOperatorNetwork(operatorNetwork?.mccmnc?.toString() ?? "");
      navigate(AppRoutes.HARDWARES);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate title="Manage OperatorNetwork" cross>
      <Box className="ManageOperatorNetwork">
        {operatorNetwork && !isLoadingOperatorNetwork ? (
          <>
            <StyledPaper>
              <Box className="dataHeader">
                <Title
                  variant="h3"
                  children={`Mccmnc: ${operatorNetwork?.mccmnc}`}
                />
              </Box>

              <Box className="data">
                <DataDisplay
                  datas={operatorNetwork}
                  datasInformations={operatorNetworkDataToDisplay}
                  orientation={"horizontal"}
                />
              </Box>

              <Box className="buttons">
                {!isLoading ? (
                  <>
                    <StyledButton
                      children="Delete"
                      className="cancel"
                      onClick={handleDeleteOperatorNetwork}
                    />
                  </>
                ) : (
                  <SpinnerLoader />
                )}
              </Box>
            </StyledPaper>

            <StyledPaper>
              <Box className="dataHeader">
                <Title
                  variant="h3"
                  children={`Mccmnc: ${operatorNetwork?.mccmnc}`}
                />
              </Box>

              <Box className="data">
                <OperatorNetworkDataDisplayAndEdit
                  operatorNetwork={operatorNetwork}
                />
              </Box>
            </StyledPaper>
          </>
        ) : (
          <SpinnerLoader />
        )}
      </Box>
    </PageTemplate>
  );
};

export default ManageOperatorNetwork;
