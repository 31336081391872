import { Box } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import GenericTable from "../../molecules/GenericTable/GenericTable";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { components } from "../../../types/rtk/allTypes/allTypes";
import { AppRoutes } from "../../../routes/AppRoutes";

interface Props {
  trackers: components["schemas"]["OrderB2BTrackerObjRoot"][];
}

const TrackerListTable = ({ trackers }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const colDef =
    types?.components.schemas.OrderB2BTrackerObjRoot.properties ?? {};

  const colToOverride: GridColDef[] = [
    {
      field: "imei",
      renderCell: (params: GridRenderCellParams) => params.row.imei,
    },
    {
      field: "simpletracker.retailer_id",
      headerName: "Retailer Id",
      renderCell: (params: GridRenderCellParams) => (
        <Box
          onClick={() =>
            navigate(
              `${
                AppRoutes.MANAGE_RETAILER
              }/${params.row.simpletracker.retailer_id.toString()}`
            )
          }
          className="clickableBox"
        >
          {params.row.simpletracker.retailer_id}
        </Box>
      ),
    },
    {
      field: "simpletracker.type",
      headerName: "Type",
      renderCell: (params: GridRenderCellParams) =>
        params.row.simpletracker.type,
    },
    {
      field: "simpletracker.tracker_color",
      headerName: "Color",
      renderCell: (params: GridRenderCellParams) =>
        params.row.simpletracker.tracker_color,
    },
    {
      field: "simpletracker.model",
      headerName: "Model",
      renderCell: (params: GridRenderCellParams) =>
        params.row.simpletracker.model,
    },
    {
      field: "simpletracker.lot",
      headerName: "Lot",
      renderCell: (params: GridRenderCellParams) =>
        params.row.simpletracker.lot,
    },
    {
      field: "simpletracker.packaging",
      headerName: "Packaging",
      renderCell: (params: GridRenderCellParams) =>
        params.row.simpletracker.packaging,
    },
  ];

  const getRowId = (model: GridRowModel) => model.simpletracker.id;

  return (
    <Box className="TrackerListTable">
      {types && (
        <GenericTable
          rawData={{ items: trackers }}
          isLoading={false}
          isFetching={false}
          colDef={colDef}
          colToOverride={colToOverride}
          getRowId={getRowId}
        />
      )}
    </Box>
  );
};

export default TrackerListTable;
