import { Box } from "@mui/material";

import PageTemplate from "../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import { FormEvent, useState } from "react";
import { CreateRegisterTracker } from "../../../types/rtk/quality-check/quality-check";
import { useCreateRegisterTrackerMutation } from "../../../rtk/quality-check/quality-check";
import InputAutocomplete from "../../atoms/InputAutocomplete/InputAutocomplete";
import InputText from "../../atoms/InputText/InputText";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

const AddTrackers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<CreateRegisterTracker>({
    imei_list: [],
    customer_mail: "",
  } as CreateRegisterTracker);

  const [createRegisterTracker] = useCreateRegisterTrackerMutation();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await createRegisterTracker(formData).unwrap();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate title="Add trackers to an account" cross>
      <StyledPaper>
        <Box component={"form"} onSubmit={handleSubmit} className="AddTrackers">
          <InputAutocomplete
            label={"Tracker(s) chosen"}
            value={formData.imei_list.map((element) => element.toString())}
            onChange={(value) => {
              setFormData({
                ...formData,
                imei_list: (value as string[]).map((element) =>
                  parseInt(element)
                ),
              });
            }}
            options={[]}
            multiple
            freeSolo
            number
          />
          <InputText
            label="Chosen account"
            value={formData.customer_mail}
            onChange={(value) => {
              setFormData({
                ...formData,
                customer_mail: value,
              });
            }}
          />
          <Box className="buttons">
            {!isLoading ? (
              <StyledButton
                type="submit"
                disabled={
                  formData.imei_list.length < 1 && formData.customer_mail === ""
                }
              >
                Add trackers to this account
              </StyledButton>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </PageTemplate>
  );
};

export default AddTrackers;
