import { RouteObject } from "react-router-dom";

import { AppRoutes } from "../AppRoutes";
import Users from "../../components/pages/Users/Users";
import ManageUsers from "../../components/pages/Users/ManageUsers";

export const UsersRoutes: RouteObject = {
  path: AppRoutes.USERS,
  children: [
    { index: true, element: <Users /> },
    {
      path: AppRoutes.MANAGE_USERS + "/:id",
      element: <ManageUsers />,
    },
  ],
};
