import {
  CreateRegisterTracker,
  CreateRegisterTrackerResponse,
  CreateActivateSim,
  CreateActivateSimResponse,
  CreateCustomCommand,
  CreateCustomCommandResponse,
} from "../../types/rtk/quality-check/quality-check";
import { apiSlice } from "../_api/api-slice";

export const feature = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createRegisterTracker: build.mutation<
      CreateRegisterTrackerResponse,
      CreateRegisterTracker
    >({
      query: (body) => ({
        url: `qc-register-tracker`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Features" }],
    }),
    createActivateSim: build.mutation<
      CreateActivateSimResponse,
      CreateActivateSim
    >({
      query: (body) => ({
        url: `qc-activate-sim`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Features" }],
    }),
    createCustomCommand: build.mutation<
      CreateCustomCommandResponse,
      CreateCustomCommand
    >({
      query: (body) => ({
        url: `qc-custom-command`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Features" }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateActivateSimMutation,
  useCreateCustomCommandMutation,
  useCreateRegisterTrackerMutation,
} = feature;
