import { Chip } from "@mui/material";
import stc from "string-to-color";

interface Props {
  label: string;
  className?: string;
  color?:
    | "info"
    | "default"
    | "error"
    | "success"
    | "primary"
    | "secondary"
    | "warning";
  randomColor?: boolean;
  onDelete?: (event: any) => void;
}

const StyledChip = ({
  label,
  className,
  color,
  onDelete,
  randomColor,
}: Props) => {
  return (
    <Chip
      className={`StyledChip ${className}`}
      label={label}
      color={color}
      onDelete={onDelete}
      sx={randomColor ? { backgroundColor: stc(label ?? "") } : undefined}
    />
  );
};

export default StyledChip;
