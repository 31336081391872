import { Box } from "@mui/material";
import { useState } from "react";

interface Props {
  totalTrackers: number;
  migratedTrackers: number;
  deployRate: number;
}

const DeployRateComponent = ({
  totalTrackers,
  migratedTrackers,
  deployRate,
}: Props) => {
  const [showValue, setShowValue] = useState(false);

  return (
    <Box
      onMouseEnter={() => {
        setShowValue(true);
      }}
      onMouseLeave={() => setShowValue(false)}
    >
      {showValue ? migratedTrackers + "/" + totalTrackers : deployRate}
    </Box>
  );
};

export default DeployRateComponent;
