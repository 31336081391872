import { Box, Modal } from "@mui/material";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import StyledButton from "../../atoms/StyledButton/StyledButton";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  action?: (event: React.MouseEvent<HTMLElement>) => void;
  titleConfirmation?: string;
  textConfirmation?: string;
  textButtonConfirmation?: string;
}

const ConfirmationModal = ({
  isOpen,
  handleClose,
  action,
  titleConfirmation,
  textConfirmation,
  textButtonConfirmation,
}: Props) => {
  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    if (action) {
      action(event);
    }
    handleClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <>
        <StyledPaper className="ConfirmationModal">
          <Title variant="h1">
            {titleConfirmation ? titleConfirmation : "Warning"}
          </Title>
          <Text>
            {textConfirmation
              ? textConfirmation
              : "Are you sur you want to delete ?"}
          </Text>
          <Box className="buttonsBox">
            <StyledButton className="cancel" onClick={handleClose}>
              Cancel
            </StyledButton>
            <StyledButton onClick={handleDelete}>
              {textButtonConfirmation ? textButtonConfirmation : "Delete !"}
            </StyledButton>
          </Box>
        </StyledPaper>
      </>
    </Modal>
  );
};

export default ConfirmationModal;
