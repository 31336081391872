import { ThemeState } from "./../../types/rtk/theme/theme";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ThemeState = {
  mode: "light",
};

const ThemeSlice = createSlice({
  name: "theme",
  initialState: initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeState>) => {
      state.mode = action.payload.mode;
    },
  },
});
export const { setTheme } = ThemeSlice.actions;
const ThemeReducer = ThemeSlice.reducer;
export default ThemeReducer;
