import { Box } from "@mui/material";

import PageTemplate from "../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import { FormEvent, useState } from "react";
import { CreateActivateSim } from "../../../types/rtk/quality-check/quality-check";
import { useCreateActivateSimMutation } from "../../../rtk/quality-check/quality-check";
import InputAutocomplete from "../../atoms/InputAutocomplete/InputAutocomplete";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

const ActivateSims = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<CreateActivateSim>({
    sim_list: [],
  } as CreateActivateSim);
  const [createActivateSim] = useCreateActivateSimMutation();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await createActivateSim(formData).unwrap();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate title="Activate SIM cards" cross>
      <StyledPaper>
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          className="ActivateSims"
        >
          <InputAutocomplete
            label={"SIM card(s) to activate"}
            value={formData.sim_list}
            onChange={(value) => {
              setFormData({
                sim_list: value as string[],
              });
            }}
            options={[]}
            multiple
            freeSolo
          />
          <Box className="buttons">
            {!isLoading ? (
              <StyledButton
                type="submit"
                disabled={formData.sim_list.length < 1}
              >
                Activate SIM cards
              </StyledButton>
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        </Box>
      </StyledPaper>
    </PageTemplate>
  );
};

export default ActivateSims;
