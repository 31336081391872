import {
  PromoCode,
  CreatePromoCode,
  UpdatePromoCode,
  PromoCodes,
  CreatePromoCodeResponse,
  UpdatePromoCodeResponse,
} from "../../../types/rtk/promo-codes/promo-code/promo-code";

import { apiSlice } from "../../_api/api-slice";

export const promoCodes = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPromoCodes: build.query<PromoCodes, string>({
      query: (url) => ({
        url: `promo-code${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Promo_codes" }],
    }),
    getPromoCodeById: build.query<PromoCode, string>({
      query: (id) => ({
        url: `promo-code/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "Promo_code", id: `Promo_code_${id}` },
      ],
    }),
    getPromoCodeCampaign: build.query<PromoCode, string>({
      query: (id) => ({
        url: `promo-code-campaign/${id}/export`,
        method: "get",
        responseHandler: (response: any) => response.text(),
      }),
      providesTags: (_result, _error, id) => [
        { type: "Promo_code_campaign", id: `Promo_code_campaign_${id}` },
      ],
    }),
    createPromoCode: build.mutation<
      CreatePromoCodeResponse,
      Partial<CreatePromoCode>
    >({
      query: (body) => ({
        url: `promo-code`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, body) => [
        { type: "Promo_codes" },
        { type: "Affiliate", id: `Affiliate_${body.affiliate_id}` },
      ],
    }),
    createPromoCodeBatch: build.mutation<
      CreatePromoCodeResponse,
      Partial<CreatePromoCode>
    >({
      query: (body) => ({
        url: `promo-code-batch`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, body) => [
        { type: "Promo_codes" },
        { type: "Affiliate", id: `Affiliate_${body.affiliate_id}` },
      ],
    }),
    updatePromoCode: build.mutation<
      UpdatePromoCodeResponse,
      { body: UpdatePromoCode; id: string }
    >({
      query: ({ body, id }) => ({
        url: `promo-code/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: (_result, _error, { body, id }) => [
        { type: "Promo_code", id: `Promo_code_${id}` },
        { type: "Affiliate", id: `Affiliate_${body.affiliate_id}` },
        { type: "Promo_codes" },
      ],
    }),
    deletePromoCode: build.mutation<null, { id: string; affiliate_id: string }>(
      {
        query: ({ id }) => ({
          url: `promo-code/${id}`,
          method: "delete",
        }),
        invalidatesTags: (_result, _error, arg) => [
          { type: "Promo_codes" },
          { type: "Affiliate", id: `Affiliate_${arg.affiliate_id}` },
        ],
      }
    ),
  }),

  overrideExisting: false,
});

export const {
  useGetPromoCodesQuery,
  useGetPromoCodeByIdQuery,
  useLazyGetPromoCodeCampaignQuery,
  useCreatePromoCodeMutation,
  useCreatePromoCodeBatchMutation,
  useUpdatePromoCodeMutation,
  useDeletePromoCodeMutation,
} = promoCodes;
