import { RouteObject } from "react-router-dom";

import { AppRoutes } from "../AppRoutes";
import Retailers from "../../components/pages/Retailers/Retailers/Retailers";
import CreateRetailer from "../../components/pages/Retailers/Retailers/CreateRetailer";
import StoreLocator from "../../components/pages/Retailers/StoreLocator/StoreLocator";
import Groups from "../../components/pages/Retailers/Groups/Groups";
import ManageRetailer from "../../components/pages/Retailers/Retailers/ManageRetailer";
import CreateGroup from "../../components/pages/Retailers/Groups/CreateGroup";
import ManageGroup from "../../components/pages/Retailers/Groups/ManageGroup";

export const RetailersRoutes: RouteObject = {
  path: AppRoutes.RETAILERS,
  children: [
    {
      path: AppRoutes.RETAILER_CHILD,
      element: <Retailers />,
    },
    { path: AppRoutes.CREATE_RETAILER, element: <CreateRetailer /> },
    { path: AppRoutes.MANAGE_RETAILER + "/:id", element: <ManageRetailer /> },
    {
      path: AppRoutes.GROUPS,
      element: <Groups />,
    },
    {
      path: AppRoutes.CREATE_GROUP,
      element: <CreateGroup />,
    },
    {
      path: AppRoutes.MANAGE_GROUP + "/:id",
      element: <ManageGroup />,
    },
    {
      path: AppRoutes.STORE_LOCATOR,
      element: <StoreLocator />,
    },
  ],
};
