import FirmwareForm from "../../../organisms/FirmwareForm/FirmwareForm";
import PageTemplate from "../../../templates/PageTemplate/PageTemplate";

const CreateFirmware = () => {
  return (
    <PageTemplate title="New Firmware" cross>
      <FirmwareForm />
    </PageTemplate>
  );
};

export default CreateFirmware;
