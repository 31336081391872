import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import { NavBarRoutes } from "../../../routes/NavBarRoutes";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import PageTemplate from "../../templates/PageTemplate/PageTemplate";

const Overview = () => {
  const navigate = useNavigate();

  return (
    <PageTemplate>
      <Box className="Overview">
        <Grid container spacing={2}>
          {NavBarRoutes.map(
            (item, index) =>
              item.homeDisplay && (
                <Grid item xs={6} key={index}>
                  <Box className="item">
                    <StyledPaper>
                      <Box className="header">
                        <Box className="titleAndIcon">{item.icon}</Box>
                        <Title className="titleAndIcon" variant="h3">
                          {item.name}
                        </Title>
                      </Box>
                      {item.children && (
                        <Box className="children">
                          {item.children.map((child, index) => (
                            <Box
                              className="child"
                              onClick={() => navigate(child.path)}
                              key={index}
                            >
                              <Text>{child.name}</Text>
                              <ArrowForwardIcon />
                            </Box>
                          ))}
                        </Box>
                      )}
                    </StyledPaper>
                  </Box>
                </Grid>
              )
          )}
        </Grid>
      </Box>
    </PageTemplate>
  );
};

export default Overview;
