import { Box, Grid } from "@mui/material";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import PageTemplate from "../../templates/PageTemplate/PageTemplate";
import Title from "../../atoms/Title/Title";
import InputText from "../../atoms/InputText/InputText";
import Text from "../../atoms/Text/Text";
import { useState } from "react";

const Account = () => {
  const myUser = useAppSelector((state: RootState) => state.myUser);
  const [newPassword, setNewPassord] = useState("");
  const [confirmPassword, setConfirmPassord] = useState("");

  return (
    <PageTemplate title="Account">
      <Box className="Account">
        <StyledPaper className="card">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Title variant="h3" children="Basic details" />
            </Grid>
            <Grid item xs={8}>
              <Box className="inputs">
                <Text className="subtitle">{"Username"}</Text>
                <Text className="informations">{myUser.username}</Text>
                <Text className="subtitle">{"Role"}</Text>
                <Text className="informations">{myUser.role_label}</Text>
              </Box>
            </Grid>
          </Grid>
        </StyledPaper>
        <StyledPaper className="card">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Title variant="h3" children="Password" />
            </Grid>
            <Grid item xs={8}>
              <Box className="inputs">
                <InputText
                  label="New password"
                  value={newPassword}
                  onChange={(value) => {
                    setNewPassord(value);
                  }}
                />
                <InputText
                  label="Confirm password"
                  value={confirmPassword}
                  className="alignInput"
                  onChange={(value) => {
                    setConfirmPassord(value);
                  }}
                  error={newPassword !== confirmPassword}
                />
              </Box>
            </Grid>
          </Grid>
        </StyledPaper>
      </Box>
    </PageTemplate>
  );
};

export default Account;
