export const getPercentage = ({
  value,
  total,
}: {
  value: number;
  total: number;
}): number => {
  return value !== 0 && total !== 0
    ? parseInt(
        ((value * 100) / total).toFixed(1).toString().split(".")[1][0]
      ) >= 5
      ? Math.ceil((value * 100) / total)
      : Math.round((value * 100) / total)
    : 0;
};
