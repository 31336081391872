import { RouteObject } from "react-router-dom";

import { AppRoutes } from "../AppRoutes";
import QualityCheck from "../../components/pages/QualityCheck/QualityCheck";
import ActivateSims from "../../components/pages/QualityCheck/ActivateSims";
import AddTrackers from "../../components/pages/QualityCheck/AddTrackers";
import SendCommands from "../../components/pages/QualityCheck/SendCommands";

export const QualityCheckRoutes: RouteObject = {
  path: AppRoutes.QUALITY_CHECK,
  children: [
    { index: true, element: <QualityCheck /> },
    {
      path: AppRoutes.QUALITY_CHECK_SIMS,
      element: <ActivateSims />,
    },
    {
      path: AppRoutes.QUALITY_CHECK_TRACKERS,
      element: <AddTrackers />,
    },
    {
      path: AppRoutes.QUALITY_CHECK_COMMANDS,
      element: <SendCommands />,
    },
  ],
};
