import { useState } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Text from "../Text/Text";

interface Props {
  value: number;
  tracker: number;
  total: number;
}

const LinearProgressWithLabel = ({ value, tracker, total }: Props) => {
  const [showValue, setShowValue] = useState(false);

  return (
    <Box
      className="LinearProgressWithLabel"
      onMouseOver={() => setShowValue(true)}
      onMouseOut={() => setShowValue(false)}
    >
      <Box className="displayBar">
        <LinearProgress
          color={value >= 50 ? "secondary" : "primary"}
          value={value}
          variant="determinate"
        />
      </Box>
      <Box className="displayValue">
        <Text>
          {showValue ? tracker + "/" + total : `${Math.round(value)}%`}
        </Text>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
