import {
  Hardwares,
  CreateHardware,
  Hardware,
  CreateHardwareResponse,
  UpdateHardware,
  UpdateHardwareResponse,
} from "../../../types/rtk/software/hardware/hardware";
import { apiSlice } from "../../_api/api-slice";

export const hardware = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getHardwares: build.query<Hardwares, string>({
      query: (url) => ({
        url: `hardware${url}`,
        method: "get",
      }),
      providesTags: (_result, _error, _arg) => [{ type: "Hardwares" }],
    }),
    getHardwareById: build.query<Hardware, string>({
      query: (id) => ({
        url: `hardware/${id}`,
        method: "get",
      }),
      providesTags: (_result, _error, id) => [
        { type: "Hardware", id: `Hardware_${id}` },
      ],
    }),
    createHardware: build.mutation<CreateHardwareResponse, CreateHardware>({
      query: (body) => ({
        url: `hardware`,
        method: "post",
        body,
      }),
      invalidatesTags: (_result, _error, _arg) => [{ type: "Hardwares" }],
    }),
    updateHardware: build.mutation<UpdateHardwareResponse, UpdateHardware>({
      query: (id) => ({
        url: `hardware/${id}`,
        method: "put",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "Hardware", id: `Hardware_${id}` },
      ],
    }),
    deleteHardware: build.mutation<null, string>({
      query: (id) => ({
        url: `hardware/${id}`,
        method: "delete",
      }),
      invalidatesTags: (_result, _error, _id) => [{ type: "Hardwares" }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetHardwaresQuery,
  useGetHardwareByIdQuery,
  useCreateHardwareMutation,
  useUpdateHardwareMutation,
  useDeleteHardwareMutation,
} = hardware;
