import { Box, Grid } from "@mui/material";

import PageTemplate from "../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/AppRoutes";

const QualityCheck = () => {
  const navigate = useNavigate();

  const handleQualityCheck = (link: string) => {
    navigate(link);
  };

  return (
    <PageTemplate title="Dashboard QC">
      <>
        <StyledPaper>
          <Box className="QualityCheck">
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Title variant="h3" children="Activate SIM cards" />
                <Text children="Activate multiple SIM cards at the same time" />
              </Grid>
              <Grid item xs={3}>
                <StyledButton
                  onClick={() =>
                    handleQualityCheck(AppRoutes.QUALITY_CHECK_SIMS)
                  }
                >
                  Activate SIM
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </StyledPaper>

        <StyledPaper>
          <Box className="QualityCheck">
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Title variant="h3" children="Add trackers to an account" />
                <Text children="Add multiple trackers to a predefined account" />
              </Grid>
              <Grid item xs={3}>
                <StyledButton
                  onClick={() =>
                    handleQualityCheck(AppRoutes.QUALITY_CHECK_TRACKERS)
                  }
                >
                  Add trackers
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </StyledPaper>

        <StyledPaper>
          <Box className="QualityCheck">
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Title variant="h3" children="Send a custom command" />
                <Text children="Send a custom command to a list of specific IMEIs" />
              </Grid>
              <Grid item xs={3}>
                <StyledButton
                  onClick={() =>
                    handleQualityCheck(AppRoutes.QUALITY_CHECK_COMMANDS)
                  }
                >
                  Send command
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </StyledPaper>
      </>
    </PageTemplate>
  );
};

export default QualityCheck;
