import { Box, LinearProgress } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

import SwitchDarkMode from "../../atoms/SwitchDarkMode/SwitchDarkMode";
import { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import HeaderMenu from "../../molecules/HeaderMenu/HeaderMenu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";

interface Props {
  isNavBarOpen?: boolean;
  setIsNavBarOpen?: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ isNavBarOpen, setIsNavBarOpen }: Props) => {
  const token = useAppSelector((state: RootState) => state.auth.token);
  const loader = useAppSelector((state: RootState) => state.loader.isLoading);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="Header">
      {loader && (
        <Box className="loader">
          <LinearProgress />
        </Box>
      )}
      <Box className={`headerContainer ${loader && "minusMargin"}`}>
        <Box className="menu">
          {isNavBarOpen !== undefined && setIsNavBarOpen !== undefined ? (
            isNavBarOpen ? (
              <ChevronLeftIcon onClick={() => setIsNavBarOpen(!isNavBarOpen)} />
            ) : (
              <MenuIcon onClick={() => setIsNavBarOpen(!isNavBarOpen)} />
            )
          ) : null}
        </Box>
        <Box className="account">
          <SwitchDarkMode />
          {token && (
            <>
              <Box component={"span"} onClick={handleClick}>
                <AccountCircle />
              </Box>
              <HeaderMenu
                open={open}
                handleClose={handleClose}
                anchorEl={anchorEl}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
