import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ReactSVG } from "react-svg";

import errorLogo from "../../../assets/images/errorLogo.svg";
import Title from "../../atoms/Title/Title";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";

const ErrorPage = () => {
  const navigate = useNavigate();
  const theme = useAppSelector((state: RootState) => state.theme.mode);

  useMemo(() => {
    document.body.className = `theme--${theme}`;
  }, [theme]);

  return (
    <Box className="ErrorPage">
      <ReactSVG src={errorLogo} />
      <Title variant="h3">Oops.. something went wrong!</Title>
      <StyledButton onClick={() => navigate("/")}>Go home</StyledButton>
    </Box>
  );
};

export default ErrorPage;
