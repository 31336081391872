// import { useState } from "react";
import { Box, Grid } from "@mui/material";

import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import InputSwitch from "../../atoms/InputSwitch/InputSwitch";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import {
  GiftCardObj,
  OffersObj,
  PromoCodeObj,
} from "../../../types/rtk/promo-codes/promo-code/promo-code";
import { formatDate } from "../../../helpers/formatDate";
import {
  useDeletePromoCodeMutation,
  useLazyGetPromoCodeCampaignQuery,
  useUpdatePromoCodeMutation,
} from "../../../rtk/promo-codes/promo-code/promo-code";
import { components } from "../../../types/rtk/allTypes/allTypes";
import { useEffect, useState } from "react";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

interface Props {
  affiliate_id: number;
  promoCode?: PromoCodeObj[];
  giftCard?: GiftCardObj[];
}

interface PropsPromoCodeAction {
  affiliate_id: number;
  obj: PromoCodeObj;
}

const PromoCodeAction = ({ obj, affiliate_id }: PropsPromoCodeAction) => {
  const [promoCodeData, setPromoCodeData] = useState<
    components["schemas"]["PromoCodeObj"]
  >({});
  const [updatePromoCode] = useUpdatePromoCodeMutation();
  const [deletePromoCode] = useDeletePromoCodeMutation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (obj) {
      setPromoCodeData(obj);
    }
  }, [obj]);

  const handleUpdateActivation = async ({
    obj,
    value,
  }: {
    obj: components["schemas"]["PromoCodeObj"];
    value: boolean;
  }) => {
    setIsLoading(true);
    setPromoCodeData({ ...promoCodeData, is_activated: value });
    try {
      const response = await updatePromoCode({
        id: promoCodeData.id?.toString() ?? "0",
        body: { ...promoCodeData, is_activated: value },
      }).unwrap();
      console.log(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleDeletePromoCode = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await deletePromoCode({
        id: id,
        affiliate_id: affiliate_id.toString(),
      }).unwrap();
      console.log(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return promoCodeData && !isLoading ? (
    <>
      <InputSwitch
        label=""
        value={promoCodeData.is_activated ?? false}
        onChange={(value) => handleUpdateActivation({ obj, value })}
      />
      <StyledButton
        children="Delete"
        className="cancel"
        onClick={() => handleDeletePromoCode(obj.id?.toString() ?? "")}
      />
    </>
  ) : (
    <SpinnerLoader />
  );
};

const GiftCardAction = ({ obj }: { obj: GiftCardObj }) => {
  const [getPromoCodeCampaign] = useLazyGetPromoCodeCampaignQuery();
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadGiftCard = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await getPromoCodeCampaign(id).unwrap();
      const unparsedRows = String(response).split("\n");
      const rows = unparsedRows.map((row) => String(row).split(","));

      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `GiftCards.csv`);
      // if (batchRuleName) {
      //   link.setAttribute("download", `GiftCards - ${batchRuleName}.csv`);
      // }
      document.body.appendChild(link); // Required for FF
      link.click(); // This will download the data file named "my_data.csv".
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return !isLoading ? (
    <StyledButton
      children="Download"
      onClick={() =>
        handleDownloadGiftCard(obj.promo_code_campaign_id?.toString() ?? "0")
      }
    />
  ) : (
    <SpinnerLoader />
  );
};

const PromoCodeDisplay = ({ promoCode, giftCard, affiliate_id }: Props) => {
  const getDate = (value: string | null | undefined): string => {
    return value === null ? "not set" : formatDate(value ?? "");
  };

  const getOffers = (value?: OffersObj[]): string => {
    return value && value?.length > 0
      ? `${value[0].value}% - ${value[1].value}% - ${value[2].value}%`
      : "0% - 0% -  0%";
  };

  return (
    <Grid className="PromoCodeDisplay" container spacing={3}>
      {promoCode &&
        promoCode?.map((obj, i) => (
          <Grid key={i} item container xs={12}>
            <Grid item xs={9}>
              <Box className="item">
                <Box>
                  <Title variant="h3" children={!giftCard ? obj.code : ""} />
                  <Text color="tercery">
                    {`${obj.promo_code_rule?.rule_name} | Valid from: ${getDate(
                      obj.valid_from
                    )} | Expires on: ${getDate(obj.expires_on)}`}
                  </Text>
                  <Text color="tercery">{`Reduction: ${getOffers(
                    obj.promo_code_rule?.offers as OffersObj[]
                  )} | Commission: ${obj.commission_amount} €`}</Text>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} className="gridAction">
              <PromoCodeAction obj={obj} affiliate_id={affiliate_id} />
            </Grid>
          </Grid>
        ))}

      {giftCard &&
        giftCard?.map((obj, i) => (
          <Grid key={i} item container xs={12}>
            <Grid item xs={9}>
              <Box className="item">
                <Box>
                  <Title
                    variant="h3"
                    children={obj.promo_code_rule?.rule_name ?? ""}
                  />
                  <Text color="tercery">
                    {`${
                      obj.promo_code_rule?.rule_name ?? ""
                    } | Valid from: ${getDate(
                      obj.valid_from
                    )} | Expires on: ${getDate(obj.expires_on)}`}
                  </Text>
                  <Text color="tercery">{`Batch ID: ${obj.promo_code_campaign_id} | Batch size: ${obj.batch_size}`}</Text>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} className="gridAction">
              <GiftCardAction obj={obj} />
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default PromoCodeDisplay;
