import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../../atoms/StyledPaper/StyledPaper";
import Title from "../../../atoms/Title/Title";
import Text from "../../../atoms/Text/Text";
import DataDisplay from "../../../molecules/DataDisplay/DataDisplay";
import StyledButton from "../../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../../atoms/SpinnerLoader/SpinnerLoader";
import GroupForm from "../../../organisms/GroupForm/GroupForm";
import {
  useDeleteGroupMutation,
  useGetGroupByIdQuery,
} from "../../../../rtk/retailers/group/group";
import { formatDate } from "../../../../helpers/formatDate";
import { AppRoutes } from "../../../../routes/AppRoutes";

const ManageGroup = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { data: group, isLoading: isLoadingGroup } = useGetGroupByIdQuery(
    id ?? "",
    {
      skip: !id,
    }
  );
  const [deleteGroup] = useDeleteGroupMutation();

  const groupDataToDisplay = [
    { title: "Type", selector: "type.name" },
    { title: "Name", selector: "name" },
    { title: "Created at", selector: "created_at", callBack: formatDate },
    {
      title: "Logo-header (= displayed in the userspace)",
      selector: "logo_header",
    },
    {
      title: "Splashscreen = (displayed in the apps)",
      selector: "logo_splashscreen",
    },
  ];

  const handleDeleteGroup = async () => {
    setIsLoading(true);
    try {
      await deleteGroup(group?.id?.toString() ?? "");
      navigate(AppRoutes.GROUPS);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate
      title="Manage group"
      cross
      onClickCross={editMode === true ? () => setEditMode(false) : undefined}
    >
      <Box className="ManageGroup">
        {!editMode ? (
          <StyledPaper>
            {!isLoadingGroup ? (
              <>
                <Box className="dataHeader">
                  <Title variant="h3" children={group?.name} />
                  <Text children={`group id: ${group?.id ?? ""}`} />
                </Box>

                <Box className="data">
                  <DataDisplay
                    datas={group}
                    datasInformations={groupDataToDisplay}
                    orientation={"horizontal"}
                  />
                </Box>

                <Box className="buttons">
                  {!isLoading ? (
                    <>
                      <StyledButton
                        children="Delete"
                        className="cancel"
                        onClick={handleDeleteGroup}
                      />
                      <StyledButton
                        children="Edit"
                        onClick={() => setEditMode(true)}
                      />
                    </>
                  ) : (
                    <SpinnerLoader />
                  )}
                </Box>
              </>
            ) : (
              <SpinnerLoader />
            )}
          </StyledPaper>
        ) : (
          <GroupForm group={group} onClickCancel={() => setEditMode(false)} />
        )}
      </Box>
    </PageTemplate>
  );
};

export default ManageGroup;
