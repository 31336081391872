import OTAForm from "../../../organisms/OTAForm/OTAForm";
import PageTemplate from "../../../templates/PageTemplate/PageTemplate";

const CreateOTACampain = () => {
  return (
    <PageTemplate title="New OTA" cross>
      <OTAForm />
    </PageTemplate>
  );
};

export default CreateOTACampain;
